<div class="modal-body">
  <h4 class="modal-title mb-2">Search User</h4>
  <form [formGroup]="form" *ngIf="form">
    <div class="form-row form-group d-flex align-items-end">
      <div class="col-12 col-sm-4">
        <label class="form-check-label text-muted">IT Department </label>
        <ng-select name="department" id="department" formControlName="department">
          <ng-option disabled [value]="">--Select Department--</ng-option>
          <ng-option *ngFor="let item of departmentList" [value]=item.deptID> {{item.deptName}}</ng-option>
        </ng-select>
      </div>
      <div class="col-12 col-sm-7">
        <label class="form-check-label text-muted">Name </label>
        <input type="text" class="form-control form-control-sm" formControlName="name">
      </div>
      <div class="col-12 col-sm-1">
        <button type="button" class="btn btn-warning float-right my-2 my-sm-0" (click)="search()">Search</button>
      </div>
    </div>
  </form>
  <div class="table-responsive" *ngIf="userData">
    <div class="d-flex justify-content-end my-3">
      <app-row-per-page [size]=pageSize (newSize)="updatePage($event)"></app-row-per-page>
    </div>
    <table class="table table-striped">
      <thead class="bg-n3">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">User Name</th>
          <th scope="col">Email</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of userData.elements">
          <th>{{item.nameEn}}</th>
          <td>{{item.nameEn}}</td>
          <th>{{item.email}}</th>
          <td>
            <a class="link text-primary" (click)="select(item)">Select</a>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="8" class="align-items-center">
            <div class="d-flex justify-content-end">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="userData.totalElements"
                [(page)]="page" [maxSize]="5" [boundaryLinks]="true" (pageChange)="updatePage(pageSize)"></ngb-pagination>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
  <button type="button" class="btn btn-primary float-right mb-3" (click)="cancel()">Close</button>
</div>