import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "taskId",
})
export class TaskIdPipe implements PipeTransform {
  transform(value: number): string {
    return value ? value.toString().padStart(7, "0") : "";
  }
}
