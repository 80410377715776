import { environment } from 'src/environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class GeneralAPIHandleInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService) { }
  count = 0;
  private handleAuthError(
    req: HttpRequest<any>,
    next: HttpHandler,
    err: HttpErrorResponse
  ): Observable<any> {
    if (err.status === 500) {
      const errorMessage = err.error != null ? err.error.message : err.message;
      this.toast.error(errorMessage, 'Internal Error');
      return of(null);
    } else if (err.status === 401) {
      this.toast.error('Your do not have permission to view this page.');
      this.router.navigate(['access-denied']);
      return of(null);
    } else if (err.status === 422 && err.error!= null && err.error.data.errorMessage === 'State incorrect.') {
      this.toast.warning(err.error.data.errorMessage)
      window.location.href = err.error.data.redirectUrl;

    } else if(err.status === 403) {
        const errorMessage = err.error != null ? err.error.message : err.message;
      if(err.error && err.error.message.includes("Session not active")){
        this.toast.error('Session not active');
        this.router.navigate(['/login']);
      }else{
        this.toast.error(errorMessage);
        this.router.navigate(['admin/task']);
      }
      
      return of(null);
    } else {
      return throwError(err);
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show()
    if(!this.isIgnoreSpinnerPath(req)){
      this.count++;
    }
    return next
      .handle(req)
      .pipe(
        catchError((err) => this.handleAuthError(req, next, err)),
        finalize(() => {
          if(!this.isIgnoreSpinnerPath(req)){
            this.count--;
          }
          if (this.count == 0) {
            setTimeout(() => {             
              this.spinner.hide()
            }, 1000)

          }
        })
      );
  }

  isIgnoreSpinnerPath(req: HttpRequest<any>){
    const ignorePath:string[] = [
      `${environment.apiBasePath}/task/pending`
    ]
    return ignorePath.some(path => path === req.url);
  }
}
