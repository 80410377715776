import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from "@angular/cdk/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatListModule } from "@angular/material/list";
import { MatSortModule } from "@angular/material/sort";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  NgbCollapseModule,
  NgbNavModule,
  NgbDatepickerModule,
  NgbPaginationModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { PaginatorModule } from "primeng/paginator";
import { ProgressBarModule } from "primeng/progressbar";
import { TableModule } from "primeng/table";
import { SharedModule } from "../../shared/shared.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ReportRoutingModule } from './report-routing.module';
import { CrReviewComponent } from './cr-review/cr-review.component';
import { IrReviewComponent } from './ir-review/ir-review.component';
import { ReportComponent } from './report.component';
import { DuplicateFileComponent } from './duplicate-file/duplicate-file.component';
import { PromoteFileComponent } from './promote-file/promote-file.component';
import { PromoteFormByPromoteIdComponent } from './promote-form-by-promote-id/promote-form-by-promote-id.component';
import { OutstandingComponent } from './outstanding/outstanding.component';
import { PromotedTaskComponent } from './promoted-task/promoted-task.component';
import { SpecialRoleComponent } from './special-role/special-role.component';
import { TimesheetViewComponent } from './timesheet-view/timesheet-view.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TimesheetReportComponent } from './timesheet-report/timesheet-report.component';
import { NewTimesheetReportComponent } from './new-timesheet-report/new-timesheet-report.component';
import { TimesheetReportDashboardComponent } from './timesheet-report-dashboard/timesheet-report-dashboard.component';
import { RequestProductionDataComponent } from './request-production-data/request-production-data.component';


@NgModule({
  imports: [
    CommonModule,
    ReportRoutingModule,
    SharedModule,
    NgbCollapseModule,
    NgbNavModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbPaginationModule,
    FontAwesomeModule,
    CdkTableModule,
    MatSortModule,
    MatListModule,
    DropdownModule,
    PaginatorModule,
    TableModule,
    ProgressBarModule,
    MultiSelectModule,
    CalendarModule,
    MatSlideToggleModule,
  ],
  declarations: [
    CrReviewComponent,
    IrReviewComponent,
    ReportComponent,
    DuplicateFileComponent,
    PromoteFileComponent,
    PromoteFormByPromoteIdComponent,
    OutstandingComponent,
    PromotedTaskComponent,
    SpecialRoleComponent,
    TimesheetViewComponent,
    TimesheetComponent,
    TimesheetReportComponent,
    NewTimesheetReportComponent,
    TimesheetReportDashboardComponent,
    RequestProductionDataComponent
  ],
  exports: [
    ReportComponent
  ]
})
export class ReportModule { }
