<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">Promote File Report</h5>
      <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
    </div>
    <div class="card bg-n7 mb-3" #SearchFilter>
      <div class="card-body">
        <h6 class="p-0 m-0;">
          <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
            <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
            <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
            Search Filters
          </a>
        </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
        <form [formGroup]="form">
          <div class="row mb-1">         
              <div class="col-12 col-sm-6 col-lg-5" #promoteTier>
                <span class="font-weight-bold">Promote Tier</span>
                <p-multiSelect [options]="promoteTierList" formControlName="promoteTier" [style]="{'width':'100%','min-width':'100%'}" defaultLabel="Select Promote Tier" optionLabel="promoteTierName" display="chip"></p-multiSelect>
              </div>
              <div class="col-12 col-sm-6 col-lg-5">
                <span class="font-weight-bold">File Name</span>
                  <input type="text" class="form-control form-control-sm" formControlName="fileName">
              </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
      </div>  
    </div>
    <div #Table>
      <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" >
        <li [ngbNavItem]="1">
          <a ngbNavLink>Promote File</a>
          <ng-template ngbNavContent> -->
  
          <div class="table-responsive" #servicesTable *ngIf="reportDataSource.data">
  
            <table mat-table matSort class="table table-hover" cdk-table [dataSource]="reportDataSource">

            <ng-container cdkColumnDef="expectedPromoteDate">
              <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Expected Promote Date</th>
              <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.expectedPromoteDate}}</td>
            </ng-container> 

            <ng-container cdkColumnDef="promoteId">
              <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef >Promote ID</th>
              <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteIDDisplay}}</td>
            </ng-container>
  
            <ng-container cdkColumnDef="subject">
              <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef >Subject</th>
              <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.subject}}</td>
            </ng-container>
  
            <ng-container cdkColumnDef="promoteTier">
              <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Promote Tier</th>
              <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteTier}}</td>
            </ng-container>
  
            <ng-container cdkColumnDef="promoteStep">
              <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>File Name</th>
              <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteStep}}</td>
            </ng-container>
  
            <tr class="table-header" cdk-header-row *cdkHeaderRowDef="reportColumns"></tr>
            <tr class="table-row" cdk-row *cdkRowDef="let row; columns: reportColumns"></tr> 
            </table>  
  
            <div class="not-found text-center" *ngIf="reportDataSource.data == null || totalElements === 0" >
              <div class="my-3"> No Data Found.</div>
            </div>
  
          </div>
  
          <div class="bg-black-haze">
            <div class="align-self-center">
            </div>
          </div>
  
          <!-- </ng-template>
      </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div> -->
    </div>
</div>