import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ok-modal',
  templateUrl: './ok-modal.component.html',
  styleUrls: ['./ok-modal.component.scss']
})
export class OkModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() btnText = 'OK';
  @Input() showClose:boolean = true;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  ok(): void {
    this.activeModal.close({ ok: true });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }
}
