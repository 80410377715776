import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { NgbdSortableHeader } from "src/app/directive/sortable.directive";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { RowPerPageComponent } from "./row-per-page/row-per-page.component";
import { TaskIdPipe } from "./pipes/task-id.pipe";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { ConfirmModalComponent } from "./component/confirm-modal/confirm-modal.component";
import { SearchUserModalComponent } from "./component/search-user-modal/search-user-modal.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { OkModalComponent } from './component/ok-modal/ok-modal.component';
import { SortableHeaderComponent } from './sorting/component/sortable-header/sortable-header.component';
import { FilterTextPipe } from './pipes/filter.pipr';
import { UploadFileComponent } from './component/upload-file/upload-file.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RequestDetailBoxComponent } from "./component/request-detail-box/request-detail-box.component";
import { AttachmentBoxComponent } from "./component/attachment-box/attachment-box.component";
import { InvolvedPersonBoxComponent } from "./component/involved-person-box/involved-person-box.component";
import { CommentBoxComponent } from "./component/comment-box/comment-box.component";
import { MainTaskBoxComponent } from "./component/main-task-box/main-task-box.component";
import { HistoryBoxComponent } from "./component/history-box/history-box.component";
import { ImpactAnalysisBoxComponent } from "./component/impact-analysis-box/impact-analysis-box.component";
import { ManDayEstimationBoxComponent } from "./component/man-day-estimation-box/man-day-estimation-box.component";
import { AssignDeveloprBoxComponent } from './component/assign-developr-box/assign-developr-box.component';
import { TaskTreeModalComponent } from './component/task-tree-modal/task-tree-modal.component';
import { TreeTableModule } from 'primeng/treetable';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ApproveDetailBoxComponent } from './component/approve-detail-box/approve-detail-box.component';
import { ChangeUserBoxComponent } from './component/change-user-box/change-user-box.component';
import { PromoteHistoryModalComponent } from './component/promote-history-modal/promote-history-modal.component';
import { ConfirmDeleteModalComponent } from './component/confirm-delete-modal/confirm-delete-modal.component';
import { TwoDigitDecimalNumberDirective } from "./directives/two-digit-decimal-number.directive";
import { ReleaseFormInfoComponent } from './component/release-form-info/release-form-info.component';
import { PromoteReleaseNoteComponent } from './component/promote-release-note/promote-release-note.component';
import { PromoteForm6Component } from './component/promote-form6/promote-form6.component';
import { PromoteTestScriptComponent } from './component/promote-test-script/promote-test-script.component';
import { ReleaseForm8Component } from './component/release-form8/release-form8.component';
import { AddCommentModalComponent } from './component/add-comment-modal/add-comment-modal.component';
import { PromoteIdPipe } from "./pipes/promoteId.pipe";
import { ExtendTimeModalComponent } from './component/extend-time-modal/extend-time-modal.component';
import { ApproveErModalComponent } from './component/approve-er-modal/approve-er-modal.component';
import { DateTimePickerComponent } from './component/date-time-picker/date-time-picker.component';
import { CloseAllTaskComponent } from './component/close-all-task/close-all-task.component';
import {TooltipModule} from 'primeng/tooltip';
import { ConvertToJiraModalComponent } from './component/convert-to-jira-modal/convert-to-jira-modal.component';
import { ManDayCompareChartComponent } from './component/convert-to-jira-modal/components/man-day-compare-chart/man-day-compare-chart.component';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { ConvertToJiraTaskDetailComponent } from './component/convert-to-jira-modal/components/convert-to-jira-task-detail/convert-to-jira-task-detail.component';
import { ButtonModule } from 'primeng/button';
import { TaskConversionModalComponent } from './component/task-conversion-modal/task-conversion-modal.component';
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { SonarqubeComponent } from './component/sonarqube/sonarqube.component';
import { AcknowledgeAndRecommendByBuBoxComponent } from "./component/acknowledge-and-recommend-by-bu-box/acknowledge-and-recommend-by-bu-box.component";

@NgModule({
  imports: [
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    FontAwesomeModule,
    TableModule,
    TreeTableModule,
    AutoCompleteModule,
    TooltipModule,
    AmChartsModule,
    ButtonModule,
    MultiSelectModule,
    DropdownModule
  ],
  declarations: [
    NgbdSortableHeader,
    RowPerPageComponent,
    ConfirmModalComponent,
    OkModalComponent,
    SearchUserModalComponent,
    TaskIdPipe,
    FileSizePipe,
    FilterTextPipe,
    SortableHeaderComponent,
    UploadFileComponent,
    RequestDetailBoxComponent,
    AttachmentBoxComponent,
    InvolvedPersonBoxComponent,
    CommentBoxComponent,
    MainTaskBoxComponent,
    HistoryBoxComponent,
    ImpactAnalysisBoxComponent,
    ManDayEstimationBoxComponent,
    AssignDeveloprBoxComponent,
    TaskTreeModalComponent,
    ApproveDetailBoxComponent,
    ChangeUserBoxComponent,
    PromoteHistoryModalComponent,
    ConfirmDeleteModalComponent,
    TwoDigitDecimalNumberDirective,
    ReleaseFormInfoComponent,
    PromoteReleaseNoteComponent,
    PromoteForm6Component,
    PromoteTestScriptComponent,
    ReleaseForm8Component,
    AddCommentModalComponent,
    PromoteIdPipe,
    ExtendTimeModalComponent,
    ApproveErModalComponent,
    DateTimePickerComponent,
    CloseAllTaskComponent,
    ConvertToJiraModalComponent,
    ManDayCompareChartComponent,
    ConvertToJiraTaskDetailComponent,
    TaskConversionModalComponent,
    SonarqubeComponent,
    AcknowledgeAndRecommendByBuBoxComponent
  ],
  exports: [
    NgbdSortableHeader,
    RowPerPageComponent,
    ConfirmModalComponent,
    SearchUserModalComponent,
    SortableHeaderComponent,
    TaskIdPipe,
    FileSizePipe,
    FilterTextPipe,
    UploadFileComponent,
    RequestDetailBoxComponent,
    AttachmentBoxComponent,
    InvolvedPersonBoxComponent,
    CommentBoxComponent,
    MainTaskBoxComponent,
    HistoryBoxComponent,
    ImpactAnalysisBoxComponent,
    ManDayEstimationBoxComponent,
    AssignDeveloprBoxComponent,
    TaskTreeModalComponent,
    ApproveDetailBoxComponent,
    ChangeUserBoxComponent,
    TwoDigitDecimalNumberDirective,
    ReleaseFormInfoComponent,
    PromoteReleaseNoteComponent,
    PromoteForm6Component,
    PromoteTestScriptComponent,
    ReleaseForm8Component,
    SonarqubeComponent,
    PromoteIdPipe,
    DateTimePickerComponent,
    CloseAllTaskComponent,
    AcknowledgeAndRecommendByBuBoxComponent
  ],
})
export class SharedModule { }
