export class PromotedReportCriteria {
    itDeptId?: string[];
    buDeptId?: string;
    endDate?: string;
    startDate?: string;
  
    PromotedReportCriteria(): void {
      this.itDeptId = null;
      this.buDeptId = "";
      this.endDate = "";
      this.startDate = "";
    }
  }