import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() isCollapse: boolean;
  @Output() formChange = new EventEmitter<FormGroup>();

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  
  constructor() { }
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = new Subscription();
    this.subscription.add(
      this.form.valueChanges.subscribe(() => this.formChange.emit(this.form))
    );
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

}
