import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RequestProductionDataService {

  constructor() { }

  public downLoadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    this.saveAs(blob, fileName);
  }

  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}
