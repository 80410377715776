<div class="card card-top bg-n7">
  <div class="card-header">
    <h6 class="card-title">
      <a (click)="collapseRequestDetail.toggle();toggleChange()" [attr.aria-expanded]="!isCollapse"
        aria-controls="collapseExample">
        <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
        <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
        Request Detail
      </a>
      <a *ngIf="taskId && status" class="task-status">
        <b>Task ID</b>: {{ taskId | taskId }}
        <span class="status">
          <b>Status</b>: {{ status }}</span>
      </a>
    </h6>
  </div>
  <div class="card-body" #collapseRequestDetail="ngbCollapse" [(ngbCollapse)]="isCollapse">
    <form [formGroup]="form" *ngIf="form">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.itDepartment">
          <span class="float-sm-right text-right">IT Department
            <span class="text-danger" *ngIf="fieldsData.itDepartment?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.itDepartment">
          <ng-select name="departmentSelector" id="departmentSelector" formControlName="itDepartment">
            <ng-option disabled [value]="">--Select Department--</ng-option>
            <ng-option *ngFor="let item of deptList" [value]=item.deptID> {{item.deptName}}</ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.serviceName">
          <span class="float-sm-right text-right">{{ isReadonly ? 'Service Name' : 'Assigned Service'}}
            <span class="text-danger" *ngIf="fieldsData.serviceName?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.serviceName">
          <ng-select name="serviceSelector" id="serviceSelector" formControlName="serviceName">
            <ng-option disabled [value]="">--Select Service--</ng-option>
            <ng-option *ngFor="let item of serviceList" [value]=item.componentId> {{item.serviceName}}</ng-option>
          </ng-select>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.taskName">
          <span class="float-sm-right text-right">{{ isTaskER ? 'Promote Subject' : 'Task Name' }}
            <span class="text-danger" *ngIf="fieldsData.taskName?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2" *ngIf="fieldsData.taskName">
          <input type="text" class="form-control form-control-sm" formControlName="taskName">
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.serviceRequestType">
          <span class="float-sm-right text-right">Service Request Type
            <span class="text-danger" *ngIf="fieldsData.serviceRequestType?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.serviceRequestType">
          <ng-select name="serviceRequestTypeSelector" id="serviceRequestTypeSelector"
            formControlName="serviceRequestType">
            <ng-option disabled [value]="">--Select Service Request Type--</ng-option>
            <ng-option *ngFor="let item of serviceRequestTypeList" [value]=item.serviceRequestTypeId>
              {{item.serviceRequestTypeName}}</ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.dataOwner && isServiceRequestTypeForGetDataProd">
          <span class="float-sm-right text-right">Data Owner
            <span class="text-danger" *ngIf="fieldsData.dataOwner?.req || isServiceRequestTypeForGetDataProd">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.dataOwner && isServiceRequestTypeForGetDataProd">
          <ng-select name="dataOwnerSelector" id="dataOwnerSelector"
            formControlName="dataOwner">
            <ng-option disabled [value]="">--Select Service Request Type--</ng-option>
            <ng-option *ngFor="let item of dataOwnerDeptList" [value]=item.deptID>
              {{item.deptName}}</ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2 pl-0" *ngIf="fieldsData.customer && isShowRequireQuotaion">
          <span class="float-sm-right text-right">Customer Name/Broker No.
            <span class="text-danger" *ngIf="fieldsData.customer?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.customer && isShowRequireQuotaion">
          <input type="text" class="form-control form-control-sm" formControlName="customer">
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.objectiveType">
          <span class="float-sm-right text-right">Objective Type
            <span class="text-danger" *ngIf="fieldsData.objectiveType?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.objectiveType">
          <ng-select name="objectTypeSelector" id="objectTypeSelector" formControlName="objectiveType">
            <ng-option *ngFor="let item of objTypeList" [value]=item.id>{{item.name}}</ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.requireQuotation && isShowRequireQuotaion">
          <span class="float-sm-right text-right">Require Quotation
            <span class="text-danger" *ngIf="fieldsData?.requireQuotation?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData?.requireQuotation && isShowRequireQuotaion">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="requireQuotation" id="inlineRadio1" [value]="true"
              formControlName="requireQuotation">
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="requireQuotation" id="inlineRadio2" [value]="false"
              formControlName="requireQuotation">
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.quotationNumber && isShowRequireQuotaion">
          <span class="float-sm-right text-right">Quotation Number
            <span class="text-danger" *ngIf="fieldsData.quotationNumber?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData?.quotationNumber && isShowRequireQuotaion">
          <input type="text" class="form-control form-control-sm" formControlName="quotationNumber">
        </div>

      </div>
      <!-- end row -->
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.scopeOfWork">
          <span class="float-sm-right text-right">{{ isTaskER ? 'Promote Description' : 'Scope of Work' }}
            <span class="text-danger" *ngIf="fieldsData.scopeOfWork?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2" *ngIf="fieldsData.scopeOfWork">
          <textarea name="scopeOfWork" id="scopeOfWork" rows="10" class="form-control form-control-sm"
            formControlName="scopeOfWork"></textarea>
        </div>
        <div class="col-12 col-sm-2 py-sm-2 pl-sm-0" *ngIf="fieldsData.benefitDescription">
          <span class="float-sm-right text-right">Benefit Description
            <span class="text-danger" *ngIf="fieldsData.benefitDescription?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2" *ngIf="fieldsData.benefitDescription">
          <textarea name="benefitDesc" id="benefitDesc" rows="10" class="form-control form-control-sm"
            formControlName="benefitDescription"></textarea>
        </div>
      </div>

      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.toSupportBusinessDepartment">
          <span class="float-sm-right text-right">To Support Business Department
            <span class="text-danger" *ngIf="fieldsData.toSupportBusinessDepartment?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.toSupportBusinessDepartment">
          <ng-select name="toSupportBusinessDepartmentSelector" id="toSupportBusinessDepartmentSelector"
            formControlName="toSupportBusinessDepartment">
            <ng-option disabled [value]="">--Select Department--</ng-option>
            <ng-option *ngFor="let item of supportdeptList" [value]=item.deptID> {{item.deptName}}</ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.businessExpectedDate">
          <span class="float-sm-right text-right">Business Expected Date
            <span class="text-danger" *ngIf="fieldsData.businessExpectedDate?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.businessExpectedDate">
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/yyyy" formControlName="businessExpectedDate" name="dp"
              ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"
                [disabled]="disableDatePicker('businessExpectedDate')">
                <fa-icon [icon]="faCalendarAlt"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.endOfUsage && isServiceRequestTypeForGetDataProd">
          <span class="float-sm-right text-right">End Of Usage
            <span class="text-danger" *ngIf="fieldsData.endOfUsage?.req || isServiceRequestTypeForGetDataProd">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.endOfUsage && isServiceRequestTypeForGetDataProd">
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/yyyy" formControlName="endOfUsage" name="dp"
              ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"
                [disabled]="disableDatePicker('endOfUsage')">
                <fa-icon [icon]="faCalendarAlt"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.businessValue">
          <span class="float-sm-right text-right">Business Value
            <span class="text-danger" *ngIf="fieldsData.businessValue?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 pb-sm-2 pt-sm-4" *ngIf="fieldsData.businessValue">
          <input type="number" class="form-control form-control-sm" placeholder="0.00" formControlName="businessValue"
            min="0">
            <small class="text-muted">กรณีไม่สามารถระบุเป็นตัวเงินได้ ให้ใส่ค่า 0</small>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.estimateManDay">
          <span class="float-sm-right text-right">Estimate Manday
            <span class="text-danger" *ngIf="fieldsData.estimateManDay?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.estimateManDay">
          <input type="number" class="form-control form-control-sm" formControlName="estimateManDay" min="0">
        </div>
      </div>
      <div class="row d-flex align-items-center mb-3" *ngIf="fieldsData.estimateManDayDescription">
        <div class="col-12 col-sm-2 py-sm-2">
          <span class="float-sm-right text-right">Manday Description
            <span class="text-danger" *ngIf="fieldsData.estimateManDayDescription?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2">
          <textarea name="mandayDescription" id="mandayDescription" rows="5" class="form-control form-control-sm"
            formControlName="mandayDescription"></textarea>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.requiredUAT">
          <span class="float-sm-right text-right">Require UAT
            <span class="text-danger" *ngIf="fieldsData?.requiredUAT?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData?.requiredUAT">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="requiredUAT" id="inlineRadio1" [value]="true"
              formControlName="requiredUAT">
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="requiredUAT" id="inlineRadio2" [value]="false"
              formControlName="requiredUAT">
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.sensitiveInformation">
          <span class="float-sm-right text-right">Impact to Personal and Sensitive Information
            <span class="text-danger" *ngIf="fieldsData?.sensitiveInformation?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData?.sensitiveInformation">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="sensitiveInformation" id="sensitiveInformation1"
              [value]="true" formControlName="sensitiveInformation">
            <label class="form-check-label" for="sensitiveInformation1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="sensitiveInformation" id="sensitiveInformation2"
              [value]="false" formControlName="sensitiveInformation">
            <label class="form-check-label" for="sensitiveInformation2">No</label>
          </div>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.reason">
          <span class="float-sm-right text-right">Reason
            <span class="text-danger" *ngIf="fieldsData.reason?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2" *ngIf="fieldsData.reason">
          <ng-select name="reasonSelector" id="reasonSelector" formControlName="reason"
            (change)="onReasonChange($event)">
            <ng-option disabled [value]="">--Select Reason--</ng-option>
            <ng-option *ngFor="let item of reasonList" [value]=item.erReasonId> {{ item.definition }}</ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="reasonId === 3">
          <span class="float-sm-right text-right">Auto Create Incident
            <span class="text-danger" *ngIf="fieldsData?.autoCreateIncidentId?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="reasonId === 3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="autoCreateIncidentId" id="autoCreateIncidentId"
              [value]="true" formControlName="autoCreateIncidentId">
            <label class="form-check-label" for="autoCreateIncidentId">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="autoCreateIncidentId" id="autoCreateIncidentId"
              [value]="false" formControlName="autoCreateIncidentId">
            <label class="form-check-label" for="autoCreateIncidentId">No</label>
          </div>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.reason">
          <span class="float-sm-right text-right">Incident ID</span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.incidentId">
          <div class="input-group">
            <input type="text" class="form-control form-control-sm" formControlName="incidentId"
              placeholder="{{placeholderIncidentChangeId}}"
              [readonly]="reasonId === 3 && form.value.autoCreateIncidentId"
              (blur)="onChangeIncident($event.target.value)">
            <span *ngIf="isValidIssueIncident && form.value.incidentId" class="icon-check-issue text-success">
              <fa-icon [icon]="faCheckCircle"></fa-icon>
            </span>
            <span *ngIf="!isValidIssueIncident && form.value.incidentId" class="icon-check-issue text-danger">
              <fa-icon [icon]="faTimesCircle"></fa-icon>
            </span>
          </div>
          <div class="mt-2" *ngIf="isValidIssueIncident">
            <b>Name</b>: {{ issueNameIncident }}
          </div>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="(reasonId === 4 || reasonId === 5 )">
          <span class="float-sm-right text-right">SR Task Id
            <span class="text-danger" *ngIf="fieldsData?.autoCreateIncidentId?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="reasonId === 4 || reasonId === 5 ">
          <ng-select [items]="srTaskList" bindValue="taskId" placeholder="--Select Task Id--" bindLabel="taskName"
            formControlName="srTaskId" [searchFn]="onSearchSrTask" [virtualScroll]="true">
            <ng-template ng-label-tmp let-item="item">
              ({{item.taskId | taskId}}) {{item.taskName}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">({{item.taskId | taskId}}) {{item.taskName}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="reasonId === 1 || reasonId === 2">
          <span class="float-sm-right text-right">Change ID
            <span class="text-danger" *ngIf="fieldsData.changeId?.req || reasonId === 2">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="reasonId === 1 || reasonId === 2">
          <div class="input-group">
            <input type="text" class="form-control form-control-sm" formControlName="changeId"
              placeholder="{{placeholderIncidentChangeId}}">
            <span *ngIf="isValidIssue && form.value.changeId" class="icon-check-issue text-success">
              <fa-icon [icon]="faCheckCircle"></fa-icon>
            </span>
            <span *ngIf="!isValidIssue && form.value.changeId" class="icon-check-issue text-danger">
              <fa-icon [icon]="faTimesCircle"></fa-icon>
            </span>
          </div>
          <div class="mt-2" *ngIf="isValidIssue">
            <b>Name</b>: {{ issueName }}
          </div>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.planPromoteDateTime">
          <span class="float-sm-right text-right">Expected Promote Date
            <span class="text-danger" *ngIf="fieldsData.planPromoteDateTime?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.planPromoteDateTime">
          <div class="input-group">
            <input class="form-control" placeholder="dd/mm/yyyy" formControlName="planPromoteDate" name="dp"
              ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"
                [disabled]="disableDatePicker('planPromoteDate')">
                <fa-icon [icon]="faCalendarAlt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.requiredQC">
          <span class="float-sm-right text-right">Required QC
            <span class="text-danger" *ngIf="fieldsData?.requiredQC?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData?.requiredQC">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="requiredQC" id="requiredQC1" [value]="true"
              formControlName="requiredQC">
            <label class="form-check-label" for="requiredQC1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="requiredQC" id="requiredQC2" [value]="false"
              formControlName="requiredQC">
            <label class="form-check-label" for="requiredQC2">No</label>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.releaseDevice">
          <span class="float-sm-right text-right">เครื่องที่จะ Release
            <span class="text-danger" *ngIf="fieldsData.releaseDevice?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2" *ngIf="fieldsData?.releaseDevice">
          <textarea name="releaseDevice" id="releaseDevice" rows="5" class="form-control form-control-sm"
            formControlName="releaseDevice"></textarea>
        </div>
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData?.postCheckRelease">
          <span class="float-sm-right text-right">การตรวจสอบหลัง Release
            <span class="text-danger" *ngIf="fieldsData.postCheckRelease?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-10 py-sm-2" *ngIf="fieldsData?.postCheckRelease">
          <textarea name="postCheckRelease" id="postCheckRelease" rows="5" class="form-control form-control-sm"
            formControlName="postCheckRelease"></textarea>
        </div>
      </div>
      <div class="row d-flex align-items-center" *ngIf="fieldsData?.toSupportBusiness">
        <div class="col-12 col-sm-2 py-sm-2">
          <span class="float-sm-right text-right">To Support Business User
          </span>
        </div>
        <div class="col-12 col-sm-9 py-sm-2 p-fluid">
          <p-autoComplete #autoComplete formControlName="toSupportBusiness" [suggestions]="filteredGroups"
            (completeMethod)="searchEmployee($event)" [multiple]="true" dataKey="empId" field="nameEn" [dropdown]="true"
            minLength="0" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
            <ng-template let-value pTemplate="selectedItem">
              <span style="font-size:18px">{{value.nameEn}}</span>
            </ng-template>
          </p-autoComplete>
        </div>

      </div>
    </form>
  </div>
</div>