<div class="card card-top bg-n7">
  <div class="card-header">
    <h6 class="card-title">
      <a (click)="collapseApprovalDetail.toggle()" [attr.aria-expanded]="!isCollapse" aria-controls="collapseExample">
        <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
        <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
        Approval Detail
      </a>
    </h6>
  </div>
  <div class="card-body" #collapseApprovalDetail="ngbCollapse" [(ngbCollapse)]="isCollapse">
    <form [formGroup]="form" *ngIf="form">
      <div class="row d-flex align-items-center mb-3">
        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.itHeadEr">
          <span class="float-sm-right text-right">IT Head <span class="text-danger"
              *ngIf="fieldsData.itHeadEr?.req">*</span></span>
        </div>
        <div class="col-12 col-sm-4" *ngIf="fieldsData.itHeadEr">
          <div class="input-group">
            <ng-select [items]="itHeadList" formControlName="itHeadEr" bindLabel="nameEn" bindValue="empID"
              appendTo="body" class="custom" style="width: 300px;">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.IT_HEAD.name)"
                [disabled]="!fieldsData.itHeadEr?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.itHeadComment">
          <span class="float-sm-right text-right">IT Head Comment <span class="text-danger"
              *ngIf="fieldsData.itHeadComment?.req">*</span></span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.itHeadComment">
          <textarea name="itHeadComment" id="itHeadComment" rows="2" class="form-control form-control-sm"
            formControlName="itHeadComment"></textarea>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.cab">
          <span class="float-sm-right text-right">CAB <span class="text-danger"
              *ngIf="fieldsData.cab?.req">*</span></span>
        </div>
        <div class="col-12 col-sm-4" *ngIf="fieldsData.cab">
          <div class="input-group">
            <ng-select [items]="cabList" formControlName="cab" bindLabel="nameEn" bindValue="empID" appendTo="body"
              class="custom" style="width: 300px;">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.CAB.name)"
                [disabled]="!fieldsData.cab?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.cabComment">
          <span class="float-sm-right text-right">CAB Comment <span class="text-danger"
              *ngIf="fieldsData.cabComment?.req">*</span></span>
        </div>
        <div class="col-12 col-sm-4 py-sm-2" *ngIf="fieldsData.cabComment">
          <textarea name="cabComment" id="cabComment" rows="2" class="form-control form-control-sm"
            formControlName="cabComment"></textarea>
        </div>

        <div class="col-12 col-sm-2 py-sm-2" *ngIf="fieldsData.se">
          <span class="float-sm-right text-right">SE <span class="text-danger"
              *ngIf="fieldsData.se?.req">*</span></span>
        </div>
        <div class="col-12 col-sm-4" *ngIf="fieldsData.se">
          <div class="input-group">
            <ng-select [items]="seList" formControlName="se" bindLabel="nameEn" bindValue="empID" appendTo="body"
              class="custom" style="width: 300px;">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.SE.name)"
                [disabled]="!fieldsData.se?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>