import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCalendarAlt, faFileExcel,faFileExport, faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {
  EmployeeControllerService,
  DepartmentControllerService,
  DepartmentResponse,
  EmployeeResponse,
  ServiceTypeResponse
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { SearchTimeSheetViewCriteria } from "src/app/model/search-timesheet-view-criteria.model"
import { ReportDataSource, TimesheetViewService } from '../services/timesheet-view.service';

@Component({
  selector: 'app-timesheet-view',
  templateUrl: './timesheet-view.component.html',
  styleUrls: ['./timesheet-view.component.scss']
})
export class TimesheetViewComponent implements OnInit {

  constructor(
    private departmentService: DepartmentControllerService,
    private employeeService: EmployeeControllerService,
    private router: Router,
    private reportService: TimesheetViewService,
  ) { }

  public faFileExcel = faFileExcel;
  public faFileExport = faFileExport;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public active: 1;
  public isSearch = true;
  public isSelectedAll = false;
  public isDisableApproveBtn = true;

  public selectedItemTotal = 0;
  public page = 1;
  public pageSize = 10;
  public totalElements = 0;
  public dateCriteriaInvalid = false;
  public activeEmployee = true;

  public currentYear: number;
  public currenDepartment: DepartmentResponse;
  public reportDataSource: ReportDataSource;
  public departmentList: DepartmentResponse[] = [];
  public serviceTypeList: ServiceTypeResponse[] = [];
  public yearList: number[] = [];
  public itEmployeeList: EmployeeResponse[] = [];
  public reportColumns : String[] = [
    'empId', 'nameEn', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
  ];
  
  public form: FormGroup;

  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear();
    this.setYearList(this.yearList, this.currentYear);

    this.form = new FormGroup({ 
      year: new FormControl(this.currentYear, Validators.required),
      itDepartment: new FormControl(),
      onlyActiveUser: new FormControl(true),
      employee: new FormControl(),

    });

    this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
      this.departmentList = result.data;
    });

    this.employeeService.getInternalItStaffsUsingGET(this.activeEmployee).subscribe((result) => {
      this.itEmployeeList = result.data;
    });

    this.updateCriteria(this.setCriteria());
    this.reportService.startSubscribe();
    this.reportDataSource = this.reportService.reportDataSource; 

    this.reportService.totalElements.subscribe((x)=> { 
      this.totalElements = x;
    });

    this.form.controls.itDepartment.valueChanges.subscribe(
      (value: DepartmentResponse) => {
        if (value) {
          this.currenDepartment = value;
          this.employeeService
            .getInternalEmployeeByDeptUsingGET(value.deptID, this.activeEmployee)
            .subscribe((result) => {
              this.itEmployeeList = result.data;
            });
        } else {
          this.employeeService.getInternalItStaffsUsingGET(this.activeEmployee).subscribe((result) => {
            this.itEmployeeList = result.data;
          }); 
        }
      }
    );

    this.form.controls.onlyActiveUser.valueChanges.subscribe(
      (value: boolean) => {
        this.activeEmployee = value;
        if (this.currenDepartment) {
          this.employeeService
            .getInternalEmployeeByDeptUsingGET(this.currenDepartment.deptID, this.activeEmployee)
            .subscribe((result) => {
              this.itEmployeeList = result.data;
            });
        } else {
          this.employeeService.getInternalItStaffsUsingGET(this.activeEmployee).subscribe((result) => {
            this.itEmployeeList = result.data;
          }); 
        }
      }
    );
  }

 public setYearList(yList: number[], curY: number){
   for(var i = 0; i<3; i++){
     yList[i] = curY - i; 
   }
 }

  public search(): void {
      this.selectedItemTotal = 0;
      let serviceCriteria: SearchTimeSheetViewCriteria = this.setCriteria();
      if ( serviceCriteria.deptId || serviceCriteria.empId.length > 0){
        this.updateCriteria(serviceCriteria);
      }
  }

  public updateCriteria(criteria: SearchTimeSheetViewCriteria): void {
    this.reportService.searchReportCriteria.next(criteria);
  }

  private setCriteria(): SearchTimeSheetViewCriteria {
    let criteria: SearchTimeSheetViewCriteria = new SearchTimeSheetViewCriteria();
    if ( this.form.value.year == null ) {
      this.form.controls.year.setValue(this.currentYear);
    }
    criteria.year = this.form.value.year;
    criteria.deptId = this.form.value.itDepartment ? this.form.value.itDepartment.deptID: null;
    criteria.empId = this.form.controls.employee.value ? 
                    this.form.controls.employee.value.map((x) => x.empID)
                    : null;
    criteria.onlyActive = this.form.value.onlyActiveUser;
    return criteria;
  }

public redirectToCreate(): void {
  this.router.navigate(["/report"]);
}

public clear(): void {
  this.form.reset();
  this.form.controls.year.setValue(this.currentYear);
  this.form.controls.onlyActiveUser.setValue(true);
}
}
