import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faAngleDown, faAngleUp, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { fileResponse } from "src/app/model/file.model";

@Component({
  selector: "app-attachment-box",
  templateUrl: "./attachment-box.component.html",
  styleUrls: ["./attachment-box.component.scss"],
})
export class AttachmentBoxComponent implements OnInit {
  @Input() fileList: fileResponse[];
  @Input() isEditMode: boolean;
  @Input() isCollapse: boolean;
  @Output() isCollapseChange = new EventEmitter<boolean>();
  @Output() fileListChange = new EventEmitter<fileResponse[]>();

  constructor() {}

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faPaperclip = faPaperclip;

  ngOnInit() {}

  public change(value: fileResponse[]): void {
    this.fileList = value;
    this.fileListChange.emit(this.fileList);
  }
  toggleChange(){
    this.isCollapseChange.emit(this.isCollapse);
  }
}
