export class SearchReportCriteria {
    deptId?: string;
    endDate?: string;
    startDate?: string;
    taskCategoryId?: number;
  
    SearchReportCriteria(): void {
      this.deptId = "";
      this.endDate = "";
      this.startDate = "";
      this.taskCategoryId = null;
    }
  }