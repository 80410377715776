<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
        <h5 style="padding: 0; margin: 0;">Timesheet Report</h5>
        <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
    </div>
    <div class="card bg-n7 mb-3" #SearchFilter>
        <div class="card-body">
          <h6 class="p-0 m-0;">
            <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
              <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
              <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
              Search Filters
            </a>
          </h6>
        <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
         <form [formGroup]="form" *ngIf="form">   
          <div class="row mb-1">
            <div class="col-12 col-sm-6 col-lg-4" #CreatedDate>
                <span class="font-weight-bold">Working Date</span>
                <form >
                  <div class="form-group hidden">
                    <div class="input-group">
                      <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker #datepicker="ngbDatepicker"
                        [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                        outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                      <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group w-100">
                      <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}"  placeholder="dd/mm/yyyy" name="dpFromDate"
                        [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                        disabled>
                        <span class="btn date-range-symbol">-</span>
                        <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}"  placeholder="dd/mm/yyyy" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                        disabled>
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                          <fa-icon [icon]="faCalendarAlt"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
              <span class="font-weight-bold ">IT Department</span>
                <ng-select name="itDepartment" id="itDepartment" formControlName="itDepartment">
                  <ng-option disabled [value]="">--Select Department--</ng-option>
                  <ng-option *ngFor="let item of itDepartmentList" [value]=item> {{item.deptName}}</ng-option>
                </ng-select>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 col-sm-6 col-lg-4" #component>
                <span class="font-weight-bold">Service</span>
                  <ng-select name="component" id="component" formControlName="component">
                    <ng-option disabled [value]="">--Select Service--</ng-option>
                    <ng-option *ngFor="let item of componentList" [value]=item.componentId> {{item.componentName}}</ng-option>
                  </ng-select>
            </div>
            <div class="col-12 col-sm-6 col-lg-4" #employee>
                <span class="font-weight-bold">Employee</span>
                    <ng-select name="employee" id="employee" formControlName="employee">
                    <ng-option disabled [value]="">--Select Service Manager--</ng-option>
                    <ng-option *ngFor="let item of itEmployeeList" [value]=item.empID> {{item.nameEn}}</ng-option>
                    </ng-select>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <span class="font-weight-bold">Task Id</span>
                <input type="number" class="form-control" formControlName="taskId" id="taskId">
            </div>
          </div>
         </form>
          <div class="d-flex justify-content-end mt-3">
            <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
            <button class="btn btn-primary" type="button" (click)="search()">Search</button>
          </div>
        </div>
        </div>  
      </div>
      <div #Table>
  
        <div class="d-flex justify-content-end my-3">
            <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
            </app-row-per-page>
        </div>
        <div class="table-responsive" #servicesTable *ngIf="timesheetReportDataSource.data">

          <table mat-table matSort class="table table-hover" cdk-table [dataSource]="timesheetReportDataSource"
          (matSortChange)=onSortChange($event)>

          <ng-container cdkColumnDef="year">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Year</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.year}}</td>
          </ng-container>
          
          <ng-container cdkColumnDef="date">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Date</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.workDate}}</td>
          </ng-container>

          <ng-container cdkColumnDef="employee">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Employee</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdByName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="taskId">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Task ID</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskId | taskId}}</td>
          </ng-container>

          <ng-container cdkColumnDef="mainTaskName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Main Task Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="serviceName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Service Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.componentName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="mainTaskCategoryName">
            <th class="table-header-cell col-2 text-left" cdk-header-cell *cdkHeaderCellDef >Main Task Category Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskType}}</td>
          </ng-container>    

          <ng-container cdkColumnDef="totalEstimation">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Total Estimation</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.totalEstimateMandays | number}}</td>
          </ng-container>

          <ng-container cdkColumnDef="taskSize">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Task Size</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskSize}}</td>
          </ng-container>

          <ng-container cdkColumnDef="manDay">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Man Day</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.duration | number}}</td>
          </ng-container>

          <ng-container cdkColumnDef="buUser">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >BU User</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buUserName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="buUnit">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Unit</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buUnitName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="buDepartment">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Department</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDepartmentName}}</td>
          </ng-container>  

          <ng-container cdkColumnDef="buDivision">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Division</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDivisionName}}</td>
          </ng-container>  


          <tr class="table-header" cdk-header-row *cdkHeaderRowDef="TimesheetReportColumns"></tr>
          <tr class="table-row" cdk-row *cdkRowDef="let row; columns: TimesheetReportColumns"></tr> 
          </table>  

          <div class="not-found text-center" *ngIf="timesheetReportDataSource.data == null || totalElements === 0" >
            <div class="my-3"> No Timesheet found.</div>
          </div>

        </div>

        <div class="bg-black-haze">
          <div class="align-self-center">
            <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
              [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
              (pageChange)="updatePage()">
            </ngb-pagination>
          </div>
        </div>
  </div>
</div>
