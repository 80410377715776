<div class="row no-gutters" style="height: 100vh;">
  <div *ngIf="isCollapsed" class="col-12 col-md-3 col-xl-2">
    <div class="sidenav">

      <div class="d-flex justify-content-center mb-2">
        <!-- <img class="circular--square img-responsive" style="max-height: 60px; padding: 5px;"
          src="assets/images/SET_Logo.png"> -->
          <img class="circular--square img-responsive" style="max-height: 60px; padding: 5px;"
          src="assets/images/logo_set_50.png">
      </div>

      <button class="dropdown-btn d-flex align-items-center" type="button" (click)="collapse.toggle()"
        [attr.aria-expanded]="!isMenu1" aria-controls="collapseExample" [routerLink]="['task']"
        routerLinkActive="router-link-active-head">
        <fa-icon [icon]="faSuitcase" class="mr-2"></fa-icon>
        Tasks
        <fa-icon *ngIf="isMenu1" [icon]="faAngleDown" class="ml-auto"></fa-icon>
        <fa-icon *ngIf="!isMenu1" [icon]="faAngleUp" class="ml-auto"></fa-icon>
      </button>
      <div class="dropdown-container" id="menu-1" #collapse="ngbCollapse" [(ngbCollapse)]="isMenu1">
        <a [routerLink]="['/task/my-task']" routerLinkActive="router-link-active-sub" (click)="clear()">My Tasks</a>
        <a *ngIf="isShowCSTaskMenu" [routerLink]="['/task/cs-task']" routerLinkActive="router-link-active-sub" (click)="clear()">CS
          Tasks</a>
        <a [routerLink]="['/task/advanced-search-task']" routerLinkActive="router-link-active-sub" (click)="clear()">Advanced
          Search</a>
      </div>
      <a [routerLink]="['/form']" routerLinkActive="router-link-active-head" (click)="clear()">
        <fa-icon [icon]="faFileAlt" class="mr-2"></fa-icon>
        Create Task
      </a>
      <button *ngIf="isShowPromoteTaskMenu" class="dropdown-btn d-flex align-items-center" type="button"
        (click)="collapse2.toggle()" [attr.aria-expanded]="!isMenu2" aria-controls="collapseExample"
        [routerLink]="['/promote-form/create-promote-form']" routerLinkActive="router-link-active-head">
        <fa-icon [icon]="faTasks" class="mr-2"></fa-icon> Promote Tasks
        <fa-icon *ngIf="isMenu2" [icon]="faAngleDown" class="ml-auto"></fa-icon>
        <fa-icon *ngIf="!isMenu2" [icon]="faAngleUp" class="ml-auto"></fa-icon>
      </button>
      <div class="dropdown-container" id="menu-5" #collapse2="ngbCollapse" [(ngbCollapse)]="isMenu2">
        <a [routerLink]="['/promote-form/create-promote-form']" routerLinkActive="router-link-active-sub" (click)="clear()">Create
          Promote Form</a>
        <a [routerLink]="['/promote-form/promote-list']" routerLinkActive="router-link-active-sub" (click)="clear('promoteList')">
          Promote List
        </a>
        <a href="#">Front-D</a>
        <a [routerLink]="['/report/duplicate-file']" routerLinkActive="router-link-active-sub" (click)="clear()">Duplicate Promote
          File</a>
      </div>
      <a *ngIf="isShowReviewMeetingMenu" [routerLink]="['/review-meeting']" routerLinkActive="router-link-active-head" (click)="clear()">
        <fa-icon [icon]="faSuitcase" class="mr-2"></fa-icon>
        Review Meeting
      </a>
      <a *ngIf="isShowTimesheetMenu" [routerLink]="['/timesheet']" routerLinkActive="router-link-active-head" (click)="clear()">
        <fa-icon [icon]="faClock" class="mr-2"></fa-icon>
        Timesheet
      </a>
      <button class="dropdown-btn d-flex align-items-center" (click)="collapse3.toggle();"
        [attr.aria-expanded]="!isMenuReport" aria-controls="collapseExample" [routerLink]="['/report']"
        routerLinkActive="router-link-active-head">
        <fa-icon [icon]="faClipboard" class="mr-2"></fa-icon>
        Report
        <fa-icon *ngIf="isMenuReport" [icon]="faAngleDown" class="ml-auto"></fa-icon>
        <fa-icon *ngIf="!isMenuReport" [icon]="faAngleUp" class="ml-auto"></fa-icon>
      </button>
      <div class="dropdown-container" id="menu-report" #collapse3="ngbCollapse" [(ngbCollapse)]="isMenuReport">
        <a (click)="clear()" [routerLink]="['/report/cr-review']" *ngIf="isShowReportReviewMeeting" routerLinkActive="router-link-active-sub">CR Review Meeting</a>
        <a (click)="clear()" [routerLink]="['/report/ir-review']" *ngIf="isShowReportReviewMeeting" routerLinkActive="router-link-active-sub">IR Review Meeting</a>
        <a (click)="clear()" [routerLink]="['/report/outstanding']" routerLinkActive="router-link-active-sub">Outstanding Task</a>
        <a (click)="clear()" [routerLink]="['/report/duplicate-file']" *ngIf="isShowReportMenu" routerLinkActive="router-link-active-sub">Promote Duplicate
          File</a>
        <a (click)="clear()" [routerLink]="['/report/promote-file']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">Promote File</a>
        <a (click)="clear()" [routerLink]="['/report/promote-form-by-promote-id']" *ngIf="isShowReportMenu" routerLinkActive="router-link-active-sub">Promote
          Form by Promote ID</a>
        <a (click)="clear()" [routerLink]="['/report/promoted']" routerLinkActive="router-link-active-sub">Promoted Task</a>
        <a (click)="clear()" [routerLink]="['/report/special-role']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">Special Roles</a>
        <a (click)="clear()" [routerLink]="['/report/timesheet-report']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">Timesheet Task</a>
        <a (click)="clear()" [routerLink]="['/report/timesheet-view']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">Timesheet by Year</a>
        <a (click)="clear()" [routerLink]="['/report/new-timesheet-report']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">New Timesheet Report</a>
        <a (click)="clear()" [routerLink]="['/report/request-production-data']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">SR For Get Production Data</a>
        <!-- <a (click)="clear()" [routerLink]="['/report/timesheet-report-dashboard']" *ngIf="isShowReportMenu"  routerLinkActive="router-link-active-sub">New Timesheet Report Dashboard</a> -->
      </div>
      <a *ngIf="isShowServiceCatalogMenu" [routerLink]="['/service-management']"
        routerLinkActive="router-link-active-head" (click)="clear()">
        <fa-icon [icon]="faBookOpen" class="mr-2"></fa-icon>
        Service Catalog
      </a>
      <a *ngIf="isShowUserRoleManagementMenu" [routerLink]="['/user-role-manage']"
        routerLinkActive="router-link-active-head" (click)="clear()">
        <fa-icon [icon]="faUserFriends" class="mr-2"></fa-icon>
        User&Role Management
      </a>
      <a *ngIf="isShowUserRoleManagementMenu" [routerLink]="['/announcement/info']"
        routerLinkActive="router-link-active-head" (click)="clear()">
        <fa-icon [icon]="faBookOpen" class="mr-2"></fa-icon>
        Announcement
      </a>
      <a *ngIf="isShowUserRoleManagementMenu" [routerLink]="['/jira-project-management']"
        routerLinkActive="router-link-active-head" (click)="clear()">
        <img src="assets/images/JIRA-icon.png" class="jira-icon mr-2">
        Jira Project Management
      </a>
      <a (click)="goToFeedBack()">
        <fa-icon [icon]="faComments" class="mr-2"></fa-icon>
        Send Feedback to Support Team
      </a>
      <a (click)="goToOldWorkFlow()">
        <fa-icon [icon]="faSearch" class="mr-2"></fa-icon>
        Search Task on Old Workflow
      </a>
    </div>
  </div>
  <div [className]="isCollapsed ? 'col-12 col-md-9 col-xl-10' : 'col-12'">
    <nav class="navbar navbar-dark bg-white border-bottom">
      <div class="d-flex align-items-center">
        <button class="btn" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseBasic">
          <fa-icon [icon]="faBars"></fa-icon>
        </button>
        <h5 class="mx-2 my-0">SET IT WORKFLOW</h5>
      </div>
      <ul class="nav justify-content-end">
        <li class="nav-item">
        </li>
        <li class="nav-item d-flex">
          <div class="mx-sm-3 font-weight-bold align-self-center">{{userProfile.nameEn || '-'}} ({{userProfile.role ||
            '-'}})</div>
        </li>
        <li class="nav-item">
          <div class="photo-profile rounded-circle"
            style="background-image : url('{{userProfile.imageUrl}}') ,url('assets/images/noProfile.jpg') "></div>
        </li>
      </ul>
    </nav>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>