import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from "@angular/material/sort";
import { Router } from '@angular/router';
import { faAngleDown, faAngleUp, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import {
  DepartmentControllerService,
  DepartmentResponse,
  EmployeeResponse,
  ServiceTypeResponse
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { PromotedReportCriteria } from "src/app/model/promoted-report-criteria.model";
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';
import { PromotedReportDataSource, PromotedReportService } from '../services/promoted-report.service';


@Component({
  selector: 'app-promoted-task',
  templateUrl: './promoted-task.component.html',
  styleUrls: ['./promoted-task.component.scss']
})
export class PromotedTaskComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private departmentService: DepartmentControllerService,
    private router: Router,
    private reportService: PromotedReportService,
    private _httpClient: HttpClient,
    private authService: AuthService
  ) { }

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public active: 1;
  public isSearch = true;
  public isSelectedAll = false;
  public isDisableApproveBtn = true;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  public selectedItemTotal = 0;
  public page = 1;
  public pageSize = 10;
  public totalElements = 0;
  public dateCriteriaInvalid = false;

  public promotedReportDataSource: PromotedReportDataSource;
  public itDepartmentList: DepartmentResponse[] = [];
  public buDepartmentList: DepartmentResponse[] = [];
  public serviceTypeList: ServiceTypeResponse[] = [];
  public itEmployeeList: EmployeeResponse[] = [];
  
  public promotedReportColumns : String[] = [
    'taskType','promoteId','promotedTaskId','promoteSubject','serviceName','promotedDate','status',
    'pendingAt','mainTaskId','mainTaskName','itDepartment','totalEstimationManday','createdByName','createdDate','buUnitName','buDepartmentName','buDivisionName']; 
  

  public form: FormGroup;
  ngOnInit(): void {

    this.form = new FormGroup({
      itDepartment: new FormControl(),
      buDepartment: new FormControl(),
      endDate: new FormControl(),
      startDate: new FormControl(),
    });

    this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
      this.itDepartmentList = result.data;
    });
    this.departmentService.getBUDepartmentUsingGET().subscribe((result) => {
      this.buDepartmentList = result.data;
    });
    this.reportService.totalElements.subscribe((x)=> { 
      this.totalElements = x;
    });

    this.reportService.searchPageSize.next(this.pageSize);
    this.updateCriteria(this.setCriteria());
    this.reportService.startPromotedReportSubscribe();
    this.promotedReportDataSource = this.reportService.promotedReportDataSource;    

  }
  public onSortChange(sort: Sort) {
    this.reportService.searchSort.next(sort);
}

public updateCriteria(criteria: PromotedReportCriteria) {
  this.page = 0;
    this.reportService.searchPage.next(0);
    this.reportService.searchReportCriteria.next(criteria);
    this.reportService.searchSort.next(null);
}

public redirectToCreate(): void {
  this.router.navigate(["/report"]);
}

public clear(): void {
  this.form.reset();
  this.fromDate = null;
  this.toDate = null;
}

private setCriteria(): PromotedReportCriteria {
  let criteria: PromotedReportCriteria = new PromotedReportCriteria();
  criteria.itDeptId = this.form.value.itDepartment ? this.form.value.itDepartment: null;
  criteria.buDeptId = this.form.value.buDepartment ? this.form.value.buDepartment.deptID: null;
  criteria.endDate = this.toDate ? this.formatter.format(this.toDate): null;
  criteria.startDate = this.fromDate ? this.formatter.format(this.fromDate) : null;
  return criteria;
}

public search(): void {
  this.selectedItemTotal = 0;
  let serviceCriteria: PromotedReportCriteria = this.setCriteria();
  if ( serviceCriteria.itDeptId || serviceCriteria.buDeptId){
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.dateCriteriaInvalid = false;
      this.updateCriteria(serviceCriteria);
    }else {
      this.dateCriteriaInvalid = true;
    }
  } else {
    this.dateCriteriaInvalid = false;
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.updateCriteria(serviceCriteria);
    }
  }
}

public updatePage(): void {
    this.reportService.searchPage.next(this.page - 1);
}

public updatePageSize(size: number): void {
    this.pageSize = size;
    this.reportService.searchPageSize.next(this.pageSize);
}

public onDateSelection(date: NgbDate): void {

  if (!this.fromDate && !this.toDate) {
    this.fromDate = date;
  } else if (
    this.fromDate &&
    !this.toDate &&
    date &&
    date.after(this.fromDate)
  ) {
    this.toDate = date;
  } else {
    this.toDate = null;
    this.fromDate = date;
  }
}

public isHovered(date: NgbDate): boolean {
  this.dateCriteriaInvalid = false;
  return (
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    date.after(this.fromDate) &&
    date.before(this.hoveredDate)
  );
}

public isInside(date: NgbDate): boolean {
  return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
}

public isRange(date: NgbDate): boolean | void {
  return (
    date.equals(this.fromDate) ||
    (this.toDate && date.equals(this.toDate)) ||
    this.isInside(date) ||
    this.isHovered(date)
  );
}

public validateInput(
  currentValue: NgbDate | null,
  input: string
): NgbDate | null {
  const parsed = this.formatter.parse(input);
  this.dateCriteriaInvalid = false;
  return parsed && this.calendar.isValid(NgbDate.from(parsed))
    ? NgbDate.from(parsed)
    : currentValue;
}


private setExportCriteria(): PromotedReportCriteria {
  let criteria: PromotedReportCriteria = new PromotedReportCriteria();
  criteria.itDeptId = this.form.value.itDepartment ? this.form.value.itDepartment: null;
  criteria.buDeptId = this.form.value.buDepartment ? this.form.value.buDepartment.deptID: null;    
  criteria.endDate = this.toDate ? this.dateString(this.toDate) : null;
  criteria.startDate = this.fromDate ? this.dateString(this.fromDate) : null;
  return criteria
}

private dateString(ngbDate: NgbDate): string {
  let dateStringFormat = "";
  let dateString: String = ngbDate.day < 10 ? "0"+ngbDate.day.toString() : ngbDate.day.toString();
  let montString: String = ngbDate.month < 10 ? "0"+ngbDate.month.toString() : ngbDate.month.toString();

  dateStringFormat = ngbDate.year.toString() + "-" + montString  + "-" + dateString ;
  return dateStringFormat;
}

public export (){
  let serviceCriteria: PromotedReportCriteria = this.setExportCriteria();
  if (serviceCriteria.itDeptId || serviceCriteria.buDeptId){
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.dateCriteriaInvalid = false;
      this.exportExcel(serviceCriteria);
    }else {
      this.dateCriteriaInvalid = true;
    }
  } else {
    this.dateCriteriaInvalid = false;
    if(serviceCriteria.startDate && serviceCriteria.endDate){
      this.exportExcel(serviceCriteria);
    }
  }
}

public exportExcel(criteria: PromotedReportCriteria){
  this._httpClient.post(config.exportPromotedTask,criteria,
    { headers: { 'Authorization': ('bearer ' + this.authService.token) },
      responseType: 'arraybuffer'})
  .subscribe((response: any) => {
  const filename = 'promoted-task-report.xlsx';
  this.reportService.downLoadFile(response, "application/octet-stream", filename);
  });
}

}
