import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faBell,
  faBookOpen,
  faBullhorn,
  faClipboard,
  faClock,
  faComments,
  faFileAlt,
  faSuitcase,
  faTasks,
  faUserFriends,
  faUsers,
  faSignOutAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationControllerService } from '@set-it-workflow/set-it-workflow-ts-angular';
import { TaskWorklistPersonResponse } from '@set-it-workflow/set-it-workflow-ts-angular/model/taskWorklistPersonResponse';
import { filter } from 'rxjs/operators';
import { roleUser } from "src/app/constants/role.constant";
import { config } from "src/environments/config";
import { AuthService } from "../../service/auth.service";
import {
  UserProfileService,
  UserProfile,
} from "../../service/user-profile.service";
import { NotificationsService } from '../shared/service/notifications.service';

@Component({
  selector: "app-private",
  templateUrl: "./private.component.html",
  styleUrls: ["./private.component.scss"],
})
export class PrivateComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userService: UserProfileService,
    private notificationService: NotificationsService,
    private router: Router
  ) { }
  public isCollapsed = true;
  public isMenu1 = false;
  public isMenu2 = true;
  public isMenu4 = true;
  public isMenu5 = true;
  public isMenu6 = true;
  public isMenuReport = true;

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faBars = faBars;
  public faBell = faBell;
  public faUserFriends = faUserFriends;
  public faClock = faClock;
  public faBookOpen = faBookOpen;
  public faFileAlt = faFileAlt;
  public faBullhorn = faBullhorn;
  public faSuitcase = faSuitcase;
  public faUsers = faUsers;
  public faClipboard = faClipboard;
  public faComments = faComments;
  public faTasks = faTasks;
  public faSignOutAlt = faSignOutAlt;
  public faSearch = faSearch;

  public loginName = "";
  public userProfile: UserProfile = new UserProfile();
  public role = roleUser;

  public totalNewTasks: number;
  public tasks: TaskWorklistPersonResponse[];
  showMore = false;
  feedbackUrl = 'https://hr.set.or.th/fast-feedback-send-feedback/19808';

  ngOnInit() {
    this.loginName = this.authService.getName();
    this.userService.setUserProfile();
    this.userService.userProfile$.subscribe((userProfile) => {
      this.userProfile = userProfile;
    });
    //this.notificationService.startSubscribe();
    //this.getNotificationTasks();
  }

  getNotificationTasks(): void {
    this.notificationService.tasks.subscribe(tasks => this.tasks = tasks);
    this.notificationService.totalNewTasks.subscribe(total => this.totalNewTasks = total);
  }

  goToTaskDetail(task: TaskWorklistPersonResponse): void {
    if (task.taskCategory === 'RELEASE') {
      if (task.releaseFormId) {
        this.redirectTo(`/promote-form/detail/${task.releaseFormId}/${task.status}`, task.taskId);
      } else {
        this.redirectTo(`/promote-form/create-release-form/${task.taskId}`, task.taskId);

      }
    } else {
      this.redirectTo(`/task/detail/${task.taskId}/${task.status}/${task.taskCategoryId}`, task.taskId);
    }
  }

  redirectTo(uri: string, taskId: number) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
    //this.notificationService.markAsViewedTask(taskId);
  }

  goToFeedBack() {
    window.open(this.feedbackUrl, '_blank').focus();
  }

  get isShowCSTaskMenu(): boolean {
    return this.userProfile.isCanViewCs
  }
  get isShowPromoteTaskMenu(): boolean {
    return (
      this.userProfile.role == this.role.superadmin || // fix
      this.userProfile.role == this.role.admin ||
      this.userProfile.role == this.role.it
    );
  }
  get isShowReviewMeetingMenu(): boolean {
    return this.userProfile.isCanViewCs
  }
  get isShowTimesheetMenu(): boolean {
    return (
      this.userProfile.role == this.role.superadmin || // fix
      this.userProfile.role == this.role.admin ||
      this.userProfile.role == this.role.it
    );
  }
  get isShowReportMenu(): boolean {
    return this.userProfile.role !== this.role.user;
  }
  get isShowServiceCatalogMenu(): boolean {
    return (
      this.userProfile.role == this.role.admin ||
      this.userProfile.role == this.role.superadmin
    );
  }
  get isShowUserRoleManagementMenu(): boolean {
    return (
      this.userProfile.role == this.role.admin ||
      this.userProfile.role == this.role.superadmin
    );
  }
  clear(condition?){
    if(condition == 'promoteList'){
      sessionStorage.removeItem('pendingForm');
      sessionStorage.removeItem('releaseForm');
      sessionStorage.removeItem('promoteListTab');
    }
    sessionStorage.removeItem('searchForm');
  }

  goToOldWorkFlow(){
    window.open(config.oldWorkFlowPath, "_blank");
  }

  get isShowReportReviewMeeting(): boolean {
    return this.userProfile.hasRoleCS;
  }
}
