import { ThisReceiver } from "@angular/compiler";

export class SearchTimesheetReportCriteria {
    startDate?: string;
    endDate?: string;
    itDeptId?: string;
    componentId?: number;
    employeeId?: string;
    taskId?: number;

    SearchTimesheetReportCriteria(): void {
      this.itDeptId = "";
      this.endDate = "";
      this.startDate = "";
      this.componentId = null;
      this.employeeId = "";
      this.taskId = null;
    }
  }