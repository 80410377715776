import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-row-per-page",
  templateUrl: "./row-per-page.component.html",
  styleUrls: ["./row-per-page.component.scss"],
})
export class RowPerPageComponent implements OnInit {
  @Input() size: number;
  @Output() newSize = new EventEmitter<number>();
  constructor() {}

  ngOnInit() {}

  public updatePage(): void {
    this.newSize.emit(this.size);
  }
}
