<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">Special Roles Report</h5>
      <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
    </div>

    <div #Table>
  
        <div class="d-flex justify-content-end my-3">
            <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
            </app-row-per-page>
        </div>
        <div class="table-responsive" #servicesTable *ngIf="reportDataSource.data">

          <table mat-table matSort class="table table-hover" cdk-table [dataSource]="reportDataSource"
          (matSortChange)=onSortChange($event)>

          <ng-container cdkColumnDef="userName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef mat-sort-header>User Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.employeeName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="department">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Department</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.deptName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="role">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Role</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.roleName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="userStatus">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef mat-sort-header>User Status</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.isActive}}</td>
          </ng-container>


          <tr class="table-header" cdk-header-row *cdkHeaderRowDef="reportColumns"></tr>
          <tr class="table-row" cdk-row *cdkRowDef="let row; columns: reportColumns"></tr> 
          </table>  

          <div class="not-found text-center" *ngIf="reportDataSource.data == null || totalElements === 0" >
            <div class="my-3"> No Special Role found.</div>
          </div>

        </div>
        <div class="bg-black-haze">
          <div class="align-self-center">
            <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
              [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
              (pageChange)="updatePage()">
            </ngb-pagination>
          </div>
        </div>

    </div>
</div>
