<div class="card card-bottom bg-n7">
  <div class="card-header">
    <h6 class="card-title">
      <a (click)="collapseComment.toggle()" [attr.aria-expanded]="!isCollapse" aria-controls="collapseExample">
        <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
        <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
        Comment
      </a>
    </h6>
  </div>
  <div class="card-body" #collapseComment="ngbCollapse" [(ngbCollapse)]="isCollapse">
    <form [formGroup]="form" *ngIf="form">
      <div class="pl-4 row">
        <div class="col-12 col-sm-12">
          <textarea name="comment" id="comment" rows="3" class="form-control form-control-sm"
            formControlName="comment"></textarea>
        </div>
      </div>
      <div *ngIf="form.controls.comment.errors?.required" class="text-danger p-2 pl-4">
        Please specify a cancellation reasons.</div>
    </form>
  </div>
</div>