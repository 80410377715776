<div class="d-flex flex-column justify-content-center h-100">
    <!-- <img class="align-self-center" src="/assets/images/SETLogo.png" width="100" height="100"> -->
    <img class="align-self-center" src="/assets/images/logo_set_50.png" width="100" height="100">
    <br>
    <div class="text-center">
      <h1>Access Denied</h1>
    </div>
    <div class="text-center">
      You do not have permission to view this page.
    </div>
    <div class="text-center">
      Please check your credentials and try it again.
    </div>
    <div class="text-center">
      <button class="btn btn-primary" [routerLink]="['/login']" >Go to Login</button>
    </div>
  </div>
  
