import { Injectable } from '@angular/core';
import { NotificationControllerService, TaskWorklistPersonResponse } from '@set-it-workflow/set-it-workflow-ts-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  tasks = new BehaviorSubject<TaskWorklistPersonResponse[]>([]);
  totalNewTasks = new BehaviorSubject<number>(null);

  constructor(private notificationService: NotificationControllerService) { }

  startSubscribe() {
    this.getNotificationTasks();
  }

  private getNotificationTasks(): void {
    this.notificationService.getNotificationTasksUsingGET().subscribe(res => {
      this.tasks.next(res.data.taskWorklistPerson);
      this.totalNewTasks.next(res.data.totalNewTasks);
    });
  }

  markAsViewedTask(taskId: number): void {
    this.notificationService.markIsViewedTaskUsingPUT(taskId).subscribe(() => {
      this.getNotificationTasks();
    });
  }
}
