<div class="mt-3 mx-3">
  <div class="my-3">
    <h5>Create Task</h5>
  </div>
  <div class="card card-top bg-n7" #taskCategory>
    <div class="card-header">
      <h6 class="card-title">
        <a (click)="collapseTaskCategory.toggle()" [attr.aria-expanded]="!isShowTaskCategory"
          aria-controls="collapseExample">
          <fa-icon *ngIf="isShowTaskCategory" [icon]="faAngleDown" class="mr-1"></fa-icon>
          <fa-icon *ngIf="!isShowTaskCategory" [icon]="faAngleUp" class="mr-1"></fa-icon>
          Task Category
        </a>
      </h6>
    </div>
    <div class="card-body" id="taskCategory" #collapseTaskCategory="ngbCollapse" [(ngbCollapse)]="isShowTaskCategory">
      <form [formGroup]="form">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-sm-2">
            <span class="float-sm-right text-right">Task Category <span class="text-danger">*</span></span>
          </div>
          <div class="col-12 col-sm-4">
            <ng-select name="taskCategerySelector" id="taskCategerySelector" formControlName="taskCategory">
              <ng-option disabled [value]="">--Category Type--</ng-option>
              <ng-option *ngFor="let item of taskCategoryList" [value]=item.taskCategoryId>({{item.taskCategorySymbol}})
                {{item.taskCategoryName}}</ng-option>
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="form.value.taskCategory">
    <div #requestDetail>
      <app-request-detail-box [fieldsData]=fieldsData [form]=form [isReadonly]=true (formChange)="form = $event" [userProfile]=userProfile
        [isCollapse]=isShowRequestDetail [taskCategoryId]=taskCategoryId [listAllEmployee]=userList (isIssueChangId)="isIssueChangId = $event" (isIssueIncidentChangeId)="isIssueIncidentId = $event" [isCheckSSD]="true"></app-request-detail-box>
    </div>
    <div #attachmentDetail>
      <app-attachment-box [fileList]="fileList" (fileListChange)="fileList = $event" [isEditMode]=true
        [isCollapse]=isShowAttachFile></app-attachment-box>
    </div>
    <div #involvedPerson>
      <app-involved-person-box *ngIf="sectionData.involvedPerson" [fieldsData]=fieldsData
        [isCollapse]=isShowInvolvedPerson [form]=form [isReadonly]=false [isEditMode]=false (formChange)="form = $event"
        [userProfile]=userProfile [taskCategoryId]=taskCategoryId>
      </app-involved-person-box>
    </div>
    <div #approvalDetails>
      <app-approve-detail-box *ngIf="sectionData.approvalDetails" [fieldsData]=fieldsData
        [isCollapse]=isShowApprovalDetails [form]="form" [isReadonly]=false [isEditMode]=false
        (formChange)="form = $event" [userProfile]=userProfile>
      </app-approve-detail-box>
    </div>
    <div #comment>
      <app-comment-box [isCollapse]=isShowComment [form]=form (formChange)="form = $event"></app-comment-box>
    </div>
    <div class="card bg-black-haze my-3" #nextAction>
      <div class="card-body">
        <div class="float-right">
          <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Back</button>
          <button type="button" class="btn btn-success mr-2" (click)="submit('D')">Save Draft</button>
          <button type="button" class="btn btn-primary" (click)="submit('S')">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>