<div class="upload-file">
  <div *ngIf="isEditable" class="d-flex" [class.drag-over] = "fileDraggedOverDiv" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDropSuccess($event)">
    <button class="btn btn-primary col-auto" (click)="attachedFile.value=''; attachedFile.click();"
            title="Add new attachment">
      <span>Choose File or Drag it here</span>
      <input type="file" #attachedFile name="attachedFile" id="attachedFile"
             (change)="addFile(attachedFile.files[0])" class="d-none"
             [accept]=fileType>
    </button>
    <!-- <div class="d-flex"><span class="align-self-center ml-3">Or drag it here</span></div> -->
    
    <div class="d-flex flex-column ml-3">
      <span class="text-muted text-small">Please attach file with pdf, xls, doc, ppt, jpg, png, eml,
        csv, txt, sql, json, log, html, msg, md, zip. Maximum upload size: 20MB/file</span>
        <span class="text-muted text-small"><em>Please be aware of an attachment may contain confidential information.</em></span>
    </div>
  </div>
  <div class="mt-3 d-flex flex-wrap">
    <span class="py-1 px-2 bg-b5 mx-1 mb-1 d-flex justify-content-center" *ngFor="let item of fileList">
      <fa-icon [icon]="faPaperclip" class="mr-1"></fa-icon>
      <a (click)="openFile(item)">
        <span class="d-inline-block">
          {{item?.fileStoreName}}
          {{item?.fileName}}
        </span>
      </a>
      <a (click)="deleteFile(item)">
        <fa-icon [icon]="faTimes" class="mr-1  ml-2"></fa-icon>
      </a>
    </span>
  </div>

</div>
