import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Configuration, AuthenticationControllerService, EmployeeControllerService } from '@set-it-workflow/set-it-workflow-ts-angular';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ACCESS_TOKEN = 'set-it-workflow-access';
  private readonly REFRESH_TOKEN = 'set-it-workflow-refresh';
  refreshTokenInProgress = false;
  accessTokenSubject = new BehaviorSubject<string>(null);
  refreshTokenSubject = new BehaviorSubject<string>(null);
  userProfile = new BehaviorSubject<any>(null);

  constructor(
    private apiConfig: Configuration,
    private authenServiceController: AuthenticationControllerService,
  ) {

  }
  get token(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }
  get refreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }
  updateToken(token: string, refreshToken: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
    localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    this.accessTokenSubject.next(token);
    this.refreshTokenSubject.next(refreshToken);

    this.updateSwaggerToken();
  }
  updateSwaggerToken(): void {
    this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.token;
  }


  get isAuthenticated(): Observable<boolean> | boolean {
    if (this.token === null || this.token === undefined) {
      return false;
    } else if (this.doesTokenExpired()) {
      if (this.doesRefreshTokenExpired()) {
        return false;
      } else {
        return this.refresh();
      }
    } else {
      return true;
    }
  }
  doesTokenExpired(): boolean {
    return this.token == null
      ? true
      : (jwt_decode(this.token) as any).exp < Date.now() / 1000;
  }

  doesRefreshTokenExpired(): boolean {
    return this.refreshToken == null
      ? true
      : (jwt_decode(this.refreshToken) as any).exp < Date.now() / 1000;
  }

  getName(): string {
    return this.token == null ? null : (jwt_decode(this.token) as any).name;
  }
  getEmployeeId():string{
    return this.token == null ? null : (jwt_decode(this.token) as any).employee_id;
  }


  // refresh(): Observable<boolean> {
  //   let serviceToUse: Observable<KeycloakToken>;
  //   serviceToUse = this.authenServiceController.refreshTokenUsingPOST({
  //     refreshToken: this.refreshToken
  //   })
  //   return serviceToUse.pipe(
  //     map((x: any) => {
  //       const accessToken: string = x.data.accessToken;
  //       const refreshToken: string = x.data.refreshToken;
  //       this.updateToken(accessToken, refreshToken);
  //       this.refreshTokenInProgress = false;
  //       return true;
  //     }),
  //     catchError((err) => {
  //       return of(false);
  //     })
  //   );

  // }
  refresh(): Observable<boolean> {
    console.log('refresh token');

    this.refreshTokenSubject.next(null);
    this.accessTokenSubject.next(null);
    this.refreshTokenInProgress = true;

    let serviceToUse: Observable<any>;
    serviceToUse = this.authenServiceController.refreshTokenUsingPOST({
      refreshToken: this.refreshToken
    });


    return serviceToUse.pipe(
      map((x: any) => {
        const accessToken: string = x.access_token;
        const refreshToken: string = x.refresh_token;

        this.updateToken(accessToken, refreshToken);

        this.refreshTokenInProgress = false;
        return true;
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }






}
