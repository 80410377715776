import { Injectable } from '@angular/core';
import { Configuration } from '@set-it-workflow/set-it-workflow-ts-angular';
import { AuthService } from '../service/auth.service';

@Injectable()
export class AuthProvider {
  constructor(
    private apiConfig: Configuration,
    private authService: AuthService
  ) {}

  async onAppLoaded(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.apiConfig.apiKeys) {
        this.apiConfig.apiKeys = {};
      }
      this.authService.updateSwaggerToken();
      resolve(null);
    });
  }
}
