<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">New Timesheet Report</h5>
      <span *ngIf="isSearch" class="ml-auto">
        <img style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
        alt="icon-excel" title="export excel" (click)="export()">
      </span>
      <span *ngIf="!isSearch" class="ml-auto">
        <img style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
        alt="icon-excel" title="export excel" style="filter:grayscale(100%);">
      </span>
    </div>
    <div class="card bg-n7 mb-3" #SearchFilter>
      <div class="card-body">
        <h6 class="p-0 m-0;">
          <a (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapse" aria-controls="collapseExample">
            <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
            <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
            Search Filters
          </a>
        </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapse">
        <div class="row mb-1">
          <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
            <span class="font-weight-bold">IT Department</span>
            <form [formGroup]="form">
              <ng-select name="itDepartment" id="itDepartment" formControlName="deptId" (change)="onSelectDepartment()" 
              [ngClass]="{ 'is-invalid': deptId?.touched && deptId.errors?.required}" [(ngModel)]="defaultItDepartmentId">
                <ng-option disabled [value]="">--Select IT Department--</ng-option>
                <ng-option *ngFor="let itDept of itDepartments" [value]=itDept.deptID> {{itDept.deptName}}</ng-option>
              </ng-select>
            </form>
          </div>

          <div class="col-12 col-sm-6 col-lg-4" #service>
            <span class="font-weight-bold">Service Name</span>
            <form [formGroup]="form">
              <p-multiSelect 
                [options]="serviceByDeptId" 
                formControlName="serviceIds"
                [disabled]="!deptId.value"
                [style]="{'width':'100%','min-width':'100%'}"
                defaultLabel="Select Service" 
                optionLabel="serviceName" optionValue="componentId"
                display="chip"
                (onChange)="onSelectServices()">
              </p-multiSelect>
            </form>
          </div>

          <div class="col-12 col-sm-6 col-lg-4" #taskName>
            <span class="font-weight-bold">Task Name</span>
            <form [formGroup]="form">
              <input type="text" class="form-control" formControlName="taskName">
            </form>
          </div>

        </div>

        <div class="row mb-1">
          <div class="col-12 col-sm-6 col-lg-4" #CreatedDate>
            <span class="font-weight-bold">Created Date</span>
            <form class="form-inline">
              <div class="form-group hidden">
                <div class="input-group w-75">
                  <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
                <div class="input-group">
                  <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 180px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                    disabled>
                  <span class="btn date-range-symbol">-</span>
                  <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 180px;" placeholder="dd/mm/yyyy" name="dpToDate"
                    [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                    disabled>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                      <fa-icon [icon]="faCalendarAlt"></fa-icon>
                    </button>
                  </div>
                </div>
            </form>
          </div>

          <div class="col-12 col-sm-6 col-lg-4" #projectSize>
            <span class="font-weight-bold">Project Size</span>
            <form [formGroup]="form">
              <p-multiSelect 
                [options]="listProjectSize" 
                formControlName="projectSize"
                [style]="{'width':'100%','min-width':'100%'}"
                defaultLabel="Select Project Size" 
                display="chip"
                (onChange)="onSelectProjectSize()"
                [(ngModel)]="listDefaultProjectSize">
              </p-multiSelect>
            </form>
          </div>

          <div class="col-12 col-sm-3 col-lg-2" #isTaskDone>
            <span class="font-weight-bold">Completed Task Only</span>
            <form [formGroup]="form">
              <div class="custom-control custom-switch custom-switch-lg">
                <input type="checkbox" class="custom-control-input" id="custom-switch-isTaskDone" formControlName="isTaskDone">
                <label class="custom-control-label" for="custom-switch-isTaskDone"></label>
              </div>
            </form>
          </div>

          <div class="col-12 col-sm-3 col-lg-2" #isConvertToJira>
            <span class="font-weight-bold">Jira Only</span>
            <form [formGroup]="form">
              <div class="custom-control custom-switch custom-switch-lg">
                <input type="checkbox" class="custom-control-input" id="custom-switch-isConvertToJira" formControlName="isConvertToJira">
                <label class="custom-control-label" for="custom-switch-isConvertToJira"></label>
              </div>
            </form>
          </div>

        </div>

        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
      </div>  
    </div>

    <div  #Table>
      <div class="d-flex justify-content-end my-3">
        <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
        </app-row-per-page>
      </div>
      <div class="table-responsive" #servicesTable>
    
        <table mat-table matSort class="table table-hover" cdk-table [dataSource]="tasks" (matSortChange)=onSortChange($event)>
    
        <ng-container cdkColumnDef="type">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Type</th>
          <td class="table-cell border text-center" cdk-cell *cdkCellDef="let element">{{element.taskCategory}}</td>
        </ng-container>
    
        <ng-container cdkColumnDef="taskID">
          <th class="table-header-cell border-left" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Task ID</th>
          <td class="table-cell border" cdk-cell *cdkCellDef="let element">{{element.taskId}}</td>
        </ng-container>

        <ng-container cdkColumnDef="mainTaskName">
          <th class="table-header-cell border-left" cdk-header-cell *cdkHeaderCellDef>Main Task Name</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">
            <a [href]="'/task/detail/' + element.taskId + '/' + element.status + '/' + element.taskCategoryId + '?isReadonly=true'" target="_blank">{{element.taskName}}</a>
            <span *ngIf="element.isConvertToJira">
              <img src="assets/images/JIRA-icon.png" style="width: 16px; height: 16px;" class="ml-2">
            </span>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="service">
          <th class="table-header-cell border-left" cdk-header-cell *cdkHeaderCellDef>Service Name</th>
          <td class="table-cell border" cdk-cell *cdkCellDef="let element">{{element.service}}</td>
        </ng-container>

        <ng-container cdkColumnDef="createdDate">
          <th class="table-header-cell border-left" cdk-header-cell *cdkHeaderCellDef>Created Date</th>
          <td class="table-cell border" cdk-cell *cdkCellDef="let element">{{element.createdDate}}</td>
        </ng-container>
    
        <ng-container cdkColumnDef="totalEstimateManDays">
          <th class="table-header-cell header-sort-center border-left" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Estimate</th>
          <td class="table-cell text-center border" cdk-cell *cdkCellDef="let element">{{element.totalEstimateManDays}}</td>
        </ng-container>
    
        <ng-container cdkColumnDef="actualMandays">
          <th class="table-header-cell text-center border-left" cdk-header-cell *cdkHeaderCellDef>Actual</th>
          <td class="table-cell text-center border" cdk-cell *cdkCellDef="let element">{{element.manDaysFromJira}}</td>
        </ng-container>

        <ng-container cdkColumnDef="percentDiff">
          <th class="table-header-cell text-center border-left" cdk-header-cell *cdkHeaderCellDef>%Diff</th>
          <td class="table-cell text-center border" cdk-cell *cdkCellDef="let element">{{element.percentDiff}}</td>
        </ng-container>

        <ng-container cdkColumnDef="progressStatus">
          <th class="table-header-cell text-center border-left" cdk-header-cell *cdkHeaderCellDef>Status</th>
          <td class="table-cell text-center border" cdk-cell *cdkCellDef="let element">{{element.progressStatus}}</td>
        </ng-container>
    
        <tr class="table-header" cdk-header-row *cdkHeaderRowDef="newTimesheetReportColumn"></tr>
        <!-- <tr class="table-header" cdk-header-row *cdkHeaderRowDef="newTimesheetReportColumnSecondRow"></tr> -->
        <tr class="table-row" cdk-row *cdkRowDef="let row; columns: newTimesheetReportColumn" [class.hidden]="tasks.length > 0"></tr> 
        </table>  
    
    
        <div class="not-found text-center" *ngIf="tasks.length === 0" >
          <div class="my-3"> No Task found.</div>
        </div>
    
      </div>
    
      <div class="bg-black-haze">
        <div class="align-self-center">
          <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
            [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
            (pageChange)="updatePage()">
          </ngb-pagination>
        </div>
      </div>
      
    </div>
    
    
</div>
