import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import {
  EmployeeControllerService,
  MyProfileResponse,
  APIResultMyProfileResponse,
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { Subject, BehaviorSubject } from "rxjs";
import { config } from "src/environments/config";

@Injectable({
  providedIn: "root",
})
export class UserProfileService {
  private userProfileSubject = new BehaviorSubject<UserProfile>(
    new UserProfile()
  );
  userProfile$ = this.userProfileSubject.asObservable();
  // userProfile: MyProfileResponse
  // userProfileChange: Subject<MyProfileResponse> = new Subject<MyProfileResponse>();
  constructor(private employeeService: EmployeeControllerService) { }
  setUserProfile() {
    this.employeeService
      .getMyProfileUsingGET()
      .subscribe((userProfile: APIResultMyProfileResponse) => {
        let userProfileResult: UserProfile = new UserProfile(userProfile.data);
        this.userProfileSubject.next(userProfileResult);
      });
  }
}
export class UserProfile implements MyProfileResponse {
  cluster?: string;
  deptId?: string;
  deptName?: string;
  email?: string;
  empID?: string;
  grade?: string;
  isActive?: boolean;
  managerId?: string;
  nameEn?: string;
  role?: string;
  roleEnum?: MyProfileResponse.RoleEnumEnum;
  imageUrl?: string;
  isCanViewCs?: boolean;
  hasRoleCS?: boolean;
  hashCode?: string;

  constructor(profile?: MyProfileResponse) {
    this.cluster = profile?.cluster ?? "";
    this.deptId =  profile?.deptId ?? "";
    this.deptName = profile?.deptName ?? "";
    this.email = profile?.email ?? "";
    this.empID = profile?.empID ?? "";
    this.grade = profile?.grade ?? "";
    this.isActive =  profile?.isActive ?? true;
    this.managerId = profile?.managerId ?? "";
    this.nameEn = profile?.nameEn ?? "";
    this.role = profile?.role ?? "";
    this.isCanViewCs = profile?.isCanViewCs ?? false;
    this.hasRoleCS = profile?.hasRoleCS ?? false;
    this.hashCode = profile?.hashCode ?? "";
    this.imageUrl = profile?.hashCode ? UserProfile.setImageProfile(profile.hashCode) : null;
  }

  public static setImageProfile(hashCode): string {
    return hashCode ? config.imageUrlPath + '/' + hashCode + '.jpg': null;
  }
}
