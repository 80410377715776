import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import {
  faAngleDown,
  faAngleUp,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ComponentResponse,
  DepartmentControllerService,
  DepartmentResponse,
  EmployeeControllerService,
  EmployeeResponse,
  EnumResponse,
  JIRATransitionFilterResponse,
  JIRATransitionResponse
} from '@set-it-workflow/set-it-workflow-ts-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserProfile } from 'src/app/service/user-profile.service';
import { EmployeeRoleEnum } from '../../constant/employee-role-enum';
import { TaskCategoryEnum } from '../../constant/task-category-enum';
import { SearchUserModalComponent } from '../search-user-modal/search-user-modal.component';
import { convertStringToWorkflowStatus } from '../../function/state.function';
import { roleUser } from 'src/app/constants/role.constant';
import { convertJiraTransitionToString } from '../../function/transition.funtion';

@Component({
  selector: 'app-involved-person-box',
  templateUrl: './involved-person-box.component.html',
  styleUrls: ['./involved-person-box.component.scss']
})
export class InvolvedPersonBoxComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() fieldsData: any;
  @Input() taskCategoryId: number;
  @Input() isReadonly: boolean;
  @Input() isEditMode: boolean;
  @Input() isCollapse: boolean;
  @Input() isPromoteFormER: boolean;
  @Output() isCollapseChange = new EventEmitter<boolean>();
  @Input() userProfile: UserProfile;
  @Input() isShowOnPromoteForm: false;
  @Input() isPageCreatePromoteRelease: false;
  @Input() isShowDeveloper: false;
  @Input() isShowDeveloperHead: false;
  @Input() allServiceList: ComponentResponse[] = [];
  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() allEmployeeChange = new EventEmitter<EmployeeResponse[]>();
  @Input() status: string;
  @Input() nextActionData: JIRATransitionFilterResponse;
  @Output() nextActionChange = new EventEmitter<JIRATransitionResponse[]>();

  private subscription: Subscription;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faSearch = faSearch;
  public userList: EmployeeResponse[] = [];
  public bizUnitHeadList: EmployeeResponse[] = [];
  public bizDeptHeadList: EmployeeResponse[] = [];
  public itHeadList: EmployeeResponse[] = [];
  public solaList: EmployeeResponse[] = [];
  public uatList: EmployeeResponse[] = [];
  public qcHeadList: EmployeeResponse[] = [];
  public uatHeadList: EmployeeResponse[] = [];
  public pqaList: EmployeeResponse[] = [];
  public itList: EmployeeResponse[] = [];
  public allEmployeeList: BehaviorSubject<EmployeeResponse[]> = new BehaviorSubject(new Array<EmployeeResponse>());
  count = 0;
  
  public itDeptList: DepartmentResponse[] = [];
  serviceRequestTypeForGetDataProd: number = 4;
  isShowMainBusinessUser: boolean = true;
  isShowBusinessUnitHead: boolean = true;
  isShowBusinessDeptHead: boolean = true;
  public jiraTransitionEnum = EnumResponse.JiraTransitionEnum;

  private userModal: NgbModalRef;

  roleEnum = EmployeeRoleEnum;
  selectedId: string = null;

  constructor(
    private employeeService: EmployeeControllerService,
    private departmentService: DepartmentControllerService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.subscription = new Subscription();
    this.taskCategoryId = Number(this.taskCategoryId);
    this.getMasterData();

    this.onFormControlsChange();
    this.onItDepartmentOrReleaseServiceChange();
    this.onUatChange();
    this.onRequireUatChange();
    this.setDefaultInvolvedPerson();
    this.setDefaultShowBusinessHead();
    this.onServiceRequestTypeChange();
    this.onDataOwnerChange();
    this.subscription.add(this.allEmployeeList.subscribe(value =>{
      if(value && value.length>0){
        this.checkPerson();
      }
    }))
  }

  private setDefaultShowBusinessHead() {
    if (this.form.controls.serviceRequestType?.value === this.serviceRequestTypeForGetDataProd) {
      this.isShowBusinessUnitHead = false;
      if (this.form.controls.dataOwner?.value) {
        if (this.itDeptList.some(it => it.deptID === this.form.controls.dataOwner.value)) {
          this.setRoleItShowBuForIT();
        } else {
          this.setShowBuForUser();
        }
      } 
    }
  }

  private onServiceRequestTypeChange() {
    if (this.form && this.form.controls.serviceRequestType) {
      this.form.controls.serviceRequestType.valueChanges.subscribe((x) => {
        if (x === this.serviceRequestTypeForGetDataProd) {
          this.isShowBusinessUnitHead = false;
        } else {
          this.isShowBusinessUnitHead = true;
        }
    });
    }
  }

  private onDataOwnerChange() {
    if (this.form && this.form.controls.dataOwner) {
      this.form.controls.dataOwner.valueChanges.subscribe((deptId) => {
        if (deptId) {
          this.employeeService.getBusinessHeadsByDeptUsingGET(deptId, 'department').subscribe((result) => {
            this.bizDeptHeadList = result.data;
            this.form.controls.businessDeptHead.setValue(result.data[0]?.empID);
            this.form.controls.businessDeptHead.updateValueAndValidity();
          });
  
          if (this.itDeptList.some(it => it.deptID === deptId)) {
            this.setRoleItShowBuForIT();
          } else {
            this.setShowBuForUser();
          }
  
        } else {
          this.employeeService.getBusinessHeadsUsingGET(this.userProfile?.empID, 'department').subscribe((result) => {
            this.bizDeptHeadList = result.data;
            this.form.controls.businessDeptHead.setValue(result.data[0]?.empID);
            this.form.controls.businessDeptHead.updateValueAndValidity();
          });
  
          this.isShowMainBusinessUser = true;
          this.isShowBusinessDeptHead = true;
        } 
  
        this.setNextActionForMainBuCreateSR();
      });
    }
  }

  private setNextActionForMainBuCreateSR() {
    if (this.nextActionData && this.nextActionData.fullTransitions) {
      const raiseToBuUnitHead = this.convertJiraTransitionToString(this.jiraTransitionEnum.RAISETOBUSINESSUNITHEAD);
      const raiseToBuDeptHead = this.convertJiraTransitionToString(this.jiraTransitionEnum.RAISETOBUSINESSDEPTHEAD);  
      const raiseToItHead = this.convertJiraTransitionToString(this.jiraTransitionEnum.RAISETOITHEAD);
  
  
      if (this.form.controls.serviceRequestType?.value === this.serviceRequestTypeForGetDataProd) {
        if (this.userProfile.role === roleUser.user) {
          const nextAction = this.nextActionData.fullTransitions.filter((f) => f.name !== raiseToBuUnitHead && f.name !== raiseToItHead);
          this.nextActionChange.emit(nextAction);
        } else {
          if (this.form.controls.dataOwner?.value && this.itDeptList.some(it => it.deptID === this.form.controls.dataOwner.value)) {
            const nextAction = this.nextActionData.fullTransitions.filter((f) => f.name !== raiseToBuUnitHead && f.name !== raiseToBuDeptHead);
            this.nextActionChange.emit(nextAction);
          } else {
            const nextAction = this.nextActionData.fullTransitions.filter((f) => f.name !== raiseToBuUnitHead && f.name !== raiseToItHead);
            this.nextActionChange.emit(nextAction);
          }
        }
      } else {
        const nextAction = this.nextActionData.fullTransitions.filter((f) => f.name !== raiseToBuDeptHead && f.name !== raiseToItHead);
        this.nextActionChange.emit(nextAction);
      }
    }
  }
  private convertJiraTransitionToString(
    transition: EnumResponse.JiraTransitionEnum
  ): string {
    return convertJiraTransitionToString(transition);
  }

  private setRoleItShowBuForIT() {
    this.isShowMainBusinessUser = false;
    this.isShowBusinessUnitHead = false;
    this.isShowBusinessDeptHead = false;
  }

  private setShowBuForUser() {
    this.isShowMainBusinessUser = true;
    this.isShowBusinessUnitHead = false;
    this.isShowBusinessDeptHead = true;
  }

  private setDefaultInvolvedPerson() {
    if (this.taskCategoryId === TaskCategoryEnum.IR.id) {
      this.resetBuUnitHead();
      if (this.form.controls.requiredUAT.value === true) {
        this.setRequireUat();
        this.setRequireBuDeptHead();
        this.setDefualtUat();
        this.setDefaultBuDeptHead();
      } else if (this.form.controls.requiredUAT.value === false) {
        this.resetRequireUat();
        this.resetRequireBuDeptHead();
      }
    } else if (this.taskCategoryId === TaskCategoryEnum.ER.id) {
      this.setDefaultItHead();
      this.resetRequireUat();
      this.resetRequireBuDeptHead();
    } else if (!this.isPromoteFormER) {
      this.setDefaultBuUnitHead();
      this.setDefaultBuDeptHead();
      this.setDefaultMainBu();
      this.setDefualtUat();
    }
  }

  private setDefaultBuUnitHead() {
    this.bizUnitHeadList = [...this.bizUnitHeadList];
    if (this.fieldsData.businessUnitHead && !this.form.controls.businessUnitHead?.value) {
      this.form.controls.businessUnitHead.setValue(this.bizUnitHeadList[0]?.empID);
      this.form.controls.businessUnitHead.updateValueAndValidity();

    } else {
      const buUnitId = this.form.controls.businessUnitHead?.value;
      if (buUnitId && this.bizUnitHeadList && !this.bizUnitHeadList.find((f) => f.empID === buUnitId)) {        
        this.form.controls.businessUnitHead.setValue(buUnitId);
        this.form.controls.businessUnitHead.updateValueAndValidity();
      }
    }
  }

  private setDefaultMainBu() {
    if (!this.form.controls.mainBusinessUser?.value) {
      this.form.controls.mainBusinessUser.setValue(this.userProfile.empID);
      this.form.controls.mainBusinessUser.updateValueAndValidity();
    }
  }

  private setDefualtUat() {
    if (this.form.controls.uat && !this.form.controls.uat.value) {
      this.form.controls.uat.setValue(this.userProfile.empID);
      this.form.controls.uat.updateValueAndValidity();

    } else if (this.form.controls.uatHead && !this.form.controls.uatHead.value) {
      this.form.controls.uatHead.setValue(this.userProfile.empID);
      this.form.controls.uatHead.updateValueAndValidity();
    }
  }

  private setDefaultItHead() {
    if(this.taskCategoryId === TaskCategoryEnum.QR.id && this.checkNotDefaultItHead()){
      this.form.controls.itHead.setValue(null);
      this.form.controls.itHead.updateValueAndValidity();
      return;
    }
    if (this.form.controls.itHead && !this.form.controls.itHead.value) {
      this.form.controls.itHead.setValue(this.itHeadList[0]?.empID);
      this.form.controls.itHead.updateValueAndValidity();

    } else {
      const itHeadId = this.form.controls.itHead?.value;
      if (itHeadId && this.itHeadList && !this.itHeadList.find((f) => f.empID === itHeadId)) {
        this.form.controls.itHead.setValue(itHeadId);
        this.form.controls.itHead.updateValueAndValidity();
      }
    }
  }
  private checkNotDefaultItHead() {
    if(this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.DRAFT ||
      this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.BUUNITHEADREVIEW ||
    this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.MAINBUCREATEQR ||
    this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.BUDEPTHEADREVIEW ||
    this.convertStringToWorkflowStatus(this.status) === EnumResponse.WorkflowStatusEnum.WAITFORCSREVIEW
    ){
      return true;
    }else return false;
  }
  public convertStringToWorkflowStatus(
    state: string
  ): EnumResponse.WorkflowStatusEnum {
    return convertStringToWorkflowStatus(state);
  }

  private setDefaultBuDeptHead() {
    this.bizDeptHeadList = [...this.bizDeptHeadList];
    if (this.form.controls.businessDeptHead && !this.form.controls.businessDeptHead.value) {
      this.form.controls.businessDeptHead.setValue(this.bizDeptHeadList[0]?.empID);
      this.form.controls.businessDeptHead.updateValueAndValidity();

    } else {
      const buUnitId = this.form.controls.businessDeptHead?.value;
      if (buUnitId && this.bizDeptHeadList && !this.bizDeptHeadList.find((f) => f.empID === buUnitId)) {
        this.form.controls.businessDeptHead.setValue(buUnitId);
        this.form.controls.businessDeptHead.updateValueAndValidity();
      }
    }
  }

  private onFormControlsChange() {
    this.form.valueChanges.subscribe(() => this.formChange.emit(this.form));
  }

  private onItDepartmentOrReleaseServiceChange() {
    if(this.isPageCreatePromoteRelease){
      this.form.controls.componentId.valueChanges.subscribe(() => {
        console.log("PageCreatePromoteRelease Component Change");
        this.form.controls.itHead.setValue(null);
        this.getItHeads();
      })
    }else if (this.form.controls.itDepartment) {
      this.form.controls.itDepartment.valueChanges.subscribe(() => {
        this.getItHeads();
      });
    }
  }

  private onRequireUatChange() {
    if (this.form.controls.requiredUAT) {
      this.form.controls.requiredUAT.valueChanges.subscribe((req) => {
        if (this.taskCategoryId === TaskCategoryEnum.IR.id) {
          this.resetBuUnitHead();
          if (req === true) {
            this.setRequireUat();
            this.setRequireBuDeptHead();
            this.setDefualtUat();
          } else if (req === false) {
            this.resetRequireUat();
            this.resetRequireBuDeptHead();
          }
        }
      });
    }
  }

  private resetBuUnitHead() {
    this.fieldsData.businessUnitHead = null;
    this.form.controls.businessUnitHead.reset();
    this.form.controls.businessUnitHead.clearValidators();
    this.form.controls.businessUnitHead.updateValueAndValidity();
  }

  private onUatChange() {
    if (this.form.controls.uat) {
      this.form.controls.uat.valueChanges.subscribe(value => {
        if (value && TaskCategoryEnum.IR.id === this.taskCategoryId) {
          this.form.controls.businessDeptHead.reset();
          this.form.controls.businessDeptHead.updateValueAndValidity();
          this.getBuDeptHeads(value);
        }
        else {
          const mainBuUserId = this.form.controls.mainBusinessUser?.value ?
            this.form.controls.mainBusinessUser.value : this.userProfile.empID;
          this.employeeService.getBusinessHeadsUsingGET(mainBuUserId, 'department').subscribe((result) => {
            this.bizDeptHeadList = result.data;
            this.setDefaultBuDeptHead();
          });
        }
      });
    }
  }

  private getBuDeptHeads(empId: string) {
    this.employeeService.getBusinessDeptHeadsByEmpIdUsingGET(empId).subscribe((res) => {
      this.bizDeptHeadList = res.data;
      this.setDefaultBuDeptHead();
    });
  }

  private getMasterData(): void {
    this.employeeService.getEmployeeUsingGET(true).subscribe((emp) => {
      this.allEmployeeList.next(emp.data);
      this.allEmployeeChange.emit(emp.data)

      if (!this.isPromoteFormER) {
        const mainBuUserId = this.form.controls.mainBusinessUser?.value ?
          this.form.controls.mainBusinessUser.value : this.userProfile.empID;
        

        this.departmentService.getITDepartmentUsingGET().subscribe(
          (result) => {
            this.itDeptList = result.data;
            this.getBusinessDeptHead(mainBuUserId, result.data);
          },
          (error) => {
            this.getBusinessDeptHead(mainBuUserId, []);
          }
        );
        
        this.employeeService.getBusinessHeadsUsingGET(mainBuUserId, 'unit').subscribe((result) => {
          this.bizUnitHeadList = result.data;
        });
       
        this.employeeService.getSOLAsUsingGET().subscribe((sola) => {
          this.solaList = sola.data;
        });

        this.employeeService.getAllEmployeeUsingGET().subscribe((users) => {
          this.userList = users.data;
          this.uatList = users.data;
          this.uatHeadList = users.data;
          this.pqaList = users.data;
        });
  
        this.employeeService.getItDevsUsingGET().subscribe((result) => {
          this.itList = result.data;
        });

        this.getItHeads();
        
      } else {
        this.employeeService.getAllEmployeeUsingGET().subscribe((users) => {
          this.pqaList = users.data;
        });
      }
  
      this.getQC();
      
    });
  }


  private getQC(): void {
    this.employeeService.getItDevsUsingGET().subscribe((it) => {
      this.qcHeadList = it.data;
    });
  }

  private getBusinessDeptHead(mainBuUserId: string, itDeptList: DepartmentResponse[]): void {
    if (this.form.controls.serviceRequestType?.value
      && this.form.controls.serviceRequestType.value === this.serviceRequestTypeForGetDataProd
      && this.form.controls.dataOwner?.value) {
        this.employeeService.getBusinessHeadsByDeptUsingGET(this.form.controls.dataOwner.value, 'department').subscribe((result) => {
          this.bizDeptHeadList = result.data;
        });
        if (itDeptList.some(it => it.deptID === this.form.controls.dataOwner.value)) {
          this.setRoleItShowBuForIT();
        } else {
          this.setShowBuForUser();
        }
  
    } else {
      this.employeeService.getBusinessHeadsUsingGET(mainBuUserId, 'department').subscribe((result) => {
        this.bizDeptHeadList = result.data;
      });
    }
  }

  private getItHeads(): void {
    if(this.isPageCreatePromoteRelease){
      const releaseServiceDepartment = this.allServiceList.find(x => x.componentId == this.form.controls.componentId.value)
      if(releaseServiceDepartment != null || releaseServiceDepartment != undefined){
        this.employeeService.getItHeadsUsingGET(releaseServiceDepartment.departmentId).subscribe((itHead) => {
          this.itHeadList = itHead.data;
          this.setDefaultItHead();
        });
      }
      
    }else if (this.form.controls.itDepartment.value) {
      this.employeeService.getItHeadsUsingGET(this.form.controls.itDepartment.value).subscribe((itHead) => {
        this.itHeadList = itHead.data;
        this.setDefaultItHead();
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setRequireBuDeptHead() {
    this.fieldsData.businessDeptHead = {
      req: true,
      editable: true,
    };
    this.form.controls.businessDeptHead.setValidators(Validators.required);
    this.form.controls.businessDeptHead.updateValueAndValidity();
  }

  private setRequireUat() {
    if (this.form.controls.uat) {
      this.fieldsData.uat = {
        req: true,
        editable: true,
      };
      this.form.controls.uat.setValidators(Validators.required);
      this.form.controls.uat.updateValueAndValidity();

    } else if (this.form.controls.uatHead) {
      this.fieldsData.uatHead = {
        req: true,
        editable: true,
      };
      this.form.controls.uatHead.setValidators(Validators.required);
      this.form.controls.uatHead.updateValueAndValidity();
    }
  }

  private resetRequireBuDeptHead() {
    if (this.form.controls.businessDeptHead) {
      this.fieldsData.businessDeptHead = null;
      this.form.controls.businessDeptHead.reset();
      this.form.controls.businessDeptHead.clearValidators();
      this.form.controls.businessDeptHead.updateValueAndValidity();
    }
  }

  private resetRequireUat() {
    if (this.fieldsData.uatHead) {
      this.fieldsData.uatHead = null;
      this.form.controls.uatHead.reset();
      this.form.controls.uatHead.clearValidators();
      this.form.controls.uatHead.updateValueAndValidity();
    } else if (this.fieldsData.uat) {
      this.fieldsData.uat = null;
      this.form.controls.uat.reset();
      this.form.controls.uat.clearValidators();
      this.form.controls.uat.updateValueAndValidity();
    }
  }

  openSearchUserModal(role: string, task?: FormGroup): void {
    this.userModal = this.modalService.open(SearchUserModalComponent, { size: 'xl' });
    this.userModal.componentInstance.defaultDeptId =
      role === this.roleEnum.SOLA.name ||
        role === this.roleEnum.IT_HEAD.name ||
        role === this.roleEnum.QC_HEAD.name ||
        role === this.roleEnum.DEV.name ? this.form.controls.itDepartment.value : this.userProfile.deptId;

    this.userModal.componentInstance.role = role;
    this.userModal.result.then((result) => {
      if (result && result.selected) {
        switch (result.role) {
          case this.roleEnum.BU_UNIT_HEAD.name:
            if (!this.bizUnitHeadList.find((f) => f.empID === result.selected.empID)) {
              this.bizUnitHeadList.push(result.selected);
              this.bizUnitHeadList = [...this.bizUnitHeadList];
            }
            this.form.controls.businessUnitHead.setValue(result.selected.empID);
            break;
          case this.roleEnum.BU_DEPT_HEAD.name:
            if (!this.bizDeptHeadList.find((f) => f.empID === result.selected.empID)) {
              this.bizDeptHeadList.push(result.selected);
              this.bizDeptHeadList = [...this.bizDeptHeadList];
            }
            this.form.controls.businessDeptHead.setValue(result.selected.empID);
            break;
          case this.roleEnum.IT_HEAD.name:
            if (!this.itHeadList.find((f) => f.empID === result.selected.empID)) {
              this.itHeadList.push(result.selected);
              this.itHeadList = [...this.itHeadList];
            }
            this.form.controls.itHead.setValue(result.selected.empID);
            break;
          case this.roleEnum.SOLA.name:
            if (!this.solaList.find((f) => f.empID === result.selected.empID)) {
              this.solaList.push(result.selected);
              this.solaList = [...this.solaList];
            }
            this.form.controls.dev.setValue(result.selected.empID);
            break;
          case this.roleEnum.QC_HEAD.name:
            if (!this.qcHeadList.find((f) => f.empID === result.selected.empID)) {
              this.qcHeadList.push(result.selected);
              this.qcHeadList = [...this.qcHeadList];
            }
            this.form.controls.qcHead.setValue(result.selected.empID);
            break;
          case this.roleEnum.UAT_HEAD.name:
            if (!this.uatHeadList.find((f) => f.empID === result.selected.empID)) {
              this.uatHeadList.push(result.selected);
              this.uatHeadList = [...this.uatHeadList];
            }
            this.form.controls.uatHead.setValue(result.selected.empID);
            break;
          case this.roleEnum.PQA.name:
            if (!this.pqaList.find((f) => f.empID === result.selected.empID)) {
              this.pqaList.push(result.selected);
              this.pqaList = [...this.pqaList];
            }
            this.form.controls.pqa.setValue(result.selected.empID);
            break;
          case this.roleEnum.DEV.name:
            if (!this.itList.find((f) => f.empID === result.selected.empID)) {
              this.itList.push(result.selected);
              this.itList = [...this.itList];
            }
            task.controls.developerId.setValue(result.selected.empID);
            break;
          default:
            break;
        }
        this.form.updateValueAndValidity();
      }
    });
  }

  get developerHeadFormArray() {
    return this.form.controls.developerHeadForUserManage as FormArray;
  }
  get developerFormArray() {
    return this.form.controls.developerForUserManage as FormArray;
  }
  toggleChange() {
    this.isCollapseChange.emit(this.isCollapse);
  }

  checkPerson() {
    if(this.form.controls.itHead?.value){
      this.itHeadList = [...this.setEmployee(this.itHeadList, this.form.controls.itHead.value)];
    }
    
    if(this.form.controls.businessUnitHead?.value){
      this.bizUnitHeadList = [...this.setEmployee(this.bizUnitHeadList, this.form.controls.businessUnitHead.value)];
    }

    if(this.form.controls.businessDeptHead?.value){
      this.bizDeptHeadList = [...this.setEmployee(this.bizDeptHeadList, this.form.controls.businessDeptHead.value)];
    }

    if(this.form.controls.uat?.value){
      this.uatList = [...this.setEmployee(this.uatList, this.form.controls.uat.value)];
    
    } else if (this.form.controls.uatHead?.value) {
      this.uatHeadList = [...this.setEmployee(this.uatHeadList, this.form.controls.uatHead.value)];
    }

    if(this.form.controls.qcHead?.value){
      this.qcHeadList = [...this.setEmployee(this.qcHeadList, this.form.controls.qcHead.value)];
    }

    if(this.form.controls.sola?.value){
      this.solaList = [...this.setEmployee(this.solaList, this.form.controls.sola.value)];
    }
    
    if(this.form.controls.pqa?.value 
      && this.form.controls.mainBusinessUser?.value 
      && this.form.controls.pqa.value === this.form.controls.mainBusinessUser.value){
      this.pqaList = [...this.setEmployee(this.pqaList, this.form.controls.pqa.value)];
    
    } else if (this.form.controls.pqa?.value) {
      this.pqaList = [...this.setEmployee(this.pqaList, this.form.controls.pqa.value)];

    } else if (this.form.controls.mainBusinessUser?.value) {
      this.pqaList = [...this.setEmployee(this.pqaList, this.form.controls.mainBusinessUser.value)];
    }
  }

  private setEmployee(valueList: EmployeeResponse[], employeeId: any): EmployeeResponse[] {
    const isFound = valueList.some(x=> (x.empID === employeeId && x.nameEn))
    if(!isFound){
      const item = this.allEmployeeList.value.find(x=>x.empID === employeeId);
      valueList.push(item);
    }
    return valueList;
  }
}
