import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faAngleDown, faAngleUp, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  PromoteReleaseControllerService
} from '@set-it-workflow/set-it-workflow-ts-angular';
import { ReleaseSubPromoteResponse } from '@set-it-workflow/set-it-workflow-ts-angular/model/releaseSubPromoteResponse';
import { config } from '../../../../../environments/config';
import { AuthService } from '../../../../service/auth.service';
import { PromoteFileReportService } from '../services/promote-file.service';

@Component({
  selector: 'app-promote-form-by-promote-id',
  templateUrl: './promote-form-by-promote-id.component.html',
  styleUrls: ['./promote-form-by-promote-id.component.scss']
})
export class PromoteFormByPromoteIdComponent implements OnInit {

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public isSearch = true;
  public form = new FormGroup({
      promoteID: new FormControl(null, Validators.required)
    });
  data: ReleaseSubPromoteResponse[] = [];
  constructor(private promoteReleaseService: PromoteReleaseControllerService,
              private authService: AuthService,
              private httpClient: HttpClient,
              private reportService: PromoteFileReportService,
              private activatedRoute: ActivatedRoute
              ) {
  }
  promoteId: number;
  ngOnInit(): void {
    const promoteId = this.activatedRoute.snapshot.queryParams.promoteId;
    if (promoteId) {
      this.form.get('promoteID').setValue(promoteId);
      this.search();
    }
  }

  export() {
    this.promoteId = this.form.get('promoteID').value;
    this.httpClient.post(config.exportPromoteFormByPromoteID + '/' + this.promoteId, null,
      { headers: { Authorization: ('bearer ' + this.authService.token) },
        responseType: 'arraybuffer'})
      .subscribe((response: any) => {
        const filename = 'promote-form-by-promote-id-report.xlsx';
        this.reportService.downLoadFile(response, 'application/octet-stream', filename);
      });
  }

  clear() {
    this.form.reset();
  }

  search() {
    this.promoteId = this.form.get('promoteID').value;
    console.log(this.promoteId);
    this.promoteReleaseService.getSubPromoteByReleaseFormIdUsingGET(this.promoteId).subscribe(res => {
      this.data = res.data;
      console.log(this.data);
    }, error => {
      console.log(error);
    });
  }
}
