import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";

import {
  ReportControllerService, DuplicateFileResponse, APIResultPageResponseListDuplicateFileResponse,
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { BehaviorSubject, Subscription, Observable, combineLatest } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";
import { SearchDuplicateFileCriteria } from 'src/app/model/search-duplicate-file-criteria.model'

@Injectable({
  providedIn: "root",
})

export class DuplicateFileReportService {

  searchReportCriteria = new BehaviorSubject<SearchDuplicateFileCriteria>(new SearchDuplicateFileCriteria());
  reportDataSource = new ReportDataSource();
  totalElements = new BehaviorSubject<number>(0);
  searchPage = new BehaviorSubject<number>(0);
  searchPageSize = new BehaviorSubject<number>(10);
  totalPages = new BehaviorSubject<number>(0);
  private subscription: Subscription;

  constructor(private reportControllerService: ReportControllerService) { }

  public startSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(
      combineLatest([
        this.searchReportCriteria,
        this.searchPage,
        this.searchPageSize,
      ]).pipe(
        debounceTime(200),
        filter((x) => x[0] != null)
      )
        .subscribe(([searchReportCriteria, page, pageSize]) => {
          if (searchReportCriteria.startDate && searchReportCriteria.endDate) {
            this.getReport(
              searchReportCriteria.endDate,
              searchReportCriteria.startDate,
              page,
              pageSize
            ).subscribe(([totalPages, totalElements, elements]: [
              number,
              number,
              DuplicateFileResponse[]
            ]) => {
              this.totalPages.next(totalPages);
              this.totalElements.next(totalElements);
              this.reportDataSource.update(elements);
            }
            );
          }

        })
    );
  }

  public unSubscription(): void {
    this.subscription.unsubscribe();
  }

  public getReport(
    endDate: string,
    startDate: string,
    page: number,
    pageSize: number
  ): Observable<[number, number, DuplicateFileResponse[]]> {
    return this.reportControllerService.findDuplicateFilesUsingGET(endDate, startDate, page, pageSize)
      .pipe(
        map((x: APIResultPageResponseListDuplicateFileResponse) => [
          x.data.totalPages,
          x.data.totalElements,
          x.data.elements])
      );
  }

  public downLoadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    this.saveAs(blob, fileName);
  }

  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export class ReportDataSource extends DataSource<DuplicateFileResponse> {
  data = new BehaviorSubject<DuplicateFileResponse[]>([]);

  connect(): Observable<DuplicateFileResponse[]> {
    return this.data;
  }

  disconnect(): void { }

  update(x: DuplicateFileResponse[]): void {
    this.data.next(x);
  }
}
