import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { CrReviewReportControllerService,
         CrReviewReportResponse,
         APIResultPageResponseListCrReviewReportResponse
        } from "@set-it-workflow/set-it-workflow-ts-angular";
import { BehaviorSubject, Subscription, combineLatest, Observable } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";
import { SearchReportCriteria } from "src/app/model/search-report-criteria.model";

@Injectable({
  providedIn: "root", 
})
export class CrReportService {
  totalCrPages = new BehaviorSubject<number>(0);
  totalCrElements = new BehaviorSubject<number>(0);
  currentPage = new BehaviorSubject<number>(0);
  searchReportCriteria = new BehaviorSubject<SearchReportCriteria>(new SearchReportCriteria());
  searchPage = new BehaviorSubject<number>(0);
  searchPageSize = new BehaviorSubject<number>(10);
  searchSort = new BehaviorSubject<Sort>(null);
  crReportDataSource = new CrReportDataSource();

  private subscription: Subscription;

  constructor(private crReviewReportService: CrReviewReportControllerService) {}

  public startCrSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(
      combineLatest([
        this.searchReportCriteria,
        this.searchPage,
        this.searchPageSize,
        this.searchSort
      ]).pipe(
          debounceTime(200),
          filter((x) => x[0] != null)
        ).subscribe(([searchReportCriteria, page, pageSize, sort]) => {
            this.getCrReport(
              searchReportCriteria.deptId,
              searchReportCriteria.endDate,
              searchReportCriteria.startDate,
              searchReportCriteria.taskCategoryId,
              page,
              pageSize,
              sort
            ).subscribe(
              ([totalPages, totalElements, elements]: [
                number,
                number,
                CrReviewReportResponse[]
              ]) => {
                this.totalCrPages.next(totalPages);
                this.totalCrElements.next(totalElements);
                this.crReportDataSource.update(elements);
              }
            );
        })
    );
  }

  public unSubscription(): void {
    this.subscription.unsubscribe();
  }

  public getCrReport(
    deptId: string,
    endDate: string,
    startDate: string,
    taskCategoryId: number,
    page: number,
    pageSize: number,
    sort: Sort,
  ): Observable<[number, number, CrReviewReportResponse[]]> { 
    return this.crReviewReportService
      .searchCrReviewReportUsingGET(deptId,endDate,page,pageSize,this.sortFieldBy(sort),startDate, taskCategoryId)
      .pipe(
        map((x: APIResultPageResponseListCrReviewReportResponse) => [
          x.data.totalPages,
          x.data.totalElements,
          x.data.elements,
        ])
      );
  }

  public sortFieldBy(sort: Sort): [string]{
    return sort && sort.direction && sort.active ? [this.columnToSortSymbol(sort.active) + ',' + sort.direction] : null;
  }

  public columnToSortSymbol(column: string) {
    switch (column) {
      case "meetingDate":
        return "taskDetail.meetingDate";
      case "buDepartment":
        return "taskDetail.toSupportBusinessDepartment";
      case "itDepartment":
        return "taskDetail.department";
      case "componentName":
        return "component.componentName";
      case "taskId":
        return "taskId";
      case "mainTaskName":
        return "taskName";
    }
  }

  public downLoadFile(data: any, type: string,fileName:string) {

    let blob = new Blob([data], { type: type});
     this.saveAs(blob,fileName);
  }


  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
  
}

export class CrReportDataSource extends DataSource<CrReviewReportResponse> {
  data = new BehaviorSubject<CrReviewReportResponse[]>([]);

  connect(): Observable<CrReviewReportResponse[]> {
    return this.data;
  }

  disconnect(): void {}

  update(x: CrReviewReportResponse[]): void {
    this.data.next(x);
  }
}

