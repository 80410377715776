<div class="mt-3 mx-3">
  <div class="my-3 d-flex">
    <h5 style="padding: 0; margin: 0;">Duplicate File Report</h5>
    <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
  </div>
  <div class="card bg-n7 mb-3" #SearchFilter>
    <div class="card-body">
      <h6 class="p-0 m-0;">
        <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
          <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
          <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
          Search Filters
        </a>
      </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
        <div class="row mb-1">
          <div class="col-12 col-sm-6 col-lg-4" #MeetingDate>
            <span class="font-weight-bold">Promote Date</span>
            <form class="form-inline">
              <div class="form-group hidden">
                <div class="input-group w-75">
                  <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker
                    #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input #dpFromDate [ngClass]="{'is-invalid': dateCriteriaInvalid}" class="form-control"
                    style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                    disabled>
                  <span class="btn date-range-symbol">-</span>
                  <input #dpToDate [ngClass]="{'is-invalid': dateCriteriaInvalid}" class="form-control"
                    style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpToDate"
                    [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                    disabled>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                      <fa-icon [icon]="faCalendarAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
    </div>
  </div>
  <div #Table>
    <div class="d-flex justify-content-end my-3">
      <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
      </app-row-per-page>
    </div>
    <div class="table-responsive" #servicesTable *ngIf="reportDataSource.data">

      <table mat-table matSort class="table table-hover" cdk-table [dataSource]="reportDataSource">

        <ng-container cdkColumnDef="promoteId">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Promote ID</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteIDDisplay}}</td>
        </ng-container>

        <ng-container cdkColumnDef="subject">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Subject</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.subject}}</td>
        </ng-container>

        <ng-container cdkColumnDef="fileName">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>File Name</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.filename}}</td>
        </ng-container>

        <ng-container cdkColumnDef="promoteTier">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Promote Tier</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteTier}}</td>
        </ng-container>

        <ng-container cdkColumnDef="expectedPromoteDate">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Expected Promote Date</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.expectedPromoteDate}}</td>
        </ng-container>

        <tr class="table-header" cdk-header-row *cdkHeaderRowDef="reportColumns"></tr>
        <tr class="table-row" cdk-row *cdkRowDef="let row; columns: reportColumns"></tr>
      </table>

      <div class="not-found text-center" *ngIf="reportDataSource.data == null || totalElements === 0">
        <div class="my-3"> No Data found.</div>
      </div>

    </div>

    <div class="bg-black-haze">
      <div class="align-self-center">
        <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
          [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
          (pageChange)="updatePage()">
        </ngb-pagination>
      </div>
    </div>

  </div>
</div>