export class SearchTimeSheetViewCriteria {
    year?: string;
    deptId?: string;
    empId?: string[];
    onlyActive?: boolean;
  
    SearchTimeSheetViewCriteria(): void {
      this.year = "";
      this.deptId = "";
      this.empId = null;
      this.onlyActive = true;
    }
  }