import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { faAngleDown, faAngleUp, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ComponentControllerService, ComponentResponse, DepartmentControllerService, DepartmentResponse, TasksConvertToJiraTimesheet, TimesheetControllerService } from '@set-it-workflow/set-it-workflow-ts-angular';
import { toNgbDate } from 'src/app/layout/shared/function/to-ngb-date.function';
import { NewTimesheetReportCriteria } from 'src/app/model/new-timesheet-report-criteria.model';
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';
import { TimesheetReportService } from '../services/timesheet-report.service';

@Component({
  selector: 'app-new-timesheet-report',
  templateUrl: './new-timesheet-report.component.html',
  styleUrls: ['./new-timesheet-report.component.scss']
})
export class NewTimesheetReportComponent implements OnInit {
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public isSearch = false;
  public isCollapse = false;
  public initDefaultItDepartmentId;
  public defaultItDepartmentId;
  public listProjectSize = ["S", "M", "L"];
  public listDefaultProjectSize = ["M", "L"];
  public initListDefaultProjectSize = ["M", "L"];

  public page = 0;
  public pageSize = 10;
  public totalElements = 0;
  public sort: Sort = {active: 'totalEstimateManDays', direction: 'desc'};

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate;
  public toDate: NgbDate | null = null;
  public dateCriteriaInvalid = false;

  public itDepartments: DepartmentResponse[] = [];
  public serviceByDeptId: ComponentResponse[] = [];
  public tasks: TasksConvertToJiraTimesheet[] = [];

  public newTimesheetReportColumn = [
    'type', 
    'taskID', 
    'mainTaskName',
    // 'itDepartment',
    'service',
    'createdDate',
    'totalEstimateManDays',
    'actualMandays',
    'percentDiff',
    'progressStatus'
  ];

  public form: FormGroup = new FormGroup({
    deptId: new FormControl(null, Validators.required),
    serviceIds: new FormControl(),
    createdStartDate: new FormControl(),
    createdEndDate: new FormControl(),
    isTaskDone: new FormControl(),
    taskName: new FormControl(),
    projectSize: new FormControl(),
    isConvertToJira: new FormControl(),
  })

  get deptId(): FormControl { return this.form.controls.deptId as FormControl; }
  get serviceIds(): FormControl { return this.form.controls.serviceIds as FormControl; }
  get createdStartDate(): FormControl { return this.form.controls.createdStartDate as FormControl; }
  get createdEndDate(): FormControl { return this.form.controls.createdEndDate as FormControl; }
  get isTaskDone(): FormControl { return this.form.controls.isTaskDone as FormControl; }
  get taskName(): FormControl { return this.form.controls.taskName as FormControl; }
  get projectSize(): FormControl { return this.form.controls.projectSize as FormControl; }
  get isConvertToJira(): FormControl { return this.form.controls.isConvertToJira as FormControl; }


  constructor(
    private departmentService: DepartmentControllerService,
    private timesheetService: TimesheetControllerService,
    private componentService: ComponentControllerService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private _httpClient: HttpClient,
    private authService: AuthService,
    private timesheetReportService: TimesheetReportService
  ) { }

  ngOnInit(): void {
    this.getITDepartments();
  }

  private getITDepartments(): void {
    this.departmentService.getDefaultITDepartmentByUserLoginUsingGET()
    .subscribe(res => {
      this.itDepartments = res.data;
      res.data.forEach(value => {
        if (value.defaultDept) {
          this.defaultItDepartmentId = value.deptID;
          this.initDefaultItDepartmentId = value.deptID;
          if (this.defaultItDepartmentId) {
            this.getComponentByDeptId1(this.defaultItDepartmentId);
          } else {
            this.getComponentByDeptId();
          }
         
        }
      });
      var today = new Date();
      this.fromDate = toNgbDate(new Date(today.getFullYear(),0,1));
      this.toDate = toNgbDate(today);
    });
  }

  private getTasksConvertToJira(): void {
    this.timesheetService.getTasksConvertToJiraTimesheetUsingGET1(
      this.deptId.value, this.isConvertToJira.value, this.createdEndDate.value, this.createdStartDate.value, this.isTaskDone.value,
      this.page - 1, this.pageSize, this.projectSize.value, this.serviceIds.value, this.sort.active, 
      this.sort.direction, this.taskName.value
    ).subscribe(res => {
      this.tasks = res.data.tasks;
      this.totalElements = res.data.totalElements;
      this.isSearch = true;
    });
  }

  private getComponentByDeptId(): void {
    this.componentService.getComponentsByDeptIdUsingGET(this.deptId.value).subscribe(res => {
      this.serviceByDeptId = res.data;
    });
  }

  private getComponentByDeptId1(deptId: string): void {
    this.componentService.getComponentsByDeptIdUsingGET(deptId).subscribe(res => {
      this.serviceByDeptId = res.data;
    });
  }

  public onSelectDepartment(): void {
    this.serviceIds.enable();
    this.isSearch = false;
    this.getComponentByDeptId();
  }

  public onSelectServices(): void {
    this.isSearch = false;
  }

  public onSelectProjectSize(): void {
    this.isSearch = false;
  }

  public clear(): void {
    this.form.reset();
    this.form.controls.deptId.setValue(this.initDefaultItDepartmentId);
    this.form.controls.projectSize.setValue(this.initListDefaultProjectSize);
    var today = new Date();
    this.fromDate = toNgbDate(new Date(today.getFullYear(),0,1));
    this.toDate = toNgbDate(today);
    this.getComponentByDeptId1(this.initDefaultItDepartmentId);
    this.tasks = [];
    this.page = 1;
    this.totalElements = 0;
  }

  public search(): void {
    this.form.markAllAsTouched();
    this.createdStartDate.setValue(this.formatter.format(this.fromDate));
    this.createdEndDate.setValue(this.formatter.format(this.toDate));

    if (!this.form.invalid) {
      this.page = 1;
      this.getTasksConvertToJira();
    }
  }

  public onSortChange(sort: Sort) {
    this.sort = sort
    this.getTasksConvertToJira();
  }

  public updatePageSize(size: number): void {
    this.pageSize = size;
    this.page = 1;
    this.getTasksConvertToJira();
  }

  public updatePage(): void {
    if (this.deptId.value && this.isSearch) {
      this.getTasksConvertToJira();
    }
  }

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  public isHovered(date: NgbDate): boolean {
    this.dateCriteriaInvalid = false;
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): boolean | void {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public validateInput(
    currentValue: NgbDate | null,
    input: string
  ): NgbDate | null {
    const parsed = this.formatter.parse(input);
    this.dateCriteriaInvalid = false;
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  public export (){
    let serviceCriteria: NewTimesheetReportCriteria = this.setExportCriteria();
    this.exportExcel(serviceCriteria);
  }

  private setExportCriteria(): NewTimesheetReportCriteria {
    return {
      deptId: this.deptId.value,
      createdStartDate: this.createdStartDate.value,
      createdEndDate: this.createdEndDate.value,
      serviceIds: this.serviceIds.value,
      pageNumber: this.page - 1,
      pageSize: this.pageSize,
      sortBy: this.sort.active,
      sortDirection: this.sort.direction,
      isTaskDone: this.isTaskDone.value,
      taskName: this.taskName.value,
      projectSizes: this.projectSize.value,
      convertToJira: this.isConvertToJira.value,
    } as NewTimesheetReportCriteria
  }

  public exportExcel(criteria: NewTimesheetReportCriteria){
    this._httpClient.post(config.exportNewTimesheetReport,criteria,
      { headers: { 'Authorization': ('bearer ' + this.authService.token) },
        responseType: 'arraybuffer'})
    .subscribe((response: any) => {
    const filename = 'new-timesheet-report.xlsx';
    this.timesheetReportService.downLoadFile(response, "application/octet-stream", filename);
    });
  }
}
