import { AnnouncementModule } from './announcement/announcement.module';
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PrivateComponent } from "./private.component";
import { TaskFormComponent } from "./task-form/task-form.component";

const routes: Routes = [
  {
    path: "",
    component: PrivateComponent,
    children: [
      {
        path: '',
        redirectTo: "task",
        pathMatch: "full"
      },
      {
        path: "task",
        loadChildren: () =>
          import("./task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "promote-form",
        loadChildren: () =>
          import("./promote-form/promote-form.module").then(
            (m) => m.PromoteFormModule
          ),
      },
      {
        path: "review-meeting",
        loadChildren: () =>
          import("./review-meeting/review-meeting.module").then(
            (m) => m.ReviewMeetingModule
          ),
      },
      {
        path: "user-role-manage",
        loadChildren: () =>
          import("./user-role-manage/user-role-manage.module").then(
            (m) => m.UserRoleManageModule
          ),
      },
      {
        path: "service-management",
        loadChildren: () =>
          import("./service-management/service-management.module").then(
            (m) => m.ServiceManagementModule
          ),
      },
      {
        path: "report",
        loadChildren: () =>
          import("./report/report.module").then(
            (m) => m.ReportModule
          ),
      },
      {
        path: "timesheet",
        loadChildren: () =>
          import("./timesheet/timesheet.module").then(
            (m) => m.TimesheetModule
          ),
      },
      {
        path: "form",
        component: TaskFormComponent
      },
      {
        path: 'jira-project-management',
        loadChildren: () => import('./jira-project-management/jira-project-management.module').then((m) => m.JiraProjectManagementModule)
      },
      {
        path: "announcement",
        loadChildren: () =>
          import("./announcement/announcement.module").then(
            (m) => m.AnnouncementModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/task",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
