import { FileUtilService } from './../../../../service/file-util.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faPaperclip, faTimes} from '@fortawesome/free-solid-svg-icons';
import {fileResponse} from '../../../../model/file.model';
import {FileStoreControllerService} from '@set-it-workflow/set-it-workflow-ts-angular/index';
import {environment} from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/service/auth.service';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  public faPaperclip = faPaperclip;
  public faTimes = faTimes;

  @Input()
  fileList: any[] = [];
  @Output() fileListChange = new EventEmitter<any[]>();
  @Output() deleteFileListChange = new EventEmitter<number>();

  @Input()
  isEditable: boolean;
  fileDraggedOverDiv = false;
  specialFileList = [
    '.sql',
    '.log',
    '.json',
    '.md',
    '.msg',
    '.zip'
  ];
  public fileTypeAcceptList = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/plain',
    'text/html',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/csv',
    'message/rfc822',
    'application/json',
    'application/x-zip-compressed',
    'application/x-compressed',
    'application/zip',
    'multipart/x-zip',
    '.csv',
  ];

  get fileType() {
    return this.fileTypeAcceptList.concat(this.specialFileList).join(', ');
  }

  constructor(
    private fileService: FileStoreControllerService,
    private fileUtil: FileUtilService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
  }

  addFile(file: File): void {
    if (file.size > 20971520) {
      this.toastr.error('File is too big!');
      return;
    }else if (file.name.length > 70){
      this.toastr.error('You must upload file with length of filename less than 70 characters.');
      return;
    } else {
      let ignoreValidateFile = false;
      if (file.type === '') {
        const fileExtension = file.name.split('.').pop();
        ignoreValidateFile = this.specialFileList.indexOf('.' + fileExtension) !== -1;
      }
      if (!ignoreValidateFile && this.fileTypeAcceptList.indexOf(file.type) === -1) {
        this.toastr.error('File format is wrong!');
        return;
      }
      const reader = new FileReader();
      reader.readAsText(file);
      this.fileService.uploadFileUsingPOST(file, true).subscribe((res) => {
        const data: fileResponse = {
          fileStoreId: res.data.fileStoreId,
          fileStoreName: res.data.fileName,
          attachmentId: null,
        };
        this.fileList.push(data);
        this.fileListChange.emit(this.fileList);
      });
    }
  }

  deleteFile(file: fileResponse): void {
    if (file.attachmentId) {
      this.fileList = this.fileList.filter(
        (f) => f.fileStoreId !== file.fileStoreId
      );
      this.fileListChange.emit(this.fileList);
      this.deleteFileListChange.emit(file.fileStoreId);
    } else {
      this.fileService
        .inactiveFileUsingDELETE(file.fileStoreId)
        .subscribe(() => {
          this.fileList = this.fileList.filter(
            (f) => f.fileStoreId !== file.fileStoreId
          );
          this.fileListChange.emit(this.fileList);
        });
    }
  }

  openFile(file: fileResponse): void {
    const token = `access_token=${this.authService.token}`;
    this.fileUtil.downloadFile(file.fileStoreId).subscribe(data => {
      saveAs(data, file.fileStoreName);
    });
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.fileDraggedOverDiv = true;
    return false;
  }

  onDropSuccess(event) {
    event.preventDefault();
    let files: File[] = event.dataTransfer.files;
    for(let file of files){
      this.addFile(file);
    }
    this.fileDraggedOverDiv = false;
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.fileDraggedOverDiv = false;
    return false;
  }
}
