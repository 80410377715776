<div class="card card-detail bg-n7">
  <div class="card-header">
    <h6 class="card-title">
      <a (click)="collapseAttachFile.toggle();toggleChange();" [attr.aria-expanded]="!isCollapse"
        aria-controls="collapseExample">
        <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
        <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
        Attach File
      </a>
    </h6>
  </div>
  <div class="card-body" #collapseAttachFile="ngbCollapse" [(ngbCollapse)]="isCollapse">
    <app-upload-file [fileList]="fileList" (fileListChange)="change($event)" [isEditable]="isEditMode"></app-upload-file>
  </div>
</div>