import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

export function toNgbDate(value: Date): NgbDate | null {
  if(value){
    const date = new Date(value);
  const ngbDateStruct = {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
  return NgbDate.from(ngbDateStruct);
  } else {
    return null;
  } 
  
}

export function concateDateTime(date: NgbDate, time: NgbTimeStruct): Date | null {
  return new Date(Date.UTC(date.year, date.month - 1, date.day, time.hour, time.minute));
}

export function concatDate(date: NgbDate):Date | null {
  return new Date(Date.UTC(date.year, date.month - 1, date.day));
}

export function getTimeFromDate(dateTime: Date): Object | null {
  const date = new Date(dateTime);
  const time = {
    hour: date.getHours(),
    minute: date.getMinutes()
  };
  return time;
}


export function getDateString(date: any) {
  if (date) {
    const newDate = new Date(
      date.year,
      date.month - 1,
      date.day
    );
    return new DatePipe('th-be').transform(newDate, 'yyyy-MM-dd');
  }
  return null;

}
