<div class="mt-3 mx-3">
  <div class="my-3 d-flex">
    <h5 style="padding: 0; margin: 0;">Promote Form by Promote ID</h5>
    <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
  </div>
  <div class="card bg-n7 mb-3">
    <div class="card-body">
      <h6 class="p-0 m-0;">
        <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
          <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
          <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
          Search Filters
        </a>
      </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
        <form [formGroup]="form">
          <div class="row mb-1">
            <div class="col-12 col-sm-6 col-lg-5">
              <span class="font-weight-bold">Promote ID</span>
              <input type="text" class="form-control form-control-sm" formControlName="promoteID">
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
    </div>
  </div>

  <table class="table">
    <thead>
    <tr>
      <th>Promote ID</th>
      <th>Sub Promote ID</th>
      <th>Source Tier</th>
      <th>Promote Tier</th>
      <th>Promote List</th>
      <th>Sub Promote Status</th>
      <th>Modified Date</th>
    </tr>
    </thead>
    <tbody *ngIf="data.length > 0">
    <tr *ngFor="let item of data; let i = index;">
      <td>{{item.promoteIDDisplay}}</td>
      <td>{{item.promoteIDDisplay}}_{{item.subPromoteID}}</td>
      <td>{{item.source}}</td>
      <td>{{item.destination}}</td>
      <td>
        <span *ngFor="let fileList of item.fileLists">{{fileList.status}} {{fileList.filepath}}<br/></span>
      </td>
      <td>{{item.subPromoteStatus}}</td>
      <td>{{item.modifiedDate}}</td>
    </tr>
    </tbody>
  </table>
  <div class="not-found text-center" *ngIf="data.length < 1" >
    <div class="my-3"> No Data found.</div>
  </div>

</div>
