import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BASE_PATH, Configuration } from '@set-it-workflow/set-it-workflow-ts-angular';

@Injectable({
  providedIn: 'root'
})
export class FileUtilService {
  public environment = environment;
  public defaultHeaders = new HttpHeaders();;

constructor(protected httpClient: HttpClient) { }
  downloadFile(fileStoreId:number):Observable<Blob>{
    if (fileStoreId === null || fileStoreId === undefined) {
      throw new Error('Required parameter fileStoreId was null or undefined when calling getUsingGET.');
  }

  let headers = this.defaultHeaders;
  headers = headers.set('Authorization','Bearer '+localStorage.getItem('set-it-workflow-access'));

  return this.httpClient.get(`${this.environment.apiBasePath}/file-store/${encodeURIComponent(String(fileStoreId))}`,
            {
                withCredentials: true,
                headers: headers,
                observe: 'body',
                responseType: 'blob'
            }
        );
  }

}
