export class OutstaningReportCriteria {
    itDeptId?: string;
    deptId?: string;
    endDate?: string;
    startDate?: string;
  
    OutStandingReportCriteria(): void {
      this.deptId = "";
      this.endDate = "";
      this.startDate = "";
    }
  }