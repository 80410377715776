import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AuthService } from '../../service/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!window.location.href.includes('/login'))
      sessionStorage.setItem("comeBackTo", window.location.href);
    if (req.url.indexOf('refresh') !== -1) {
      return next.handle(req);
    }

    if (!req.headers.has('authorization')) {
      return next.handle(req);
    }

    const accessExpired = this.authService.doesTokenExpired();
    const refreshExpired = this.authService.doesRefreshTokenExpired();

    if (accessExpired && refreshExpired) {
      this.router.navigateByUrl('/login');
      return EMPTY;
    }
    if (accessExpired && !refreshExpired) {
      if (!this.authService.refreshTokenInProgress) {
        return this.authService.refresh().pipe(
          switchMap((authResponse) => {
            if (authResponse) {
              // means refreshing success
              return next.handle(
                req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${this.authService.token}`
                  }
                })
              );
            } else {
              this.router.navigateByUrl('/login');
              return EMPTY;
            }
          })
        );
      } else {
        return this.authService.accessTokenSubject.pipe(
          first((result) => result !== null),
          switchMap((res) => {
            return next.handle(
              req.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.authService.token}`
                }
              })
            );
          })
        );
      }
    }
    if (!accessExpired) {
      return next.handle(
        req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.token}`
          }
        })
      );
    }
  }
}
