<div class="d-flex">
  <span class="mr-2">
    Rows per page:
  </span>
  <select name="top-pagination" id="top-pagination" [(ngModel)]="size" (change)="updatePage()">
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
</div>