import { EnumResponse } from "@set-it-workflow/set-it-workflow-ts-angular";

export function convertJiraTransitionToString(
  transition: EnumResponse.JiraTransitionEnum
): string {
  switch (transition) {
    case EnumResponse.JiraTransitionEnum.CANCEL:
      return "Cancel";
    case EnumResponse.JiraTransitionEnum.CHANGEDEVELOPER:
      return "Change Developer";
    case EnumResponse.JiraTransitionEnum.APPROVE:
      return "Approve";
    case EnumResponse.JiraTransitionEnum.ASSIGNTOSECURITY:
      return "Assign to Security";
    case EnumResponse.JiraTransitionEnum.ASSIGNEDTOITSECURITY:
      return "Assigned to IT Security";
    case EnumResponse.JiraTransitionEnum.ASSIGNTODEVELOPER:
      return "Assign to Developer";
    case EnumResponse.JiraTransitionEnum.ASSIGNTODEVHEAD:
      return "Assign to Developer Head";
    case EnumResponse.JiraTransitionEnum.COMPLETETASK:
      return "Complete Task";
    case EnumResponse.JiraTransitionEnum.CHANGEUSER:
      return "Change User";
    case EnumResponse.JiraTransitionEnum.READYTOTEST:
      return "Ready to Test";
    case EnumResponse.JiraTransitionEnum.REJECT:
      return "Reject";
    case EnumResponse.JiraTransitionEnum.SUBMITTOPROMOTE:
      return "Submit to Promote";
    case EnumResponse.JiraTransitionEnum.SUBMITTOPROMOTESENDQC:
      return "Submit to Promote and Send to QC";
    case EnumResponse.JiraTransitionEnum.SUBMITTOPROMOTESENDUAT:
      return "Submit to Promote and Send to UAT";
    case EnumResponse.JiraTransitionEnum.SUBMITTOPROMOTESENDITHEAD:
        return "Submit to Promote and Send to IT Head";
    case EnumResponse.JiraTransitionEnum.DEVELOPERHEADCOMPLETETASK:
      return "Developer Head Complete Task";
    case EnumResponse.JiraTransitionEnum.REASSIGNTODEVELOPER:
      return "Reassign to Developer";
    case EnumResponse.JiraTransitionEnum.REJECTTOCS:
      return "Reject to CS";
    case EnumResponse.JiraTransitionEnum.CLOSEDEVELOPERTASK:
      return "Close Developer Task"
    case EnumResponse.JiraTransitionEnum.COMPLETE:
      return "Complete";
    case EnumResponse.JiraTransitionEnum.CONVERTTOCR:
      return "Convert to CR";
    case EnumResponse.JiraTransitionEnum.INFORMMDTOITHEAD:
      return "Inform man-day to IT Head";
    case EnumResponse.JiraTransitionEnum.RAISETOBUSINESSUNITHEAD:
      return "Raise to Business Unit Head";
    case EnumResponse.JiraTransitionEnum.RAISETOBUSINESSDEPTHEAD:
      return "Raise to Business Dept. Head";
    case EnumResponse.JiraTransitionEnum.RAISETOITHEAD:
      return "Raise to IT Head";
  }
}
