import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccessDeniedComponent} from './layout/access-denied/access-denied.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./layout/private/private.module').then((m) => m.PrivateModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./layout/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout/private/private.module').then((m) => m.PrivateModule),
  },
  {
    path: '**', redirectTo: '/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
