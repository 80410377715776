import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faAngleDown, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeControllerService, EmployeeResponse } from '@set-it-workflow/set-it-workflow-ts-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserProfile } from 'src/app/service/user-profile.service';
import { EmployeeRoleEnum } from '../../constant/employee-role-enum';
import { SearchUserModalComponent } from '../search-user-modal/search-user-modal.component';

@Component({
  selector: 'app-approve-detail-box',
  templateUrl: './approve-detail-box.component.html',
  styleUrls: ['./approve-detail-box.component.scss']
})
export class ApproveDetailBoxComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() isCollapse: boolean;
  @Input() fieldsData: any;
  @Input() isReadonly: boolean;
  @Input() isEditMode: boolean;
  @Input() userProfile: UserProfile;
  @Output() formChange = new EventEmitter<FormGroup>();

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faSearch = faSearch;

  private subscription: Subscription;
  public allEmployeeList: BehaviorSubject<EmployeeResponse[]> = new BehaviorSubject(new Array<EmployeeResponse>());
  public itHeadList: EmployeeResponse[] = [];
  public cabList: EmployeeResponse[] = [];
  public seList: EmployeeResponse[] = [];
  private userModal: NgbModalRef;
  //cabEmpId = '00625';

  roleEnum = EmployeeRoleEnum;

  constructor(
    private employeeService: EmployeeControllerService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.subscription = new Subscription();
    this.employeeService.getEmployeeUsingGET(true).subscribe((emp) => {
      this.allEmployeeList.next(emp.data);
      this.getItHeads();
      this.getCABs();
      this.getDefaultCabs();
      this.getSEs();
    });
    this.onFormChange();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  private onFormChange() {
    this.subscription.add(
      this.form.valueChanges.subscribe(() => this.formChange.emit(this.form))
    );
  }

  private getItHeads(): void {
    if (this.userProfile) {
      this.employeeService.getItHeadsUsingGET(this.userProfile.deptId).subscribe((itHead) => {
        if (itHead) {
          this.itHeadList = itHead.data;
          if (!this.form.controls.itHeadEr.value) {
            this.form.controls.itHeadEr.setValue(itHead.data[0].empID);
          }
          this.checkItHeadPerson();
        }
      });
    }
  }

  private checkItHeadPerson() {
    if(this.form.controls.itHead?.value){
      const isFound = this.itHeadList.some(x=> (x.empID === this.form.controls.itHead.value && x.nameEn))
      if(!isFound){
        const item = this.allEmployeeList.value.find(x=>x.empID === this.form.controls.itHead.value);
        this.itHeadList.push(item);
      }
      this.itHeadList = [...this.itHeadList];
    }
    if(this.form.controls.itHeadEr?.value){
      const isFound = this.itHeadList.some(x=> (x.empID === this.form.controls.itHeadEr.value && x.nameEn))
      if(!isFound){
        const item = this.allEmployeeList.value.find(x=>x.empID === this.form.controls.itHeadEr.value);
        this.itHeadList.push(item);
      }
      this.itHeadList = [...this.itHeadList];
    }
  }

  private getCABs(): void {
    this.employeeService.getCABsUsingGET().subscribe((cab) => {
      if (cab) {
        this.cabList = [...cab.data];
        this.fieldsData.cab.editable = false;
        this.form.controls.cab.disable();
      }
    });
  }

  private getDefaultCabs(): void {
    if (!this.form.controls.cab.value) {
      this.employeeService.getDefaultErCABUsingGET().subscribe((defaultCab)=>{
        if(defaultCab) {
          this.form.controls.cab.setValue(defaultCab.data.empID);
        }
      })
    } else {
      const isFound = this.cabList.some(x=> (x.empID === this.form.controls.cab.value && x.nameEn))
        if(!isFound){
          const item = this.allEmployeeList.value.find(x=>x.empID === this.form.controls.cab.value);
          this.cabList.push(item);
        }
        this.cabList = [...this.cabList];
    }
  }

  private getSEs(): void {
    this.employeeService.getSEsUsingGET().subscribe((se) => {
      if (se) {
        this.seList = se.data;
        this.checkSePerson();
      }
    });
  }

  private checkSePerson() {
    if(this.form.controls.se?.value){
      const isFound = this.seList.some(x=> (x.empID === this.form.controls.se.value && x.nameEn))
      if(!isFound){
        const item = this.allEmployeeList.value.find(x=>x.empID === this.form.controls.se.value);
        this.seList.push(item);
      }
      this.seList = [...this.seList];
    }
  }

  openSearchUserModal(role: string): void {
    this.userModal = this.modalService.open(SearchUserModalComponent, { size: 'xl' });
    this.userModal.componentInstance.defaultDeptId = this.userProfile.deptId;
    this.userModal.componentInstance.role = role;

    this.userModal.result.then((result) => {
      if (result && result.selected) {
        switch (result.role) {
          case this.roleEnum.IT_HEAD.name:
            if (!this.itHeadList.find((f) => f.empID === result.selected.empID)) {
              this.itHeadList.push(result.selected);
              this.itHeadList = [...this.itHeadList];
            }
            this.form.controls.itHeadEr.setValue(result.selected.empID);
            break;
          case this.roleEnum.CAB.name:
            if (!this.cabList.find((f) => f.empID === result.selected.empID)) {
              this.cabList.push(result.selected);
              this.cabList = [...this.cabList];
            }
            this.form.controls.cab.setValue(result.selected.empID);
            break;
          case this.roleEnum.SE.name:
            if (!this.seList.find((f) => f.empID === result.selected.empID)) {
              this.seList.push(result.selected);
              this.seList = [...this.seList];
            }
            this.form.controls.se.setValue(result.selected.empID);
            break;
        }
        this.form.updateValueAndValidity();
      }
    });
  }

}
