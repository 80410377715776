export class EmployeeRoleEnum {
    public static CS = { name: 'CS' };
    public static SOLA = { name: 'SOLA' };
    public static IT_HEAD = { name: 'IT Head' };
    public static DEV_HEAD = { name: 'Developer Head' };
    public static DEV = { name: 'Developer' };
    public static QC_HEAD = { name: 'QC Head' };
    public static QC = { name: 'QC' };
    public static UAT_HEAD = { name: 'UAT Head' };
    public static IT_ARCHITECT = { name: 'IT Architect' };
    public static SECURITY = { name: 'Security' };
    public static ENV_COMMITTEE = { name: 'Environment Committee' };
    public static BU_UNIT_HEAD = { name: 'Business Unit Head' };
    public static BU_DEPT_HEAD = { name: 'Business Dept Head' };
    public static CAB = { name: 'CAB' };
    public static PQA = { name: 'PQA' };
    public static SE = { name: 'SE' };
}