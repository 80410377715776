import { EnumResponse } from "@set-it-workflow/set-it-workflow-ts-angular/model/enumResponse";

export function convertStringToWorkflowStatus(
  state: string
): EnumResponse.WorkflowStatusEnum {
  switch (state) {
    case "Acknowledged to Business User":
      return EnumResponse.WorkflowStatusEnum.ACKNOWLEDGETOBU;
    case "Business Dept. Head Review":
      return EnumResponse.WorkflowStatusEnum.BUDEPTHEADREVIEW;
    case "Business Unit Head Review":
      return EnumResponse.WorkflowStatusEnum.BUUNITHEADREVIEW;
    case "Create":
      return EnumResponse.WorkflowStatusEnum.CREATE;
    case "CR Review Meeting":
      return EnumResponse.WorkflowStatusEnum.CRREVIEWMEETING;
    case "CS Task":
      return EnumResponse.WorkflowStatusEnum.CSTASK;
    case "Developer Estimate Manday":
      return EnumResponse.WorkflowStatusEnum.DEVESTIMATEMANDAY;
    case "Developer Head Task":
      return EnumResponse.WorkflowStatusEnum.DEVHEADTASK;
    case "Developer Task":
      return EnumResponse.WorkflowStatusEnum.DEVTASK;
    case "Draft":
      return EnumResponse.WorkflowStatusEnum.DRAFT;
    case "Edit":
      return EnumResponse.WorkflowStatusEnum.EDIT;
    case "Business Approval (EVP)":
      return EnumResponse.WorkflowStatusEnum.EVP;
    case "IT Head Review":
      return EnumResponse.WorkflowStatusEnum.ITHEADREVIEW;
    case "SOLA Review":
      return EnumResponse.WorkflowStatusEnum.SOLAREVIEW;
      case "Business Approval (VP,SVP)":
        return EnumResponse.WorkflowStatusEnum.VPSVP;
      case "Business Approval (SEVP)":
        return EnumResponse.WorkflowStatusEnum.SEVP;
    case "Converted Task to CR":
      return EnumResponse.WorkflowStatusEnum.CONVERTEDTASKTOCR;
    case "Waiting for CS Review":
      return EnumResponse.WorkflowStatusEnum.WAITFORCSREVIEW;
    case "Completed Estimate Manday":
      return EnumResponse.WorkflowStatusEnum.COMPLETEDESTIMATEMANDAY;
    case "Completed":
      return EnumResponse.WorkflowStatusEnum.COMPLETED;
    case "Closed":
      return EnumResponse.WorkflowStatusEnum.CLOSED;
    case "Waiting for Developer Estimate Manday":
      return EnumResponse.WorkflowStatusEnum.WAITINGFORDEVESTIMATEMANDAY;
    case "Rejected":
      return EnumResponse.WorkflowStatusEnum.REJECTED;
    case "Assigned to Developer Head":
      return EnumResponse.WorkflowStatusEnum.ASSIGNEDTODEVHEAD;
    case "Assigned to Developer":
      return EnumResponse.WorkflowStatusEnum.ASSIGNEDTODEV;
    case "Waiting for Security Review":
      return EnumResponse.WorkflowStatusEnum.WAITINGFORSECURITYREVIEW;
    // case "Waiting for Release":
    //   return EnumResponse.WorkflowStatusEnum.WAITINGFORRELEASE;
    case "Developer Complete Task":
      return EnumResponse.WorkflowStatusEnum.DEVELOPERCOMPLETETASK;
    case "IT Head Complete Task":
      return EnumResponse.WorkflowStatusEnum.ITHEADCOMPLETETASK;
    case "Waiting for Client Confirmation":
      return EnumResponse.WorkflowStatusEnum.WAITINGFORCLIENTCONFIRMATION;
    case "IT User Create IR":
      return EnumResponse.WorkflowStatusEnum.ITUSERCREATEIR;
    case "IT Head of Service Owner Review":
      return EnumResponse.WorkflowStatusEnum.ITHEADOFSERVICEOWNERREVIEW;
    case "IT Head of Requester Review":
      return EnumResponse.WorkflowStatusEnum.ITHEADOFREQUESTERREVIEW;
    case "QC Task":
      return EnumResponse.WorkflowStatusEnum.QCTASK;
    case "UAT Task":
      return EnumResponse.WorkflowStatusEnum.UATTASK;
    case "IT Head Approve Release":
      return EnumResponse.WorkflowStatusEnum.ITHEADAPPROVERELEASE;
    case "Developer Head Complete Task":
      return EnumResponse.WorkflowStatusEnum.DEVELOPERHEADCOMPLETETASK;
    case "Developer Head Create Release":
      return EnumResponse.WorkflowStatusEnum.DEVHEADCREATERELEASE;
    case "Canceled":
      return EnumResponse.WorkflowStatusEnum.CANCELED;
    case "Waiting for CAB Approval":
      return EnumResponse.WorkflowStatusEnum.WAITINGCABAPPROVAL;
    case "CAB Approved":
      return EnumResponse.WorkflowStatusEnum.CABAPPROVED;
    case "Production Acceptance Complete Release":
      return EnumResponse.WorkflowStatusEnum.PRODUCTIONACCEPTANCECOMPLETERELEASE;
    case "Assigned to Security":
      return EnumResponse.WorkflowStatusEnum.ASSIGNTOSECURITY;
    case "Main Business User Create BR":
      return EnumResponse.WorkflowStatusEnum.MAINBUSINESSUSERCREATEBR;
    case "Main Business User Create SR":
      return EnumResponse.WorkflowStatusEnum.MAINBUSINESSUSERCREATESR;
    case "Waiting for CAB Approval":
      return EnumResponse.WorkflowStatusEnum.WAITINGCABAPPROVAL;
    case "Main Business User Create QR":
      return EnumResponse.WorkflowStatusEnum.MAINBUCREATEQR;
    case "IR Reassign Task":
      return EnumResponse.WorkflowStatusEnum.IRREASSIGNTASK;
    case "Developer Create Promote Form":
      return EnumResponse.WorkflowStatusEnum.DEVCREATEPROMOTEFORM;
    case "Developer Create ER Task":
      return EnumResponse.WorkflowStatusEnum.DEVCREATEERTASK;
    case "QC Assign Task":
      return EnumResponse.WorkflowStatusEnum.QCASSIGNTASK;
    case "Submit to Promote":
      return EnumResponse.WorkflowStatusEnum.SUBMITTOPROMOTE;
    case "Ready to Test":
      return EnumResponse.WorkflowStatusEnum.READYTOTEST;   
    case "Failed and Close Task":
      return EnumResponse.WorkflowStatusEnum.FAILEDANDCLOSETASK;
    case "IR Review Meeting":
      return EnumResponse.WorkflowStatusEnum.IRREVIEWMEETING;
    case "CR Review Meeting":
      return EnumResponse.WorkflowStatusEnum.CRREVIEWMEETING;
    case "Business Dept Head Approve Release":
      return EnumResponse.WorkflowStatusEnum.BUSINESSDEPTHEADAPPROVERELEASE;
  }
}
