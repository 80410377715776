import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrReviewComponent } from './cr-review/cr-review.component'
import { IrReviewComponent } from './ir-review/ir-review.component'
import { DuplicateFileComponent} from './duplicate-file/duplicate-file.component'
import { PromoteFileComponent } from './promote-file/promote-file.component'
import { ReportComponent } from './report.component'
import { PromoteFormByPromoteIdComponent} from './promote-form-by-promote-id/promote-form-by-promote-id.component';
import { OutstandingComponent } from './outstanding/outstanding.component';
import { PromotedTaskComponent } from './promoted-task/promoted-task.component';
import { SpecialRoleComponent } from './special-role/special-role.component';
import { TimesheetViewComponent } from './timesheet-view/timesheet-view.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TimesheetReportComponent } from './timesheet-report/timesheet-report.component';
import { NewTimesheetReportComponent } from './new-timesheet-report/new-timesheet-report.component';
import { TimesheetReportDashboardComponent } from './timesheet-report-dashboard/timesheet-report-dashboard.component';
import { RequestProductionDataComponent } from './request-production-data/request-production-data.component';

const routes: Routes = [
  {
    path: '',
    component: ReportComponent,
    children: [
      {
        path: '',
        redirectTo: "outstanding",
        pathMatch: "full"
      },
      {
        path: 'cr-review',
        component: CrReviewComponent
      },
      {
        path: 'ir-review',
        component: IrReviewComponent
      },
      {
        path: 'duplicate-file',
        component: DuplicateFileComponent
      },
      {
        path: 'promote-file',
        component: PromoteFileComponent
      },
      {
        path: 'promote-form-by-promote-id',
        component: PromoteFormByPromoteIdComponent
      },
      {
        path: 'outstanding',
        component: OutstandingComponent
      },
      {
        path: 'promoted',
        component: PromotedTaskComponent
      },
      {
        path: 'special-role',
        component: SpecialRoleComponent
      },
      {
        path: 'timesheet-view',
        component: TimesheetViewComponent
      },
      {
        path: 'timesheet',
        component: TimesheetComponent
      },
      {
        path: 'timesheet-report',
        component: TimesheetReportComponent
      },
      {
        path: 'new-timesheet-report',
        component: NewTimesheetReportComponent
      },
      {
        path: 'timesheet-report-dashboard',
        component: TimesheetReportDashboardComponent
      },
      {
        path: 'request-production-data',
        component: RequestProductionDataComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
