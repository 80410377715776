import {BrowserModule} from '@angular/platform-browser';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './layout/shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NgbDateCustomParserFormatter} from 'src/app/formatter/ngb-date-custom-parser-formatter';
import {PrivateModule} from './layout/private/private.module';
import {environment} from './../environments/environment';
import localeTh from '@angular/common/locales/th';
import {
  ApiModule,
  BASE_PATH,
  Configuration,
  ConfigurationParameters
} from '@set-it-workflow/set-it-workflow-ts-angular';
import {AuthProvider} from './config/authen-provider';
import {AuthInterceptor} from './core/interceptor/authen-interceptor';
import {GeneralAPIHandleInterceptor} from './core/interceptor/general-api-handle-interceptor';
import {AccessDeniedComponent} from './layout/access-denied/access-denied.component';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {getBaseLocation} from './layout/shared/function/common.function';

registerLocaleData(localeTh);
@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    HttpClientModule,
    PrivateModule,
    SharedModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    ApiModule.forRoot(apiConfigFactory),
    NgxSpinnerModule
  ],
  providers: [
    NgxSpinnerService,
    AuthProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AuthProvider]
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: BASE_PATH,
      useValue: environment.apiBasePath
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: getBaseLocation()
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeneralAPIHandleInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


let sharedApiModuleConfig: Configuration;

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {};

  params.apiKeys = {
    Authorization: ''
  };

  if (sharedApiModuleConfig == null) {
    sharedApiModuleConfig = new Configuration(params);
  }
  return sharedApiModuleConfig;
}

export function init_app(authProvider: AuthProvider) {
  return () => authProvider.onAppLoaded();
}
