import { environment } from './environment';

export const config = {
    // announcement: environment.apiBasePath + '/erecruit/api/v1/positions/active',
    oldWorkFlowPath: environment.oldWorkFlow,
    imageUrlPath: 'https://enterprise.set.or.th/EnterpriseApp/EmpPhoto',
    task: environment.apiBasePath + '/api/task',
    taskDetail: environment.apiBasePath + '/api/task/updateTaskDetail',
    companytask: environment.apiBasePath + '/api/task/companyrequest',
    buFilter: environment.apiBasePath + '/api/task/bufilter',
    pendingTask: environment.apiBasePath + '/api/task/mypending',
    requestTask: environment.apiBasePath + '/api/task/myrequest',
    buPendingTask: environment.apiBasePath + '/api/task/bupending',
    buRequestTask: environment.apiBasePath + '/api/task/burequest',
    buApproveTaskByEmpId: environment.apiBasePath + '/api/task/bu',
    buApproverByTask: environment.apiBasePath + '/api/task/buapprover',
    buApproveTask: environment.apiBasePath + '/api/task/buapprove',
    requestType: environment.apiBasePath + '/api/requesttype',
    serviceType: environment.apiBasePath + '/api/servicetype',
    objectiveType: environment.apiBasePath + '/api/objectivetype',
    status: environment.apiBasePath + '/api/status',
    comment: environment.apiBasePath + '/api/comment',
    profile: environment.apiBasePath + '/api/profile',
    system: environment.apiBasePath + '/api/system',
    transition: environment.apiBasePath + '/api/transition',
    login: environment.apiBasePath + '/api/public/login',
    admin: environment.apiBasePath + '/api/admin',
    userPermission: environment.apiBasePath + '/api/admin/internal-user-permission/',
    pendingUserByTask: environment.apiBasePath + '/api/worklist/task',
    attachment: environment.apiBasePath + '/api/temp-attachment',
    brokerRedirectUrl: environment.apiBasePath + '/api/public/login/find-redirect-url',
    exportCrReview: environment.apiBasePath + '/export/cr',
    exportIrReview: environment.apiBasePath + '/export/ir',
    exportDuplicateFile: environment.apiBasePath + '/export/duplicateFiles',
    exportPromoteFile: environment.apiBasePath + '/export/promoteFiles',
    exportOutstandingTask: environment.apiBasePath + '/export/outstanding',
    exportPromotedTask: environment.apiBasePath + '/export/promoted-report',
    exportSpecialRole: environment.apiBasePath + '/export/special-role-report',
    exportTimeSheet: environment.apiBasePath + '/export/timesheet',
    exportTimeSheetReport: environment.apiBasePath + '/export/timesheet-report',
    exportPromoteFormByPromoteID: environment.apiBasePath + '/export/promote-form-by-promote-id',
    exportPendingPromoteForm: environment.apiBasePath + '/export/pending-promote-form',
    exportPromoteList: environment.apiBasePath + '/export/promote-list',
    exportNewTimesheetReport: environment.apiBasePath + '/export/new-timesheet-report',
    exportRequestProductionDataReport: environment.apiBasePath + '/export/request-production-data',
    adalConfig: {
        tenant: environment.tenant,
        clientId: environment.clientId,
        cacheLocation: 'localStorage',
        // redirectUri: environment.origin + '/setworkflow/login',
        redirectUri: environment.origin + '/login',
        endpoints: {
            'https://graph.microsoft.com': 'graph',
        },
        // postLogoutRedirectUri: environment.origin + '/setworkflow/logout'
        postLogoutRedirectUri: environment.origin + '/logout'
    }
};
