import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DepartmentControllerService,
  DepartmentResponse,
  EmployeeControllerService,
} from '@set-it-workflow/set-it-workflow-ts-angular';
import { EmployeeResponse } from '@set-it-workflow/set-it-workflow-ts-angular/model/employeeResponse';
import { PageResponseListEmployeeResponse } from '@set-it-workflow/set-it-workflow-ts-angular/model/pageResponseListEmployeeResponse';
import { EmployeeRoleEnum } from '../../constant/employee-role-enum';

@Component({
  selector: 'app-search-user-modal',
  templateUrl: './search-user-modal.component.html',
  styleUrls: ['./search-user-modal.component.scss'],
})
export class SearchUserModalComponent implements OnInit {
  public departmentList: DepartmentResponse[] = [];
  public form: FormGroup;
  public userData: PageResponseListEmployeeResponse;
  public page = 1;
  public pageSize = 10;

  @Input() defaultDeptId: number;
  @Input() role: string;

  private roleEnum = EmployeeRoleEnum;

  constructor(
    private activeModal: NgbActiveModal,
    private employeeService: EmployeeControllerService,
    private departmentService: DepartmentControllerService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      department: new FormControl(this.defaultDeptId),
      name: new FormControl(),
    });
    if (this.role === this.roleEnum.DEV.name || this.role === this.roleEnum.IT_HEAD.name) {
      this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
        this.departmentList = result.data;
      });
    } else {
      this.departmentService.getAllDepartmentUsingGET().subscribe((result) => {
        this.departmentList = result.data;
      });
    }
    this.getEmployees();
  }

  private getEmployees(): void {
    this.employeeService.searchByCriteriaUsingGET(
      this.form.value.department,
      this.form.value.name,
      this.page - 1,
      this.role,
      this.pageSize
    ).subscribe((result) => {
      this.userData = result.data;
    });
  }

  public search(): void {
    this.page = 1;
    this.getEmployees();
  }

  public cancel(): void {
    this.activeModal.close();
  }

  public select(item: EmployeeResponse): void {
    this.activeModal.close({ selected: item, role: this.role });
  }

  public updatePage(size: number): void {
    if (this.pageSize !== size) {
      this.page = 1;
    }
    this.pageSize = size;
    this.getEmployees();
  }
}
