<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">New Timesheet Report Dashboard</h5>
    </div>
    <div class="card bg-n7 mb-3" #SearchFilter>
      <div class="card-body">
        <h6 class="p-0 m-0;">
          <a (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapse" aria-controls="collapseExample">
            <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
            <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
            Search Filters
          </a>
        </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapse">
        <div class="row mb-1">
          <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
            <span class="font-weight-bold ">IT Department</span>
            <form [formGroup]="form">
              <ng-select name="itDepartment" id="itDepartment" formControlName="deptId" (change)="onSelectDepartment()" 
              [ngClass]="{ 'is-invalid': deptId?.touched && deptId.errors?.required}">
                <ng-option disabled [value]="">--Select IT Department--</ng-option>
                <ng-option *ngFor="let itDept of itDepartments" [value]=itDept.deptID> {{itDept.deptName}}</ng-option>
              </ng-select>
            </form>
          </div>
          <div class="col-12 col-sm-6 col-lg-4" #service>
            <span class="font-weight-bold ">Service</span>
            <form [formGroup]="form">
              <p-multiSelect 
                [options]="serviceByDeptId" 
                formControlName="serviceIds"
                [disabled]="!deptId.value"
                [style]="{'width':'100%','min-width':'100%'}"
                defaultLabel="Select Service" 
                optionLabel="serviceName" optionValue="componentId"
                display="chip">
              </p-multiSelect>
            </form>
          </div>
          <div class="col-12 col-sm-6 col-lg-4" #CreatedDate>
            <span class="font-weight-bold">Created Date</span>
            <form class="form-inline">
              <div class="form-group hidden">
                <div class="input-group w-75">
                  <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                    disabled>
                    <span class="btn date-range-symbol">-</span>
                    <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpToDate"
                    [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                    disabled>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                      <fa-icon [icon]="faCalendarAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
      </div>  
    </div>
</div>

<div *ngIf="isSearch && tasks.length > 0" id="tasks-chart"></div>
<div *ngIf="isSearch && tasks.length === 0" class="not-found text-center">
  <div class="my-3"> No Task found.</div>
</div>