import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faAngleDown, faAngleUp, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import {
  MasterDataControllerService
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { PromoteTierResponse } from '@set-it-workflow/set-it-workflow-ts-angular/model/promoteTierResponse';
import { SearchPromotedFileCriteria } from "src/app/model/search-promote-file-criteria.model";
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';
import { PromoteFileReportService, ReportDataSource } from '../services/promote-file.service';

@Component({
  selector: 'app-promote-file',
  templateUrl: './promote-file.component.html',
  styleUrls: ['./promote-file.component.scss']
})
export class PromoteFileComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private reportService: PromoteFileReportService,
    private _httpClient: HttpClient,
    private authService: AuthService,
    private masterDataService: MasterDataControllerService
  ) { }
  
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public active: 1;
  public isSearch = true;
  public isSelectedAll = false;
  public isDisableApproveBtn = true;
  public promoteTierList: PromoteTierResponse[] = [];

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  public selectedItemTotal = 0;
  public page = 1;
  public pageSize = 10;
  public totalElements = 0;

  public reportDataSource: ReportDataSource;

  public reportColumns : String[] = ['expectedPromoteDate','promoteId','subject', 'promoteTier', 'promoteStep']; 
  

  public form: FormGroup;
  ngOnInit(): void {
    this.form = new FormGroup({
      promoteTier: new FormControl(),
      fileName: new FormControl()
    });

    this.updateCriteria(this.setCriteria());
    this.reportService.startSubscribe();
    this.reportDataSource = this.reportService.reportDataSource;

    this.masterDataService.getPromoteTiersUsingGET().subscribe((result) => {
      this.promoteTierList = result.data;
    });

    this.reportService.totalElements.subscribe((x)=> {
      this.totalElements = x;
    });

  }

updateCriteria(criteria: SearchPromotedFileCriteria) {
    this.reportService.searchReportCriteria.next(criteria);
}

public redirectToCreate(): void {
  this.router.navigate(["/report"]);
}

public clear(): void {
  this.form.reset();
  this.fromDate = null;
  this.toDate = null;
}

private setCriteria(): SearchPromotedFileCriteria {
  let criteria: SearchPromotedFileCriteria = new SearchPromotedFileCriteria();
  let promoteTierObject: PromoteTierResponse[];

  promoteTierObject = this.form.value.promoteTier ? this.form.value.promoteTier : null;
  criteria.promoteTiers = promoteTierObject ? Object.keys(promoteTierObject).map(x => promoteTierObject[x].promoteTierName) : null;
  criteria.fileName = this.form.value.fileName ? this.form.value.fileName : null;

  return criteria;
}

public export (){

  let criteria: SearchPromotedFileCriteria = this.setCriteria();
  this._httpClient.post(config.exportPromoteFile,criteria,
     { headers: { 'Authorization': ('bearer ' + this.authService.token) },
       responseType: 'arraybuffer'})
  .subscribe((response: any) => {
    const filename = 'promote-file-report.xlsx';
    this.reportService.downLoadFile(response, "application/octet-stream", filename);
  });
  
}

public search(): void {
  let serviceCriteria: SearchPromotedFileCriteria = this.setCriteria();
  this.updateCriteria(serviceCriteria);
}

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  public isHovered(date: NgbDate): boolean {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): boolean | void {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public validateInput(
    currentValue: NgbDate | null,
    input: string
  ): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
}
