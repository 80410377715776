import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from "@angular/material/sort";
import { Router } from '@angular/router';

import {
  faAngleDown,
  faAngleUp, faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";

import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";

import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { config } from 'src/environments/config';

import {
  DepartmentControllerService,
  DepartmentResponse,
  EmployeeResponse,
  ServiceTypeResponse
} from "@set-it-workflow/set-it-workflow-ts-angular";
import { ReportDataSource, SpecialRoleService } from '../services/special-role.service';


@Component({
  selector: 'app-special-role',
  templateUrl: './special-role.component.html',
  styleUrls: ['./special-role.component.scss']
})
export class SpecialRoleComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private departmentService: DepartmentControllerService,
    private router: Router,
    private reportService: SpecialRoleService,
    private _httpClient: HttpClient,
    private authService: AuthService) { }

  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;
  public active: 1;
  public isSearch = true;
  public isSelectedAll = false;
  public isDisableApproveBtn = true;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  public selectedItemTotal = 0;
  public page = 1;
  public pageSize = 10;
  public totalElements = 0;
  public dateCriteriaInvalid = false;

  public reportDataSource: ReportDataSource;
  public itDepartmentList: DepartmentResponse[] = [];
  public buDepartmentList: DepartmentResponse[] = [];
  public serviceTypeList: ServiceTypeResponse[] = [];
  public itEmployeeList: EmployeeResponse[] = [];

  public reportColumns : String[] = [
    'userName','department','role','userStatus']; 
  
  public form: FormGroup;
  ngOnInit(): void {

    this.form = new FormGroup({
      itDepartment: new FormControl(),
      buDepartment: new FormControl(),
      endDate: new FormControl(),
      startDate: new FormControl(),
    });

    this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
      this.itDepartmentList = result.data;
    });
    this.departmentService.getBUDepartmentUsingGET().subscribe((result) => {
      this.buDepartmentList = result.data;
    });
    this.reportService.totalElements.subscribe((x)=> { 
      this.totalElements = x;
    });

    this.reportService.searchPageSize.next(this.pageSize);
    this.updateCriteria();
    this.reportService.startSubscribe();
    this.reportDataSource = this.reportService.reportDataSource;    

  }
  public onSortChange(sort: Sort) {
    this.reportService.searchSort.next(sort);
}

public updateCriteria() {
  this.page = 0;
  this.reportService.searchPage.next(0);
  this.reportService.searchSort.next(null);
}

public redirectToCreate(): void {
  this.router.navigate(["/report"]);
}

public clear(): void {
  this.form.reset();
  this.fromDate = null;
  this.toDate = null;
}

public search(): void {
  this.selectedItemTotal = 0;
  this.updateCriteria();
}

public updatePage(): void {
    this.reportService.searchPage.next(this.page - 1);
}

public updatePageSize(size: number): void {
    this.pageSize = size;
    this.reportService.searchPageSize.next(this.pageSize);
}

public onDateSelection(date: NgbDate): void {

  if (!this.fromDate && !this.toDate) {
    this.fromDate = date;
  } else if (
    this.fromDate &&
    !this.toDate &&
    date &&
    date.after(this.fromDate)
  ) {
    this.toDate = date;
  } else {
    this.toDate = null;
    this.fromDate = date;
  }
}

public isHovered(date: NgbDate): boolean {
  this.dateCriteriaInvalid = false;
  return (
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    date.after(this.fromDate) &&
    date.before(this.hoveredDate)
  );
}

public isInside(date: NgbDate): boolean {
  return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
}

public isRange(date: NgbDate): boolean | void {
  return (
    date.equals(this.fromDate) ||
    (this.toDate && date.equals(this.toDate)) ||
    this.isInside(date) ||
    this.isHovered(date)
  );
}

public validateInput(
  currentValue: NgbDate | null,
  input: string
): NgbDate | null {
  const parsed = this.formatter.parse(input);
  this.dateCriteriaInvalid = false;
  return parsed && this.calendar.isValid(NgbDate.from(parsed))
    ? NgbDate.from(parsed)
    : currentValue;
}


public export(){
  this._httpClient.post(config.exportSpecialRole,null,
    { headers: { 'Authorization': ('bearer ' + this.authService.token) },
      responseType: 'arraybuffer'})
  .subscribe((response: any) => {
  const filename = 'special-role-report.xlsx';
  this.reportService.downLoadFile(response, "application/octet-stream", filename);
  });
}

}
