<div class="mt-3 mx-3">
  <div class="my-3 d-flex">
    <h5 style="padding: 0; margin: 0;">Promoted Task Report</h5>
    <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
  </div>
  <div class="card bg-n7 mb-3" #SearchFilter>
    <div class="card-body">
      <h6 class="p-0 m-0;">
        <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
          <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
          <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
          Search Filters
        </a>
      </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
        <div class="row mb-1">
          <div class="col-12 col-sm-6 col-lg-4" #itDepartment [formGroup]="form">
            <!-- <span class="font-weight-bold ">IT Department</span>
            <form [formGroup]="form">
              <ng-select name="itDepartment" id="itDepartment" formControlName="itDepartment">
                <ng-option disabled [value]="">--Select Department--</ng-option>
                <ng-option *ngFor="let item of itDepartmentList" [value]=item> {{item.deptName}}</ng-option>
              </ng-select>
            </form> -->
            <span class="font-weight-bold">IT Department</span>
            <p-multiSelect [options]="itDepartmentList" formControlName="itDepartment"
              [style]="{'width':'100%','min-width':'100%'}" defaultLabel="Select Department" optionValue="deptID"
              optionLabel="deptName" display="chip">
              <ng-template let-itDepartment pTemplate="item">
                <div>{{itDepartment.deptName}}</div>
              </ng-template>
            </p-multiSelect>
          </div>
          <div class="col-12 col-sm-6 col-lg-4" #buDepartment>
            <span class="font-weight-bold ">Support Department</span>
            <form [formGroup]="form">
              <ng-select name="itDepartment" id="buDepartment" formControlName="buDepartment">
                <ng-option disabled [value]="">--Select Department--</ng-option>
                <ng-option *ngFor="let item of buDepartmentList" [value]=item> {{item.deptName}}</ng-option>
              </ng-select>
            </form>
          </div>
          <div class="col-12 col-sm-6 col-lg-4" #PromotedDate>
            <span class="font-weight-bold">Promoted Date</span>
            <form class="form-inline">
              <div class="form-group hidden">
                <div class="input-group w-75">
                  <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker
                    #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}"
                    style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                    disabled>
                  <span class="btn date-range-symbol">-</span>
                  <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}"
                    style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpToDate"
                    [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                    disabled>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                      <fa-icon [icon]="faCalendarAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
    </div>
  </div>
  <div #Table>

    <div class="d-flex justify-content-end my-3">
      <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
      </app-row-per-page>
    </div>
    <div class="example-container" #servicesTable *ngIf="promotedReportDataSource.data">

      <table mat-table matSort class="table table-hover" cdk-table [dataSource]="promotedReportDataSource"
        (matSortChange)=onSortChange($event) fixedLayout="true">

        <ng-container cdkColumnDef="taskType">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Type</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskType}}</td>
        </ng-container>
        <ng-container cdkColumnDef="promoteId">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Promoted ID</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteIDDisplay}}</td>
        </ng-container>

        <ng-container cdkColumnDef="promotedTaskId">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Promoted Task ID</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promotedTaskId | taskId}}</td>
        </ng-container>
        <ng-container cdkColumnDef="promoteSubject">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Promote Subject</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promoteSubject | taskId}}</td>
        </ng-container>
        <ng-container cdkColumnDef="serviceName">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Service Name</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.serviceName}}</td>
        </ng-container>
        <ng-container cdkColumnDef="promotedDate">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Promoted Date</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.promotedDate}}</td>
        </ng-container>

        <ng-container cdkColumnDef="status">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Status</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.status}}</td>
        </ng-container>

        <ng-container cdkColumnDef="pendingAt">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Pending At</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.pendingAt}}</td>
        </ng-container>
        <ng-container cdkColumnDef="mainTaskId">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Main Task ID</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskId | taskId}}</td>
        </ng-container>
        <ng-container cdkColumnDef="mainTaskName">
          <th class="table-header-cell text-left col-2" cdk-header-cell *cdkHeaderCellDef>Main
            Task Name</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.mainTaskName | taskId}}</td>
        </ng-container>

        <ng-container cdkColumnDef="itDepartment">
          <th class="table-header-cell text-left col-2" cdk-header-cell *cdkHeaderCellDef>IT Department</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.itDeptName}}</td>
        </ng-container>
        <ng-container cdkColumnDef="totalEstimationManday">
          <th class="table-header-cell text-right" cdk-header-cell *cdkHeaderCellDef width="50">Total Man-day</th>
          <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element" width="50">
            {{element.totalEstimationManDay |
            number}}</td>
        </ng-container>

        <ng-container cdkColumnDef="createdByName">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Created By</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdByName}}</td>
        </ng-container>

        <ng-container cdkColumnDef="createdDate">
          <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Created Date</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdDate}}</td>
        </ng-container>

        <ng-container cdkColumnDef="buUnitName">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Unit</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buUnitName}}</td>
        </ng-container>

        <ng-container cdkColumnDef="buDepartmentName">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Department</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDepartmentName}}</td>
        </ng-container>

        <ng-container cdkColumnDef="buDivisionName">
          <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Division</th>
          <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDivisionName}}</td>
        </ng-container>
        <tr class="table-header" cdk-header-row *cdkHeaderRowDef="promotedReportColumns"></tr>
        <tr class="table-row" cdk-row *cdkRowDef="let row; columns: promotedReportColumns"></tr>
      </table>

      <div class="not-found text-center" *ngIf="promotedReportDataSource.data == null || totalElements === 0">
        <div class="my-3"> No Promoted Task found.</div>
      </div>


    </div>
    <div class="bg-black-haze">
      <div class="align-self-center">
        <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements" [(page)]="page"
          [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" (pageChange)="updatePage()">
        </ngb-pagination>
      </div>
    </div>

  </div>
</div>