import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { ReportControllerService, PromotedFilesResponse, APIResultListPromotedFilesResponse
        } from "@set-it-workflow/set-it-workflow-ts-angular";
import { BehaviorSubject, Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SearchPromotedFileCriteria} from 'src/app/model/search-promote-file-criteria.model'

@Injectable({
  providedIn: "root", 
})

export class PromoteFileReportService {
  
  searchReportCriteria = new BehaviorSubject<SearchPromotedFileCriteria>(new SearchPromotedFileCriteria());
  reportDataSource = new ReportDataSource();
  totalElements = new BehaviorSubject<number>(0);
  private subscription: Subscription;

  constructor(private reportControllerService: ReportControllerService) {}

  public startSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(this.searchReportCriteria
      .subscribe((criteria) => {
            this.getReport(
              criteria.promoteTiers,
              criteria.fileName,
            ).subscribe((elements: PromotedFilesResponse[]) => {
                this.reportDataSource.update(elements);
                this.totalElements.next(elements.length);
              }
            );
      })
    );
  }

  public unSubscription(): void {
    this.subscription.unsubscribe();
  }

  public getReport(
    promoteTiers: string[],
    fileName: string,
  ): Observable<PromotedFilesResponse[]> { 
    return this.reportControllerService.findPromotedFilesByTierUsingGET(fileName, promoteTiers)
      .pipe(
        map((x: APIResultListPromotedFilesResponse) => x.data )
      );
  }

  public downLoadFile(data: any, type: string,fileName:string) {
    let blob = new Blob([data], { type: type});
     this.saveAs(blob,fileName);
  }

  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export class ReportDataSource extends DataSource<PromotedFilesResponse> {
  data = new BehaviorSubject<PromotedFilesResponse[]>([]);

  connect(): Observable<PromotedFilesResponse[]> {
    return this.data;
  }

  disconnect(): void {}

  update(x: PromotedFilesResponse[]): void {
    this.data.next(x);
  }
}
