import { DataSource } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { TimesheetControllerService, APIResultListTimesheetMonthDetailResponse, TimesheetMonthDetailResponse
        } from "@set-it-workflow/set-it-workflow-ts-angular";
import { BehaviorSubject, Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SearchTimeSheetViewCriteria} from 'src/app/model/search-timesheet-view-criteria.model'

@Injectable({
  providedIn: "root", 
})

export class TimesheetViewService {
  
  searchReportCriteria = new BehaviorSubject<SearchTimeSheetViewCriteria>(new SearchTimeSheetViewCriteria());
  reportDataSource = new ReportDataSource();
  totalElements = new BehaviorSubject<number>(0);
  private subscription: Subscription;

  constructor(private timesheetControllerService: TimesheetControllerService) {}

  public startSubscribe(): void {
    this.subscription = new Subscription();
    this.subscription.add(this.searchReportCriteria
      .subscribe((criteria) => {
            this.getReport(
              criteria.year,
              criteria.deptId,
              criteria.empId,
              criteria.onlyActive
            ).subscribe((elements: TimesheetMonthDetailResponse[]) => {
                this.reportDataSource.update(elements);
                this.totalElements.next(elements.length);
              }
            );
      })
    );
  }

  public unSubscription(): void {
    this.subscription.unsubscribe();
  }

  public getReport(
    year: string,
    departmentId: string,
    empId: string[],
    onlyActive: boolean
  ): Observable<TimesheetMonthDetailResponse[]> { 
    var startDate = '01/01/' + year;
    var endDate = '31/12/' + year;
    return this.timesheetControllerService.getTimesheetViewUsingGET(endDate, startDate, departmentId, empId, onlyActive)
      .pipe(
        map((x: APIResultListTimesheetMonthDetailResponse) => x.data )
      );
  }

  public downLoadFile(data: any, type: string,fileName:string) {
    let blob = new Blob([data], { type: type});
     this.saveAs(blob,fileName);
  }

  public saveAs(blob: any, fileName: any) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export class ReportDataSource extends DataSource<TimesheetMonthDetailResponse> {
  data = new BehaviorSubject<TimesheetMonthDetailResponse[]>([]);

  connect(): Observable<TimesheetMonthDetailResponse[]> {
    return this.data;
  }

  disconnect(): void {}

  update(x: TimesheetMonthDetailResponse[]): void {
    this.data.next(x);
  }
}
