<div class="card card-detail bg-n7" id="InvolvedPerson">
  <div class="card-header">
    <h6 class="card-title">
      <a (click)="collapseBusinessPerson.toggle();toggleChange()" [attr.aria-expanded]="!isCollapse"
        aria-controls="collapseExample">
        <fa-icon *ngIf="isCollapse" [icon]="faAngleDown" class="mr-1"></fa-icon>
        <fa-icon *ngIf="!isCollapse" [icon]="faAngleUp" class="mr-1"></fa-icon>
        Involved Person
      </a>
    </h6>
  </div>
  <div class="card-body" #collapseBusinessPerson="ngbCollapse" [(ngbCollapse)]="isCollapse">
    <form [formGroup]="form" *ngIf="form && !isPromoteFormER">
      <p class="ml-sm-3" *ngIf="(fieldsData.businessUnitHead || fieldsData.businessDeptHead || fieldsData.uat) && isShowMainBusinessUser">
        <span class="label-y4">Business Person</span>
      </p>
      <div class="row d-flex align-items-center mb-3" *ngIf="fieldsData.mainBusinessUser && isShowMainBusinessUser">
        <div class="col-12 col-sm-2">
          <span class="float-sm-right text-right">Main Business User
            <span class="text-danger" *ngIf="fieldsData.mainBusinessUser?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4" *ngIf="isShowMainBusinessUser">
          <ng-select [items]="pqaList" formControlName="mainBusinessUser" bindLabel="nameEn" bindValue="empID"
            appendTo="body" class="custom" [readonly]="isShowOnPromoteForm">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="border px-2">
                <span>{{item.nameEn}}</span>
                <br>
                <span class="text-muted font-italic">({{item.deptName}})</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2" *ngIf="fieldsData.businessUnitHead && isShowBusinessUnitHead">
          <span class="float-sm-right text-right">Business Unit Head
            <span class="text-danger" *ngIf="fieldsData.businessUnitHead?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 form-group" *ngIf="fieldsData.businessUnitHead && isShowBusinessUnitHead">
          <div class="input-group">
            <ng-select [items]="bizUnitHeadList" formControlName="businessUnitHead" bindLabel="nameEn" bindValue="empID"
              appendTo="body" class="custom" style="flex-grow:1;" [readonly]="isShowOnPromoteForm">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.BU_UNIT_HEAD.name)"
                [disabled]="!fieldsData.businessUnitHead?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2" *ngIf="fieldsData.uat">
          <span class="float-sm-right text-right">UAT
            <span class="text-danger" *ngIf="fieldsData.uat?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 form-group" *ngIf="fieldsData.uat">
          <div class="input-group">
            <ng-select [items]="uatList" formControlName="uat" bindLabel="nameEn" bindValue="empID" appendTo="body"
              class="custom" style="flex-grow:1;">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.UAT_HEAD.name)"
                [disabled]="!fieldsData.uat?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-2 pl-0" *ngIf="fieldsData.businessDeptHead && isShowBusinessDeptHead">
          <span class="float-sm-right text-right">Business Department Head
            <span class="text-danger" *ngIf="fieldsData.businessDeptHead?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 form-group" *ngIf="fieldsData.businessDeptHead && isShowBusinessDeptHead">
          <div class="input-group">
            <ng-select [items]="bizDeptHeadList" formControlName="businessDeptHead" bindLabel="nameEn" bindValue="empID"
              appendTo="body" class="custom" style="flex-grow:1;" [readonly]="isShowOnPromoteForm">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.BU_DEPT_HEAD.name)"
                [disabled]="!fieldsData.businessDeptHead?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-2 pl-0" *ngIf="fieldsData.productionAcceptance">
          <span class="float-sm-right text-right">Production Acceptance
            <span class="text-danger" *ngIf="fieldsData.productionAcceptance?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4 form-group" *ngIf="fieldsData.productionAcceptance">
          <ng-select [items]="pqaList" formControlName="productionAcceptance" bindLabel="nameEn" bindValue="empID"
            appendTo="body" class="custom">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="border px-2">
                <span>{{item.nameEn}}</span>
                <br>
                <span class="text-muted font-italic">({{item.deptName}})</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div *ngIf="fieldsData.itHead || fieldsData.sola || fieldsData.qcHead">
        <p class="ml-sm-3">
          <span class="label-y4">IT Person</span>
        </p>
        <div class="row d-flex align-items-center mb-3">
          <div class="col-12 col-sm-2" *ngIf="fieldsData.itHead">
            <span class="float-sm-right text-right">IT Head
              <span class="text-danger" *ngIf="fieldsData.itHead?.req">*</span>
            </span>
          </div>
          <div class="col-12 col-sm-4" *ngIf="fieldsData.itHead">
            <div class="input-group">
              <ng-select [items]="itHeadList" formControlName="itHead" bindLabel="nameEn" bindValue="empID"
                appendTo="body" class="custom" style="flex-grow:1;"
                [readonly]="!fieldsData.itHead?.editable || (isReadonly && isEditMode)">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="border px-2">
                    <span>{{item.nameEn}}</span>
                    <br>
                    <span class="text-muted font-italic">({{item.deptName}})</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group-append">
                <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.IT_HEAD.name)"
                  [disabled]="!fieldsData.itHead?.editable || (isReadonly && isEditMode)">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-2" *ngIf="fieldsData.sola">
            <span class="float-sm-right text-right">SOLA
              <span class="text-danger" *ngIf="fieldsData.sola?.req">*</span>
            </span>
          </div>
          <div class="col-12 col-sm-4" *ngIf="fieldsData.sola">
            <div class="input-group">
              <ng-select [items]="solaList" formControlName="sola" bindLabel="nameEn" bindValue="empID" appendTo="body"
                class="custom" style="flex-grow:1;" [readonly]="isShowOnPromoteForm">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="border px-2">
                    <span>{{item.nameEn}}</span>
                    <br>
                    <span class="text-muted font-italic">({{item.deptName}})</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group-append">
                <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.SOLA.name)"
                  [disabled]="!fieldsData.sola?.editable || (isReadonly && isEditMode)">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Developer && Developer Head -->
        <div class="row d-flex align-items-center mb-3" *ngIf="isShowDeveloperHead">
          <div class="col-12 col-sm-2">
            <span class="float-sm-right text-right">Developer Head
            </span>
          </div>
          <div class="col-12 col-sm-10">
            <table class="table mt-2">
              <thead class="bg-n3">
                <tr>
                  <th style="width:30%">Assigned User</th>
                  <th style="width:40%">Task Id</th>
                  <th style="width:20%">Task Name</th>
                </tr>
              </thead>
              <tbody formArrayName="developerHeadForUserManage">
                <tr [formGroupName]="i" *ngFor="let task of developerHeadFormArray.controls; let i = index;"
                  style="background:#ffffff;">
                  <td>
                    <div class="input-group">
                      <ng-select [items]="itList" formControlName="developerId" bindLabel="nameEn" bindValue="empID"
                        appendTo="body" class="custom" style="width: 80%;" appendTo="body">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          <div class="border px-2">
                            <span>{{item.nameEn}}</span>
                            <br>
                            <span class="text-muted font-italic">({{item.deptName}})</span>
                          </div>
                        </ng-template>
                      </ng-select>
                      <div class="input-group-append">
                        <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.DEV.name, task)"
                          [disabled]="!task.value.canChangeDeveloper">
                          <fa-icon [icon]="faSearch"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{task.controls.taskId.value | taskId}}
                  </td>
                  <td>
                    {{task.controls.taskName.value}}
                  </td>
                </tr>
                <tr style="background:#ffffff;" *ngIf="developerHeadFormArray.length == 0">
                  <td colspan="3">
                    <div class="not-found text-center">
                      <div class="my-1">No Data found.</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row d-flex align-items-center mb-3" *ngIf="isShowDeveloper">
          <div class="col-12 col-sm-2">
            <span class="float-sm-right text-right">Developer
            </span>
          </div>
          <div class="col-12 col-sm-10">
            <table class="table mt-2">
              <thead class="bg-n3">
                <tr>
                  <th style="width:30%">Assigned User</th>
                  <th style="width:20%">Task Id</th>
                  <th style="width:40%">Task Name</th>
                </tr>
              </thead>
              <tbody formArrayName="developerForUserManage">
                <tr [formGroupName]="i" *ngFor="let task of developerFormArray.controls; let i = index;"
                  style="background:#ffffff;">
                  <td>
                    <div class="input-group">
                      <ng-select [items]="itList" formControlName="developerId" bindLabel="nameEn" bindValue="empID"
                        appendTo="body" class="custom" style="width: 80%;" appendTo="body">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          <div class="border px-2">
                            <span>{{item.nameEn}}</span>
                            <br>
                            <span class="text-muted font-italic">({{item.deptName}})</span>
                          </div>
                        </ng-template>
                      </ng-select>
                      <div class="input-group-append">
                        <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.DEV.name, task)"
                          [disabled]="!task.value.canChangeDeveloper">
                          <fa-icon [icon]="faSearch"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{task.controls.taskId.value | taskId}}
                  </td>
                  <td>
                    {{task.controls.taskName.value}}
                  </td>
                </tr>
                <tr style="background:#ffffff;" *ngIf="developerFormArray.length == 0">
                  <td colspan="3">
                    <div class="not-found text-center">
                      <div class="my-1">No Data found.</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- QC HEAD && UAT HEAD-->
        <div class="row d-flex align-items-center mb-3">
          <div class="col-12 col-sm-2" *ngIf="fieldsData.qcHead">
            <span class="float-sm-right text-right">QC Head
              <span class="text-danger" *ngIf="fieldsData.qcHead?.req">*</span>
            </span>
          </div>
          <div class="col-12 col-sm-4" *ngIf="fieldsData.qcHead">
            <div class="input-group">
              <ng-select [items]="qcHeadList" formControlName="qcHead" bindLabel="nameEn" bindValue="empID"
                appendTo="body" class="custom" style="flex-grow: 1;">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="border px-2">
                    <span>{{item.nameEn}}</span>
                    <br>
                    <span class="text-muted font-italic">({{item.deptName}})</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group-append">
                <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.QC_HEAD.name)"
                  [disabled]="!fieldsData.qcHead?.editable || (isReadonly && isEditMode)">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-2" *ngIf="fieldsData.uatHead">
            <span class="float-sm-right text-right">UAT Head
              <span class="text-danger" *ngIf="fieldsData.uatHead?.req">*</span>
            </span>
          </div>
          <div class="col-12 col-sm-4" *ngIf="fieldsData.uatHead">
            <div class="input-group">
              <ng-select [items]="uatHeadList" formControlName="uatHead" bindLabel="nameEn" bindValue="empID"
                appendTo="body" class="custom" style="flex-grow:1;"
                [readonly]="!fieldsData.uatHead?.editable || (isReadonly && isEditMode)">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="border px-2">
                    <span>{{item.nameEn}}</span>
                    <br>
                    <span class="text-muted font-italic">({{item.deptName}})</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group-append">
                <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.UAT_HEAD.name)"
                  [disabled]="!fieldsData.uatHead?.editable || (isReadonly && isEditMode)">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- QC HEAD && UAT HEAD-->
      <div class="row d-flex align-items-center">
        <div class="col-12 col-sm-2" *ngIf="fieldsData.pqa">
          <span class="float-sm-right text-right">Production acceptance
            <span class="text-danger" *ngIf="fieldsData.pqa?.req">*</span>
          </span>
        </div>
        <div class="col-12 col-sm-4" *ngIf="fieldsData.pqa">
          <div class="input-group">
            <ng-select [items]="pqaList" formControlName="pqa" bindLabel="nameEn" bindValue="empID" appendTo="body"
              class="custom" style="flex-grow:1;">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="border px-2">
                  <span>{{item.nameEn}}</span>
                  <br>
                  <span class="text-muted font-italic">({{item.deptName}})</span>
                </div>
              </ng-template>
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.PQA.name)"
                [disabled]="!fieldsData.pqa?.editable || (isReadonly && isEditMode)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form [formGroup]="form" *ngIf="form && isPromoteFormER">
      <div>
        <p class="ml-sm-3">
          <span class="label-y4">IT Person</span>
        </p>
        <!-- QC HEAD && UAT HEAD-->
        <div class="row d-flex align-items-center mb-3">
          <div class="col-12 col-sm-2" *ngIf="fieldsData.qcHead">
            <span class="float-sm-right text-right">QC Head
              <span class="text-danger" *ngIf="fieldsData.qcHead?.req">*</span>
            </span>
          </div>
          <div class="col-12 col-sm-4" *ngIf="fieldsData.qcHead">
            <div class="input-group">
              <ng-select [items]="qcHeadList" formControlName="qcHead" bindLabel="nameEn" bindValue="empID"
                appendTo="body" class="custom" style="flex-grow: 1;">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="border px-2">
                    <span>{{item.nameEn}}</span>
                    <br>
                    <span class="text-muted font-italic">({{item.deptName}})</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group-append">
                <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.QC_HEAD.name)"
                  [disabled]="!fieldsData.qcHead?.editable || (isReadonly && isEditMode)">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center">
          <div class="col-12 col-sm-2" *ngIf="fieldsData.pqa">
            <span class="float-sm-right text-right">Production acceptance
              <span class="text-danger" *ngIf="fieldsData.pqa?.req">*</span>
            </span>
          </div>
          <div class="col-12 col-sm-4" *ngIf="fieldsData.pqa">
            <div class="input-group">
              <ng-select [items]="pqaList" formControlName="pqa" bindLabel="nameEn" bindValue="empID" appendTo="body"
                class="custom" style="flex-grow:1;">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="border px-2">
                    <span>{{item.nameEn}}</span>
                    <br>
                    <span class="text-muted font-italic">({{item.deptName}})</span>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group-append">
                <button class="btn btn-b2" type="button" (click)="openSearchUserModal(roleEnum.PQA.name)"
                  [disabled]="!fieldsData.pqa?.editable || (isReadonly && isEditMode)">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- QC HEAD && UAT HEAD-->
    </form>
  </div>
</div>