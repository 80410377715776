import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivateComponent } from './private.component';
import { PrivateRoutingModule } from './private-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskFormComponent } from './task-form/task-form.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReportModule } from './report/report.module';


@NgModule({
  imports: [
    CommonModule,
    PrivateRoutingModule,
    NgbCollapseModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    ReportModule,
  ],
  declarations: [
    PrivateComponent,
    TaskFormComponent,
  ],
  exports: [PrivateComponent]

})
export class PrivateModule { }
