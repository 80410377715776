import { AmChart, AmChartsService } from '@amcharts/amcharts3-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faAngleDown, faAngleUp, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ComponentControllerService, ComponentResponse, DepartmentControllerService, DepartmentResponse, TasksConvertToJiraTimesheetReport, TimesheetControllerService } from '@set-it-workflow/set-it-workflow-ts-angular';

@Component({
  selector: 'app-timesheet-report-dashboard',
  templateUrl: './timesheet-report-dashboard.component.html',
  styleUrls: ['./timesheet-report-dashboard.component.scss']
})
export class TimesheetReportDashboardComponent implements OnInit, OnDestroy {
  public isCollapse = true;
  public isSearch = false;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;

  public itDepartments: DepartmentResponse[] = [];
  public serviceByDeptId: ComponentResponse[] = [];

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate;
  public toDate: NgbDate | null = null;
  public dateCriteriaInvalid = false;
  public tasks: TasksConvertToJiraTimesheetReport[] = [];

  public form: FormGroup = new FormGroup({
    deptId: new FormControl(null, Validators.required),
    serviceIds: new FormControl(),
    createdStartDate: new FormControl(),
    createdEndDate: new FormControl(),
  })

  get deptId(): FormControl { return this.form.controls.deptId as FormControl; }
  get serviceIds(): FormControl { return this.form.controls.serviceIds as FormControl; }
  get createdStartDate(): FormControl { return this.form.controls.createdStartDate as FormControl; }
  get createdEndDate(): FormControl { return this.form.controls.createdEndDate as FormControl; }

  private chart: AmChart;

  constructor(
    private departmentService: DepartmentControllerService,
    private componentService: ComponentControllerService,
    private timesheetService: TimesheetControllerService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private Amcharts: AmChartsService
  ) { }

  ngOnInit(): void {
    this.getITDepartments();
  }

  private getITDepartments(): void {
    this.departmentService.getITDepartmentUsingGET().subscribe(res => {
      this.itDepartments = res.data;
    });
  }

  private getComponentByDeptId(): void {
    this.componentService.getComponentsByDeptIdUsingGET(this.deptId.value).subscribe(res => {
      this.serviceByDeptId = res.data;
    });
  }

  private getTasksConvertToJiraTimesheetReport(): void {
    this.timesheetService.getTasksConvertToJiraTimesheetReportUsingGET(
      this.deptId.value, this.createdStartDate.value, this.createdEndDate.value, this.serviceIds.value
    ).subscribe(res => {
      this.tasks = res.data;
      this.isSearch = true;

      setTimeout(() => this.createChart(), 300)
    });
  }

  private convertData(): ChartData[] {
    const result: ChartData[] = [];

    this.tasks.forEach(t => {
      result.push({
        taskName: t.taskName,
        actualManDays: parseFloat(t.actualManDays),
        totalEstimateManDays: parseFloat(t.totalEstimateManDays),
        differenceManDays: parseFloat(t.diffManDays),
        differencePercentage: parseFloat(t.diffPercentage),
        plotDifferenceManDays: parseFloat(t.diffManDays) < 0 ? 0.0 : parseFloat(t.diffManDays)
      })
    });

    const maxResult = 10;
    if (result.length < maxResult) {
      const dummyData = maxResult - result.length;

      for (let i = 0; i < dummyData; i++) {
        result.push({
          taskName: "N/A",
          actualManDays: 0.0,
          totalEstimateManDays: 0.0,
          differenceManDays: 0.0,
          differencePercentage: 0.0,
          plotDifferenceManDays: 0.0
        })
      }
    }

    return result;
  }


  public onSelectDepartment(): void {
    this.getComponentByDeptId();
  }

  public search(): void {
    this.form.markAllAsTouched();
    this.createdStartDate.setValue(this.formatter.format(this.fromDate));
    this.createdEndDate.setValue(this.formatter.format(this.toDate));

    if (!this.form.invalid) {
      this.getTasksConvertToJiraTimesheetReport();
    }
  }

  public clear(): void {
    this.form.reset();
  }

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  public isHovered(date: NgbDate): boolean {
    this.dateCriteriaInvalid = false;
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): boolean | void {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public validateInput(
    currentValue: NgbDate | null,
    input: string
  ): NgbDate | null {
    const parsed = this.formatter.parse(input);
    this.dateCriteriaInvalid = false;
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  private createChart(): void {
    let chartData: ChartData[] = [];

    if (this.tasks.length > 0) {
      chartData = this.convertData();
    }
    
    this.chart = this.Amcharts.makeChart("tasks-chart", {
      "type": "serial",
      "theme": "none",
      "marginBottom": 50,
      "dataProvider": chartData,
      "graphs": [{
        "id": "estimate",
        "valueAxis": "estimate",
        "fillAlphas": 1,
        "lineAlpha": 0.2,
        "columnWidth": 0.7,
        "type": "column",
        "valueField": "totalEstimateManDays",
        "clustered": true,
        "fillColors": "#fbaf34",
        "showBalloon": true,
        "balloonText": "Total Estimation Man-day = [[value]]"
      }, {
        "id": "actual",
        "valueAxis": "actual",
        "fillAlphas": 1,
        "lineAlpha": 0.2,
        "columnWidth": 0.7,
        "type": "column",
        "valueField": "actualManDays",
        "clustered": true,
        "fillColors": "#28a745",
        "showBalloon": true,
        "balloonText": "Actual Man-day = [[value]]"
      }, {
        "id": "difference",
        "bullet": "round",
        "lineThickness": 1,
        "bulletSize": 6,
        "bulletBorderAlpha": 1,
        "bulletColor": "#FFFFFF",
        "lineColor": "#FD5D5D",
        "useLineColorForBulletBorder": true,
        "bulletBorderThickness": 2,
        "fillAlphas": 0,
        "lineAlpha": 1,
        "valueField": "plotDifferenceManDays",
        "descriptionField": "differenceManDays",
        "balloonFunction": (dataItem) => {
          return `<span>Man-days difference: ${dataItem.dataContext.differenceManDays}</span><br><span>Percent change: ${dataItem.dataContext.differencePercentage} %</span>`
        }
      }],
      "categoryField": "taskName",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0.2,
        "axisAlpha": 0,
        "autoWrap": true,
      },
      "valueField": "differenceManDays",
      "valueAxes": [{
        "title": "Man-day(s)",
        "baseValue": 0,
        "minimum": 0
      }],
     "export": {
        "enabled": false
      }
    
    })
  }

  ngOnDestroy(): void {
    if (this.chart) { this.Amcharts.destroyChart(this.chart); }
  }

}
interface ChartData {
  taskName: string,
  actualManDays: number,
  totalEstimateManDays: number,
  differenceManDays: number,
  differencePercentage: number,
  plotDifferenceManDays: number,
}
