<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">View Timesheet</h5>
    </div>
    <div class="card bg-n7 mb-3" #searchFilter>
        <div class="card-body">
          <h6 class="p-0 m-0;">
            <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
              <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
              <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
              Search Filters
            </a>
          </h6>
          <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
            <form [formGroup]= "form">
            <div class="row mb-1">
              <div class="col-12 col-sm-3 col-lg-2" #year>
                <span class="font-weight-bold">Year</span> 
                  <ng-select name="year" id="year" formControlName="year">
                    <ng-option disabled [value]="">--Select Year--</ng-option>
                    <ng-option *ngFor="let item of yearList" [value]=item>{{item}}</ng-option>
                  </ng-select>
              </div>
              <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
                <span class="font-weight-bold">IT Department</span>
                  <ng-select name="itDepartment" id="itDepartment" formControlName="itDepartment">
                    <ng-option disabled [value]="">--Select Department--</ng-option>
                    <ng-option *ngFor="let item of departmentList" [value]=item> {{item.deptName}}</ng-option>
                  </ng-select>
              </div>
            </div>
            <div class="row mb-1">
            <div class="col-12 col-sm-3 col-lg-2 d-flex align-items-center" #onlyActiveUser>
                <div class="form-group form-check m-0">
                    <input type="checkbox" class="form-check-input" id="input-only-my-task"
                        formControlName="onlyActiveUser">
                    <label class="form-check-label" for="input-only-my-task">Only Active User</label>
                </div>
            </div>
              <div class="col-12 col-sm-6 col-lg-4" #employee>
                <span class="font-weight-bold">Employee</span>
                <p-multiSelect [options]="itEmployeeList" formControlName="employee" [style]="{'width':'100%','min-width':'100%'}"
                  defaultLabel="Select Employee" optionLabel="nameEn" display="chip">
                </p-multiSelect>
              </div>
            </div>
            </form>
            <div class="d-flex justify-content-end mt-3">
              <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
              <button class="btn btn-primary" type="button" (click)="search()">Search</button>
            </div>
          </div> 
        </div> 
    </div>
    <div #Table>  
            <div class="table-responsive" #servicesTable *ngIf="reportDataSource.data">
    
              <table mat-table matSort class="table table-hover" cdk-table [dataSource]="reportDataSource">

              <ng-container cdkColumnDef="empId">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >User Id</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.empId}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="nameEn">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Name</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.nameEn}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="jan">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Jan</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[0]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="feb">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Feb</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[1]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="mar">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Mar</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[2]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="apr">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Apr</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[3]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="may">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>May</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[4]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="jun">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Jun</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[5]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="jul">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Jul</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[6]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="aug">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Aug</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[7]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="sep">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Sep</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[8]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="oct">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Oct</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[9]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="nov">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Nov</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[10]}}</td>
              </ng-container> 

              <ng-container cdkColumnDef="dec">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef>Dec</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.manHours[11]}}</td>
              </ng-container> 

              <tr class="table-header" cdk-header-row *cdkHeaderRowDef="reportColumns"></tr>
              <tr class="table-row" cdk-row *cdkRowDef="let row; columns: reportColumns"></tr> 
              </table>  
    
              <div class="not-found text-center" *ngIf="reportDataSource.data == null || totalElements === 0" >
                <div class="my-3"> No Data Found.</div>
              </div>
    
            </div>
    
            <div class="bg-black-haze">
              <div class="align-self-center">
              </div>
            </div>
    </div>
</div>
