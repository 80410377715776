<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">SR For Get Production Data Report</h5>
      <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
    </div>
    <div class="card bg-n7 mb-3" #SearchFilter>
        <div class="card-body">
            <h6 class="p-0 m-0;">
                <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
                    <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
                    <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
                    Search Filters
                </a>
            </h6>
            <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
                <div class="row mb-1">
                    <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
                      <span class="font-weight-bold">IT Department</span>
                      <form [formGroup]="form">
                        <ng-select name="itDepartment" id="itDepartment" formControlName="itDepartment">
                          <ng-option disabled [value]="">--Select IT Department--</ng-option>
                          <ng-option *ngFor="let itDept of itDepartmentList" [value]=itDept.deptID> {{itDept.deptName}}</ng-option>
                        </ng-select>
                      </form>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4" #service>
                      <span class="font-weight-bold">Service Name</span>
                      <form [formGroup]="form">
                        <ng-select name="component" id="component" formControlName="component">
                            <ng-option disabled [value]="">--Select Service--</ng-option>
                            <ng-option *ngFor="let item of componentList" [value]=item.componentId> {{item.componentName}}</ng-option>
                        </ng-select>
                      </form>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-12 col-sm-6 col-lg-4" #dataOwner>
                        <span class="font-weight-bold">Data Owner</span>
                        <form [formGroup]="form">
                            <ng-select name="dataOwner" id="dataOwner" formControlName="dataOwner">
                                <ng-option disabled [value]="">--Select Data Owner--</ng-option>
                                <ng-option *ngFor="let itDept of itDepartmentList" [value]=itDept.deptID> {{itDept.deptName}}</ng-option>
                            </ng-select>
                        </form>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4" #CreatedDate>
                        <span class="font-weight-bold">Created Date</span>
                        <form class="form-inline">
                          <div class="form-group hidden">
                            <div class="input-group w-75">
                              <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                              <ng-template #t let-date let-focused="focused">
                                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                  (mouseleave)="hoveredDate = null">
                                  {{ date.day }}
                                </span>
                              </ng-template>
                             </div>
                            </div>
                            <div class="input-group">
                              <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 180px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                                [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                disabled>
                              <span class="btn date-range-symbol">-</span>
                              <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 180px;" placeholder="dd/mm/yyyy" name="dpToDate"
                                [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                                disabled>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                                  <fa-icon [icon]="faCalendarAlt"></fa-icon>
                                </button>
                              </div>
                            </div>
                        </form>
                      </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
                    <button class="btn btn-primary" type="button" (click)="search()">Search</button>
                </div>
            </div>
        </div>
    </div>

    <div #Table>
        <div class="d-flex justify-content-end my-3">
            <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
            </app-row-per-page>
        </div>
        <div class="table-responsive" #servicesTable>

            <table mat-table matSort class="table table-hover" cdk-table [dataSource]="tasks"
            (matSortChange)=onSortChange($event)>

            <ng-container cdkColumnDef="taskId">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Task ID</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskId | taskId}}</td>
            </ng-container>

            <ng-container cdkColumnDef="taskName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Task Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskName}}</td>
            </ng-container>

            <ng-container cdkColumnDef="itDeptName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >IT Department</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.itDepartmentName}}</td>
            </ng-container>

            <ng-container cdkColumnDef="serviceName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Service Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.componentName}}</td>
            </ng-container>

            <ng-container cdkColumnDef="dataOwnerName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Data Owner</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.dataOwnerName}}</td>
            </ng-container>

            <ng-container cdkColumnDef="endOfUsage">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >End of Usage</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.endOfUsageFormat}}</td>
            </ng-container>

            <ng-container cdkColumnDef="createdDate">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Created Date</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdDateFormat}}</td>
            </ng-container>
         
            <ng-container cdkColumnDef="createdByName">
            <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Created By</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdByName}}</td>
            </ng-container>


            <tr class="table-header" cdk-header-row *cdkHeaderRowDef="RequestProductionDataReportColumns"></tr>
            <tr class="table-row" cdk-row *cdkRowDef="let row; columns: RequestProductionDataReportColumns"></tr> 
            </table>  

            <div class="not-found text-center" *ngIf="tasks.length === 0 || totalElements === 0" >
            <div class="my-3"> No Task found.</div>
            </div>

        </div>

        <div class="bg-black-haze">
            <div class="align-self-center">
            <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
                [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
                (pageChange)="updatePage()">
            </ngb-pagination>
            </div>
        </div>
    </div>
</div>